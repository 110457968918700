var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticStyle: {
      "background-image": "linear-gradient(120deg, #9eefe1 5%, #0050ff 96%)",
      "mix-blend-mode": "multiply",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }