var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-mailing-list",
        width: "360px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
    },
    [
      _c("div", { staticClass: "modal modal-mailing-list" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-mailing-list")
                },
              },
            }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("logo", {
              staticClass: "modal-logo centered-block",
              staticStyle: { width: "175px" },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content content-section" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email",
                    expression: "'required|email'",
                  },
                ],
                attrs: { type: "email", name: "email", placeholder: "Email" },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.email = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "centered-text" },
                [
                  _c(
                    "ph-button",
                    {
                      attrs: {
                        type: "submit",
                        size: "medium",
                        loading: _vm.loading,
                        color: "primary-outline",
                      },
                    },
                    [_vm._v("\n            Submit\n          ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }