var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "price-range" }, [
    _c("div", { staticClass: "price-range-slider" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.localValue,
            expression: "localValue",
          },
        ],
        attrs: {
          type: "range",
          min: _vm.min,
          max: _vm.max,
          name: _vm.name,
          step: _vm.step || 100,
        },
        domProps: { value: _vm.localValue },
        on: {
          input: _vm.valueChanged,
          __r: function ($event) {
            _vm.localValue = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "price-range-labels" }, [
        _c("div", [_vm._v("£" + _vm._s(_vm.pounds(_vm.min)))]),
        _vm._v(" "),
        _c("div", [_vm._v("£" + _vm._s(_vm.pounds(_vm.max)))]),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "price-range-value" },
      [
        _c(
          "ph-button",
          {
            nativeOn: {
              click: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [_vm._v("£" + _vm._s(_vm.pounds(_vm.localValue)))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }