var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-edit-comment",
        width: "700px",
        height: "auto",
        scrollable: "",
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal modal-comment" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.onHide.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-content" },
          [
            _c("div", { staticClass: "release-header" }, [
              _vm.comment
                ? _c(
                    "div",
                    { staticClass: "release-content content-section" },
                    [
                      _c("h2", [_vm._v(_vm._s(_vm.comment.name))]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.comment.description))]),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("add-text", {
              attrs: { type: "editComment", addTextAble: _vm.comment },
              on: { success: _vm.onCommentAdded },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }