// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../../../node_modules/laravel-mix/node_modules/css-loader/dist/cjs.js??clonedRuleSet-13.use[1]!../../../../../../node_modules/mediaelement/build/mediaelementplayer.min.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h2[data-v-348bad44] {\n  margin-bottom: 0;\n}\np[data-v-348bad44] {\n  margin: 1em 0;\n}", "",{"version":3,"sources":["webpack://./resources/assets/js/vue/components/modals/video-player.vue"],"names":[],"mappings":"AAGA;EACI,gBAAA;AADJ;AAGA;EACI,aAAA;AAAJ","sourcesContent":["\n//@import \"~styles/helpers/_variables.scss\";\n@import \"~mediaelement/build/mediaelementplayer.min.css\";\nh2 {\n    margin-bottom: 0;\n}\np {\n    margin: 1em 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
