var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "modal",
        {
          attrs: {
            name: "modal-auth-login",
            maxWidth: 600,
            height: "auto",
            scrollable: "",
            adaptive: "",
          },
          on: { closed: _vm.closed },
        },
        [
          !_vm.deActivatedUser && !_vm.bannedUser
            ? _c("div", { staticClass: "modal modal-auth-login" }, [
                _c(
                  "div",
                  { staticClass: "modal-header" },
                  [
                    _c("logo", {
                      staticClass: "modal-logo centered-block",
                      staticStyle: { width: "185px" },
                    }),
                    _vm._v(" "),
                    _c("close-icon", {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$modal.hide("modal-auth-login")
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-content" }, [
                  _c(
                    "p",
                    {
                      staticClass: "centered-text",
                      staticStyle: { margin: "1em 0" },
                    },
                    [
                      _vm._v("\n          Don't have an account? "),
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: { click: _vm.showRegister },
                        },
                        [_vm._v("Register")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "form",
                    {
                      staticClass: "form-login",
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.formSubmit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.email,
                            expression: "email",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|email",
                            expression: "'required|email'",
                          },
                        ],
                        class: { error: _vm.errors.has("email") },
                        attrs: {
                          type: "email",
                          name: "email",
                          placeholder: "Email",
                        },
                        domProps: { value: _vm.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.email = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("email")
                        ? _c("span", { staticClass: "error-msg" }, [
                            _vm._v(_vm._s(_vm.errors.first("email"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.password,
                            expression: "password",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        class: { error: _vm.errors.has("password") },
                        attrs: {
                          type: "password",
                          name: "password",
                          placeholder: "Password",
                        },
                        domProps: { value: _vm.password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.password = $event.target.value
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.errors.has("password")
                        ? _c("span", { staticClass: "error-msg" }, [
                            _vm._v(_vm._s(_vm.errors.first("password"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.bag.has("auth")
                        ? _c("span", { staticClass: "error-msg" }, [
                            _vm._v(_vm._s(_vm.bag.first("auth"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "group-wrap" }, [
                        _c(
                          "label",
                          {
                            staticClass: "remember-me",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.remember,
                                  expression: "remember",
                                },
                              ],
                              attrs: {
                                type: "checkbox",
                                name: "remember",
                                id: "remember",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.remember)
                                  ? _vm._i(_vm.remember, null) > -1
                                  : _vm.remember,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.remember,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.remember = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.remember = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.remember = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v("\n              Remember me\n            "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "remember-me",
                            staticStyle: {
                              display: "block",
                              "text-align": "right",
                              cursor: "pointer",
                            },
                            on: { click: _vm.showReset },
                          },
                          [
                            _vm._v(
                              "\n              Forgot password\n            "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "centered-text" },
                        [
                          _c(
                            "ph-button",
                            {
                              attrs: {
                                type: "submit",
                                size: "medium",
                                loading: _vm.loading,
                              },
                            },
                            [_vm._v("\n              Login\n            ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ])
            : _c("div", { staticClass: "modal modal-auth-login" }, [
                _c(
                  "div",
                  { staticClass: "modal-header" },
                  [
                    _c("img", {
                      staticClass: "modal-logo centered-block",
                      attrs: { src: "/img/logo.png" },
                    }),
                    _vm._v(" "),
                    _c("close-icon", {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.$modal.hide("modal-auth-login")
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-content" }, [
                  _vm.deActivatedUser
                    ? _c("div", [
                        _c(
                          "p",
                          {
                            staticClass: "centered-text",
                            staticStyle: { margin: "1em 0" },
                          },
                          [
                            _vm._v(
                              "\n            Your account is currently deactivated, Please contact admin.\n          "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.bannedUser
                    ? _c("div", [
                        _c(
                          "p",
                          {
                            staticClass: "centered-text",
                            staticStyle: { margin: "1em 0" },
                          },
                          [
                            _vm._v(
                              "\n            Your account has been banned.\n          "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
        ]
      ),
      _vm._v(" "),
      _c("modal", { attrs: { name: "reset-password" } }, [
        _c(
          "div",
          { staticStyle: { position: "absolute", right: "0", top: "0" } },
          [
            _c("close-icon", {
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("reset-password")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "container" }, [
          !_vm.loading && !_vm.loaded
            ? _c("div", { staticClass: "reset-form-wrap" }, [
                _c("img", {
                  staticClass: "reset-logo modal-logo centered-block",
                  attrs: { src: "/img/logo.png" },
                }),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function ($event) {
                        $event.preventDefault()
                        return _vm.submitReset.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("h4", { staticStyle: { "text-align": "center" } }, [
                      _vm._v("Reset your Password"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticStyle: { "text-align": "center" },
                        attrs: { for: "email" },
                      },
                      [
                        _vm._v(
                          "Enter your email below to request a password reset\n            link"
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email",
                        },
                      ],
                      attrs: {
                        type: "email",
                        name: "email",
                        id: "",
                        placeholder: "Email",
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.email = $event.target.value
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticStyle: { margin: "auto", display: "block" },
                        attrs: { type: "submit" },
                      },
                      [_vm._v("Submit")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.emailErrMsg
                  ? _c(
                      "span",
                      {
                        staticStyle: {
                          display: "block",
                          "padding-top": "10px",
                          "text-align": "center",
                          color: "red",
                          "font-size": "12px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.emailErrMsg))]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c(
                "div",
                {
                  staticClass: "reset-form-wrap",
                  staticStyle: { margin: "auto", transition: "1s ease" },
                },
                [
                  _c("img", {
                    staticClass: "reset-logo modal-logo centered-block",
                    attrs: { src: "/img/logo.png" },
                  }),
                  _vm._v(" "),
                  _c("spinner", {
                    staticStyle: { margin: "0 auto" },
                    attrs: {
                      "animation-duration": 1000,
                      size: 75,
                      color: "black",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.loading && _vm.loaded
            ? _c(
                "div",
                {
                  staticClass: "reset-form-wrap",
                  staticStyle: { margin: "auto", transition: "1s ease" },
                },
                [
                  _c("img", {
                    staticClass: "reset-logo modal-logo centered-block",
                    attrs: { src: "/img/logo.png" },
                  }),
                  _vm._v(" "),
                  _c("p", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(
                      "A password reset link has been sent, please check your email."
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "centered-block",
                      staticStyle: {
                        "margin-top": "10px",
                        "font-size": "12px",
                        display: "block",
                        "margin-left": "auto",
                        "margin-right": "auto",
                      },
                      on: { click: _vm.submitReset },
                    },
                    [_vm._v("Resend Link")]
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }