var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-item" }, [
    _c(
      "div",
      { staticClass: "p-item-image" },
      [
        _c(
          "router-link",
          { attrs: { to: _vm.getRouterObject(_vm.release) } },
          [
            _c("avatar", {
              attrs: {
                size: 130,
                src: _vm.release.image.files.thumb.url,
                tile: true,
                recent: _vm.release.is_recent,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "p-item-main" }, [
      _c("div", { staticClass: "p-item-detail" }, [
        _c(
          "div",
          { staticClass: "p-item-title" },
          [
            _c(
              "router-link",
              {
                staticStyle: { "text-decoration": "none" },
                attrs: { to: _vm.getRouterObject(_vm.release) },
              },
              [_c("span", [_vm._v(_vm._s(_vm.release.name))])]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "release-description" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.release.description) + "\n        "
        ),
      ]),
      _vm._v(" "),
      _vm.release.tracks
        ? _c(
            "div",
            { staticClass: "tracks" },
            _vm._l(_vm.release.tracks, function (track, index) {
              return _c("div", { staticClass: "track flex" }, [
                _c("div", { staticClass: "flex-1" }, [
                  _c("div", { staticClass: "track-image" }, [
                    track.image
                      ? _c("img", {
                          attrs: {
                            src: track.image.files.medium.url,
                            alt: track.image.alt,
                          },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(index + 1) +
                          " - " +
                          _vm._s(track.name) +
                          "\n              "
                      ),
                      _c("play-pause-button", {
                        attrs: { track: track, type: "streamable" },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p-item-meta" },
        [
          _c("actions", {
            attrs: { actionable: _vm.release, actiontype: "release" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "p-item-time" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.moment(_vm.release.release_date).fromNow()) +
                "\n            "
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }