var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded page-profile-pro" }, [
    _c("div", { staticClass: "page-main" }, [
      _c(
        "div",
        {
          staticClass: "profile-pro-hero",
          style: {
            background: !_vm.backgroundImageUrl
              ? "#e6e6e6"
              : `url(${_vm.backgroundImageUrl})`,
          },
        },
        [
          _vm.$store.state.app.user.id > 0
            ? _c(
                "div",
                {
                  staticClass: "change-button",
                  on: { click: _vm.showChangeBannerModal },
                },
                [_vm._v("\n        Change Banner\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.backgroundImageUrl && _vm.$store.state.app.user.id > 0
            ? _c(
                "div",
                {
                  staticClass: "remove-button",
                  on: { click: _vm.deleteBanner },
                },
                [_c("i", { staticClass: "fa fa-trash" }), _c("span")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("profile-avatar", { attrs: { user: _vm.mutableUser, size: 200 } }),
          _vm._v(" "),
          _c("div", { staticClass: "pro-hero-info" }, [
            _c(
              "div",
              { staticClass: "pro-hero-info-name" },
              [
                _vm._v(
                  "\n          " + _vm._s(_vm.mutableUser.name) + "\n          "
                ),
                _c("ph-label", { attrs: { color: "blue" } }, [_vm._v("Pro")]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "pro-hero-info-bio" }, [
              _vm._v(
                "\n          " + _vm._s(_vm.mutableUser.bio) + "\n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pro-hero-info-actions" },
              [
                !_vm.isMe && _vm.isLogin
                  ? _c("follow-action", {
                      attrs: { user: _vm.mutableUser },
                      on: { update: _vm.followStatusUpdated },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pro-main" }, [
        _c(
          "aside",
          { staticClass: "sidebar-left" },
          [_c("user-left-sidebar", { attrs: { user: _vm.user } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pro-content-area" },
          [
            _c("profile-navigation", {
              attrs: { user: _vm.user, routeNameSubview: _vm.routeSubview },
              on: { linkclick: _vm.linkClicked },
            }),
            _vm._v(" "),
            _vm.linkclicked == 0
              ? _c("router-view", { attrs: { user: _vm.user } })
              : _vm._e(),
            _vm._v(" "),
            _vm.linkclicked == 1
              ? _c("main-subview", {
                  attrs: { user: _vm.user, path: _vm.subviewpath },
                  on: { linkclickSubview: _vm.linkClicked },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "aside",
      { staticClass: "sidebar-right" },
      [_c("user-right-sidebar", { attrs: { user: _vm.user, news: _vm.news } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }