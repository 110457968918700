var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded" }, [
    _c(
      "div",
      { staticClass: "page-main" },
      [
        _c("h1", { staticClass: "no-top" }, [_vm._v("My Favourites")]),
        _vm._v(" "),
        _vm.favourites.length
          ? _c(
              "div",
              [
                _c("div", { staticClass: "filters" }, [
                  _c(
                    "span",
                    {
                      staticClass: "filter",
                      class: { active: _vm.filter === "all" },
                      on: {
                        click: function ($event) {
                          _vm.filter = "all"
                        },
                      },
                    },
                    [_vm._v("All")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "filter",
                      class: { active: _vm.filter === "release" },
                      on: {
                        click: function ($event) {
                          _vm.filter = "release"
                        },
                      },
                    },
                    [_vm._v("Releases")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "filter",
                      class: { active: _vm.filter === "track" },
                      on: {
                        click: function ($event) {
                          _vm.filter = "track"
                        },
                      },
                    },
                    [_vm._v("Tracks")]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "filter",
                      class: { active: _vm.filter === "news" },
                      on: {
                        click: function ($event) {
                          _vm.filter = "news"
                        },
                      },
                    },
                    [_vm._v("News")]
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.filteredFavourites, function (like) {
                  return _c(
                    "div",
                    { key: like.id },
                    [_c("item", { attrs: { item: like.likeable } })],
                    1
                  )
                }),
                _vm._v(" "),
                _c(
                  "ph-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showLoadMore,
                        expression: "showLoadMore",
                      },
                    ],
                    attrs: { loading: _vm.loading },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.loadMore.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Load More")]
                ),
              ],
              2
            )
          : _c("div", [_vm._v("\n          No Favourites\n        ")]),
        _vm._v(" "),
        _c("spinner", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.favourites,
              expression: "!favourites",
            },
          ],
          staticStyle: { margin: "3em auto" },
          attrs: { "animation-duration": 1000, size: 60, color: "black" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }