var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "profile-avatar",
      class: { isme: _vm.isMe },
      on: { click: _vm.showModal },
    },
    [
      _c("avatar", {
        staticClass: "pro-hero-avatar",
        attrs: {
          size: _vm.size,
          src: _vm.user.avatar.files.medium.url,
          alt: _vm.user.avatar.alt,
          verified: _vm.verified,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "change-button" }, [
        _vm._v("\n    Change Avatar\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }