var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.loaded
      ? _c("div", [
          _vm.myMusic.length
            ? _c(
                "div",
                _vm._l(_vm.myMusic, function (items, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { "margin-bottom": "20px" } },
                    [
                      items.length > 1
                        ? _c(
                            "div",
                            [
                              _c("my-music-release", {
                                attrs: { item: items, background: index % 2 },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            _vm._l(items, function (item) {
                              return _c("my-music-track", {
                                key: item.id,
                                attrs: { item: item, background: index % 2 },
                              })
                            }),
                            1
                          ),
                    ]
                  )
                }),
                0
              )
            : _c("div", [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "text-align": "center",
                      height: "100vh",
                      display: "flex",
                      "justify-content": "center",
                      "align-items": "center",
                    },
                  },
                  [
                    _vm._v(
                      "\n        You haven’t purchased any music yet… perhaps "
                    ),
                    _c("router-link", { attrs: { to: "/discover" } }, [
                      _vm._v("discover"),
                    ]),
                    _vm._v(" some?\n      "),
                  ],
                  1
                ),
              ]),
        ])
      : _c(
          "div",
          { staticClass: "phase-loading widget-center" },
          [
            _c("dotlottie-player", {
              staticStyle: { width: "150px", height: "150px" },
              attrs: {
                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                background: "transparent",
                speed: "1",
                loop: "",
                autoplay: "",
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }