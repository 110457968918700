var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "asset" },
    [
      _c("avatar", {
        attrs: { src: _vm.asset.files.medium.url, tile: true, size: 350 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }