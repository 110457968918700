var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hero" },
    [
      _c("overlay"),
      _vm._v(" "),
      _c("div", { staticClass: "hero-content" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "main-search" }, [
          _c(
            "form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.toggleSearch.apply(null, arguments)
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerm,
                    expression: "searchTerm",
                  },
                ],
                staticClass: "search-input",
                attrs: { type: "text", placeholder: "SEARCH" },
                domProps: { value: _vm.searchTerm },
                on: {
                  keyup: function ($event) {
                    $event.preventDefault()
                    return _vm.onEnter($event)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchTerm = $event.target.value
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "search-toggle", on: { click: _vm.toggleSearch } },
            [_c("i", { staticClass: "fa fa-search" })]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "hero-buttons" },
          [
            _c(
              "ph-button",
              {
                attrs: { color: "white-blur", size: "medium" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showAuthModal()
                  },
                },
              },
              [_c("p", [_vm._v("Join For Free >")])]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h1", { staticClass: "hero-title" }, [
      _vm._v("Connecting People "),
      _c("span", [_vm._v("Through Music.")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }