<template>
  <ph-panel id="billing">
    <h2>Payment Methods</h2>

    <existing-card-account :cards="cards" :account="'bill'" :actions="true" @handleRemove="handleRemove" />

    <!-- Stripe Elements Placeholder -->
    <!-- <div id="card-element" style="margin-bottom: 20px;"></div>

    <ph-button id="card-button" size="medium" :loading="submitting">Save</ph-button> -->

    <button @click="openAddPaymentMethodModal" class="add-card-btn"><i class="fa fa-plus"></i></button>

    <AddPaymentMethod :clientSecret="clientSecret" />
  </ph-panel>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import PaymentMixin from './payment-mixin'
import { HalfCircleSpinner as Spinner } from 'epic-spinners'
import ExistingCardAccount from '../../../global/existing-card-account';
import AddPaymentMethod from '../../../modals/add-payment-method.vue';

export default {
  data() {
    return {
      loadedInitialCardData: false,
      submitting: false,
      submitted: false,
      clientSecret: null,

      cards: null,
      error_message: '',
    }
  },

  mounted: function () {
    this.getClientSecret().then(secret => this.clientSecret = secret)
    // this.payment()
    this.getPaymentMethod()
  },

  computed: {
    ...mapState([
      'app'
    ]),
  },

  methods: {
    openAddPaymentMethodModal() {
      this.$modal.show('add-payment-method')
    },
    payment() {
      const stripe = Stripe(process.env.MIX_VUE_APP_STRIPE_KEY)
      const elements = stripe.elements()
      const cardElement = elements.create('card', { hidePostalCode: true })
      const cardButton = document.getElementById('card-button')

      cardElement.mount('#card-element')

      cardButton.addEventListener('click', async () => {
        this.submitting = true
        const { setupIntent, error } = await stripe.confirmCardSetup(
          this.clientSecret.client_secret, {
          payment_method: {
            card: cardElement,
            billing_details: { name: this.app.user.name },
          },
        },
        )

        if (error) {
          this.submitting = false
        } else {
          await axios.post('/api/account/billing', {
            payment_method: setupIntent.payment_method
          }).then(({ data }) => {
            this.submitting = false

            // Update user details in store
            this.$store.commit('app/setUser', data.user);

            Vue.notify({
              group: 'main',
              type: 'success',
              title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
              duration: 1500,
            })
          }).catch(error => {
            this.submitting = false
          })
        }
      })
    },

    getPaymentMethod() {
      axios.get('/api/account/billing/method')
        .then(response => {
          this.cards = response.data.payment_methods.data
          console.log("billing cards", this.cards);
        })
    },
    handleRemove() {
      this.card = null
    }
  },
  mixins: [
    PaymentMixin,
  ],
  components: {
    Spinner,
    ExistingCardAccount,
    AddPaymentMethod
  },
}
</script>

<style lang="scss" scoped>
h2 {
  margin-bottom: 20px;
  font-size: 24px;
  padding-bottom: 5px;
  border-bottom: 1px solid #30f;
}

.error-msg {
  color: #ff6e6e;
  font-size: 12px;
}

.saved-card {
  div {
    margin: 10px 0;
  }
}

.checkbox-container {
  margin: 15px 0;
}

.payment-info {
  margin: 1em 0;
  background: white;
  padding: 1em;

  p {
    line-height: 150%;
  }
}

.card {
  margin: 2em 0;
  width: 50%;
}

.add-card-btn {
  color: #3300ff;
  padding: 8px 10px;
  border: 1px solid #3300ff;
  font-size: 32px;
  border-radius: 5px;
  background: transparent;
}
</style>
