<template>
  <modal name="modal-checkout" width="600px" height="auto" @closed="closed" @before-close="closed" @opened="opened"
    scrollable adaptive>
    <div class="modal modal-checkout">
      <div class="modal-header">
        <logo class="modal-logo centered-block" style="width: 185px;" />
        <close-icon @click.native="$modal.hide('modal-checkout')"></close-icon>
      </div>
      <div class="modal-content">
        <div v-if="!complete">
          <h2 class="payment-info-header" style="text-align: center;">Payment Information</h2>
          <div v-show="loadedInitialCardData">
            <div v-if="!stripePayment">
              <default-card-account :card="card" />
              <div class="card-details-info" @click.prevent="showCardInput">Add new card</div>
            </div>

            <div v-show="stripePayment">
              <div id="card-element" style="margin-bottom: 20px;"></div>
              <div id="card-errors" role="alert"></div>
              <div class="save-card-block">
                <span class="save-card-text">Would you like to save this card to your account?</span>
                <label class="switch">
                  <input type="checkbox" v-model="isSaveTheCard">
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div v-show="!loadedInitialCardData">
            <spinner style="margin: 3em auto;" :animation-duration="1000" :size="60" :color="'black'" />
          </div>
          <h2 class="no-top" style="text-align: center;">Order Information</h2>
          <table>
            <tr>
              <td>
                Cart Subtotal
              </td>
              <td>
                &pound;{{ $store.getters['cart/getTotal'] }}
              </td>
            </tr>
            <tr>
              <td>
                Taxes
              </td>
              <td>
                &pound;{{ taxes }}
              </td>
            </tr>
            <tr class="border-top bold">
              <td>
                Total
              </td>
              <td>
                &pound;{{ total }}
              </td>
            </tr>
          </table>
          <p class="alert" v-if="total >= (parseInt(settings.purchase_approval_threshold) / 100)">
            As this purchase is greater than £{{ parseInt(settings.purchase_approval_threshold) / 100 }}, it
            will need to be manually approved by an admin.
          </p>
          <div v-show="!loadedInitialCardData" style="text-align: center;">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <div v-show="loadedInitialCardData" style="text-align: center;">
            <ph-button id="paymentButton" @click.native="pay" size="large" :loading="loading">Pay</ph-button>
          </div>
          <p v-show="loadedInitialCardData" style="text-align: center;">
            By confirming this purchase, you agree to the Phase Terms of Use.
          </p>
        </div>
        <div v-else>
          <ph-panel type="error" v-if="error" style="text-align: center;">
            <h2 class="no-top header">Error!</h2>
            <p>
              There was an error processing your payment. You have not been charged.
            </p>
          </ph-panel>
          <ph-panel type="success" v-else style="text-align: center;">
            <h2 class="no-top header">Success!</h2>
            <p>
              Your purchase is complete. Visit your
              <router-link to="/account/mymusic" @click.native="$modal.hide('modal-checkout');">music
              </router-link>
              page to download your tracks now!
            </p>
          </ph-panel>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue'
import Logo from "global/logo";
import PhButton from 'global/ph-button'
import CloseIcon from 'global/close-icon'
import { HalfCircleSpinner as Spinner } from 'epic-spinners'
import { mapState } from 'vuex'
import DefaultCardAccount from '../../global/default-card-account';

export default {
  data() {
    return {
      // State
      loadedInitialCardData: false,
      settings: window.settings,
      loading: false,
      complete: false,
      error: false,
      secret: null,
      cardElement: null,
      order: null,
      stripe: null,
      card: null,
      stripePayment: false,
      user: null,
      isSaveTheCard: false,
    }
  },
  computed: {
    ...mapState(['app']),
    items() {
      return this.$store.state.cart.items
    },
    subtotal: function () {
      return this.$store.getters['cart/getTotal']
    },
    taxes: function () {
      return (this.subtotal * 0.2).toFixed(2)
    },
    total: function () {
      return (parseFloat(this.taxes) + parseFloat(this.subtotal)).toFixed(2)
    },
    userHasCard: function () {
      return !!this.user && !!(this.user.card_brand && this.user.card_last_four);
    },
  },
  methods: {
    async getPaymentIntent() {
      await axios.post('/api/order/payment/intent', {
        items: this.items,
        order_id: this.order.id,
      }).then(response => {
        this.secret = response.data
      })
    },
    async setupCardElement() {
      this.stripe = Stripe(process.env.MIX_VUE_APP_STRIPE_KEY)
      const elements = this.stripe.elements()
      this.cardElement = elements.create('card', { hidePostalCode: true })

      this.cardElement.mount('#card-element')

      this.loadedInitialCardData = true

      this.cardElement.addEventListener('change', ({ error }) => {
        const displayError = document.getElementById('card-errors');
        if (error) {
          displayError.textContent = error.message;
        } else {
          displayError.textContent = '';
        }
      });
    },
    showCardInput() {
      this.stripePayment = true;
    },
    async pay() {
      this.loading = true;
      if (!this.stripePayment && !this.card) {
        this.loading = false;
        Vue.notify({
          group: 'main',
          type: 'error',
          title: 'Please add valid payment method!',
        })
        return 0;
      }

      if (!this.order) {
        await axios.post('/api/order/create', {
          items: this.items,
          existing_account: !this.stripePayment,
        }).then(response => {
          this.order = response.data

        }).catch(err => {
          this.loading = false
        })
      }
      if (this.stripePayment || !this.card) {
        await this.getPaymentIntent();
        const result = await this.stripe.confirmCardPayment(this.secret, {
          payment_method: {
            card: this.cardElement,
            billing_details: {
              name: this.app.user.name,
              email: this.app.user.email
            },
          },
          setup_future_usage: 'on_session'
        })
        if (result.error) {
          Vue.notify({
            group: 'main',
            type: 'error',
            title: result.error.message,
          })
          this.loading = false
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            if (this.isSaveTheCard) {
              await axios.post('/api/account/billing/attach', {
                pm_id: result.paymentIntent.payment_method,
              }).then(response => {
                Vue.notify({
                  group: 'main',
                  type: 'success',
                  title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
                  duration: 1500,
                })
                this.loading = false
                this.complete = true
              }).catch(err => {
                Vue.notify({
                  group: 'main',
                  type: 'error',
                  title: 'Something went wrong!',
                })
                this.loading = false
              })
            } else {
              Vue.notify({
                group: 'main',
                type: 'success',
                title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
                duration: 1500,
              })
              this.loading = false
              this.complete = true
            }

          }
        }
      } else {
        Vue.notify({
          group: 'main',
          type: 'success',
          title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
          duration: 1500,
        })
        this.loading = false
        this.complete = true
      }

      this.$store.dispatch('cart/reset');
    },
    opened() {
      // Rehydrate the user on open
      this.user = this.$store.getters['app/getUser'];
      axios.get('/api/account/billing/default-method')
        .then(response => {
          this.card = response.data.payment_method
          this.showCard = !!!response.data.payment_method
        })
      this.setupCardElement()
    },
    closed() {
      this.stripe = null
      this.order = null
      this.secret = null
      this.cardElement = null
      this.complete = false
      this.stripePayment = false
    },
  },
  components: {
    PhButton,
    CloseIcon,
    Spinner,
    DefaultCardAccount,
  },
}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

.payment-info-header {
  margin-bottom: 10px;
}

.card-details-info {
  text-decoration: underline;
  margin: 1em 0;
  cursor: pointer;
}

p:first-of-type {
  margin-bottom: 2em;
}

p:last-of-type {
  margin-top: 1em;
}

input {
  font-size: 100%;
}

table {
  width: 100%;
  margin-bottom: 2em;
}

tr.border-top {
  border-top: 1px solid;
}

td {
  padding: 1em 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "No";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  color: #000;
  transition: .4s;
  border-radius: 50%;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  /* Font size set to 12px */
}

input:checked+.slider:before {
  content: "Yes";
  transform: translateX(30px);
  background-color: #3300ff;
  color: #fff;
}

.save-card-block {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.save-card-block .save-card-text {
  font-size: 14px;
}
</style>
