var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded" }, [
    _c("div", { staticClass: "page-main page-track-release" }, [
      _c(
        "div",
        { staticClass: "main-info" },
        [
          _vm.track
            ? _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "release",
                      params: { releaseid: _vm.track.release.slug },
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "release-image" },
                    [
                      _c("div", { staticClass: "release-label" }, [
                        _vm._v(
                          "\n                        Release\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("avatar", {
                        attrs: {
                          src: _vm.image.files.medium.url,
                          size: 300,
                          tile: true,
                          center: false,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _c(
                "div",
                { staticClass: "release-image" },
                [
                  _c("avatar", {
                    attrs: {
                      src: _vm.image.files.medium.url,
                      size: 300,
                      tile: true,
                      center: false,
                    },
                  }),
                ],
                1
              ),
          _vm._v(" "),
          _c("div", { staticClass: "item-info" }, [
            _c("div", { staticClass: "item-info-upper" }, [
              _c(
                "div",
                { staticClass: "item-info-upper-left" },
                [
                  _c(
                    "h1",
                    [
                      _vm.track
                        ? _c("play-pause-button", {
                            attrs: {
                              track: _vm.track,
                              size: 3,
                              type: _vm.streamable,
                            },
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.item.name) +
                          "\n                        "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "artists-genres" }, [
                    _vm.release
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$store.getters["app/getClassByKey"](
                                _vm.release.class
                              ).name
                            ) + "\n                            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.track ? _c("span", [_vm._v("Track ")]) : _vm._e(),
                    _vm._v(
                      "\n                            by\n                            "
                    ),
                    _vm.release
                      ? _c("span", { staticClass: "item" }, [
                          _vm._v(_vm._s(_vm.release.uploader.name)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.release
                      ? _c(
                          "span",
                          [
                            _vm._v("in\n                                "),
                            _vm._l(_vm.item.genres, function (genre, index) {
                              return _c("span", { staticClass: "item" }, [
                                _vm._v(_vm._s(genre.name)),
                                _vm.item.genres[index + 1]
                                  ? _c("span", [
                                      _vm._v(
                                        ",\n                                    "
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.track
                      ? _c(
                          "span",
                          [
                            _vm._v("in\n                                "),
                            _vm._l(
                              _vm.track.release.genres,
                              function (genre, index) {
                                return _c("span", { staticClass: "item" }, [
                                  _vm._v(_vm._s(genre.name)),
                                  _vm.track.release.genres[index + 1]
                                    ? _c("span", [
                                        _vm._v(
                                          ",\n                                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              }
                            ),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _vm.release
                      ? _c(
                          "span",
                          [
                            _vm._v(
                              "\n                                Uploaded by\n                                "
                            ),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: _vm.getRouterObject(_vm.release.uploader),
                                },
                              },
                              [_vm._v(_vm._s(_vm.release.uploader.name))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.track
                      ? _c(
                          "span",
                          [
                            _vm._v(
                              "\n                                Uploaded by\n                                "
                            ),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: _vm.getRouterObject(
                                    _vm.track.release.uploader
                                  ),
                                },
                              },
                              [_vm._v(_vm._s(_vm.track.release.uploader.name))]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _vm.release
                    ? _c("p", { staticClass: "release-date" }, [
                        _vm._v(
                          "\n                            Release Date\n                            "
                        ),
                        _c("span", { staticClass: "date" }, [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.release.release_date)
                                .format("DD-MM-YYYY")
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.release
                    ? _c("p", { staticClass: "release-description" }, [
                        _vm._v(
                          "\n                            " +
                            _vm._s(_vm.release.description) +
                            "\n                        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.release && !!(_vm.release.tracks.length || 0)
                    ? _c("add-to-cart-button", { attrs: { product: _vm.item } })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "item-info-upper-right" },
                [
                  _c("social-sharing", {
                    inlineTemplate: {
                      render: function () {
                        var _vm = this,
                          _c = _vm._self._c
                        return _c(
                          "span",
                          { staticStyle: { display: "flex", gap: "25px" } },
                          [
                            _c("network", { attrs: { network: "twitter" } }, [
                              _c("i", {
                                staticClass: "fab fa-twitter-square",
                                staticStyle: { cursor: "pointer" },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("network", { attrs: { network: "facebook" } }, [
                              _c("i", {
                                staticClass: "fab fa-facebook",
                                staticStyle: { cursor: "pointer" },
                              }),
                            ]),
                          ],
                          1
                        )
                      },
                      staticRenderFns: [],
                    },
                  }),
                  _vm._v(" "),
                  _c("like-button", {
                    attrs: { likeable: _vm.item },
                    on: { like: _vm.liked, unlike: _vm.unliked },
                  }),
                  _vm._v(" "),
                  _c("share-button", { attrs: { shareable: _vm.item } }),
                  _vm._v(" "),
                  _c("report-button", { attrs: { reportable: _vm.item } }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.release
        ? _c(
            "div",
            { staticClass: "metadata" },
            [
              _c("h2", [_vm._v("Tracks")]),
              _vm._v(" "),
              _c("track-list", {
                attrs: { tracks: _vm.release.tracks, release: _vm.release },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.track
        ? _c("div", { staticClass: "metadata" }, [
            _c("h2", [_vm._v("Track Info")]),
            _vm._v(" "),
            _c("table", { staticClass: "meta-table" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("tr", [
                _c("td", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm
                          .moment()
                          .startOf("day")
                          .seconds(_vm.track.length)
                          .format("mm:ss")
                      ) +
                      "\n                    "
                  ),
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(_vm.track.bpm))]),
                _vm._v(" "),
                _c("td", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$store.getters["app/getKeyByKey"](_vm.track.key).name
                    ),
                  },
                }),
                _vm._v(" "),
                _c(
                  "td",
                  _vm._l(_vm.track.release.genres, function (genre, index) {
                    return _c("span", [
                      _vm._v(_vm._s(genre.name)),
                      _vm.track.release.genres[index + 1]
                        ? _c("span", [
                            _vm._v(",\n                            "),
                          ])
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                        " +
                      _vm._s(
                        _vm
                          .moment(_vm.track.release.release_date)
                          .format("DD-MM-YYYY")
                      ) +
                      "\n                    "
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "comments" },
        [_c("comments-container", { attrs: { commentable: _vm.item } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Duration")]),
      _vm._v(" "),
      _c("th", [_vm._v("BPM")]),
      _vm._v(" "),
      _c("th", [_vm._v("Key")]),
      _vm._v(" "),
      _c("th", [_vm._v("Genres")]),
      _vm._v(" "),
      _c("th", [_vm._v("Release Date")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }