var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.articleLoaded
      ? _c(
          "div",
          [
            _c("spinner", {
              staticStyle: { margin: "3em auto" },
              attrs: { "animation-duration": 1000, size: 100, color: "black" },
            }),
          ],
          1
        )
      : _c("div", { staticClass: "page-content-padded page-news-article" }, [
          _vm.articleLoaded
            ? _c("div", { staticClass: "page-main" }, [
                _c("h1", { staticClass: "centered-text" }, [
                  _vm._v(_vm._s(_vm.article.title)),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "byline centered-text" },
                  [
                    _vm._v("\n\n                Article by "),
                    _c(
                      "router-link",
                      {
                        staticClass: "blue",
                        attrs: { to: "/user/" + _vm.article.user.path },
                      },
                      [_vm._v(_vm._s(_vm.article.user.name))]
                    ),
                    _vm._v(" in\n\n                "),
                    _vm._l(_vm.article.categories, function (category, index) {
                      return _c(
                        "span",
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "blue",
                              attrs: { to: "/news" + category.path },
                            },
                            [_vm._v(_vm._s(category.title))]
                          ),
                          _vm.article.categories[index + 1]
                            ? _c("span", [_vm._v(", ")])
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    _vm._v(
                      "\n\n                - " +
                        _vm._s(
                          _vm
                            .moment(_vm.article.published_at)
                            .format("MMMM D, YYYY")
                        ) +
                        "\n\n                \n            "
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "actions-wrapper" },
                  [
                    _c("actions", {
                      attrs: { actionable: _vm.article },
                      on: {
                        like: _vm.commitLike,
                        unlike: _vm.commitUnlike,
                        share: _vm.commitShare,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "cover-img",
                  style:
                    "background-image: url(" +
                    _vm.article.image.files.original.url +
                    ")",
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "article-body",
                  domProps: { innerHTML: _vm._s(_vm.article.content) },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "article-social" },
                  [
                    _c("comments-container", {
                      attrs: { commentable: _vm.article },
                      on: { newComment: _vm.newComment },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("h2", { staticClass: "related-articles-heading" }, [
                  _vm._v("Related Articles"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "related-articles" },
                  _vm._l(
                    _vm.news.articles.slice(0, 2),
                    function (article, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "related-article" },
                        [_c("related-article", { attrs: { data: article } })],
                        1
                      )
                    }
                  ),
                  0
                ),
              ])
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }