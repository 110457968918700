<template>
    <ph-panel id="manage">
        <div class="col-header flex justify-between align-item-center">
            <h2>Account</h2>
            <div class="btn-box">
                <!-- <ph-button v-if="app.user.roles[0].name === 'artist' || app.user.roles[0].name === 'pro'" style="
                    margin-bottom: 5px;" size="medium" @click.native="downgradeAccount" :loading="downgrading">
                    Downgrade to Standard
                </ph-button>
                <ph-button v-if="app.user.roles[0].name === 'pro'" style="margin-bottom: 5px;" size="medium"
                    @click.native="downgradeToArtist" :loading="downgrading">
                    Downgrade to Artist
                </ph-button>
                <ph-button v-if="app.user.roles[0].name === 'standard'" style="margin-bottom: 5px;" size="medium"
                    @click.native="openVerificationModal" :loading="upgrading">
                    Upgrade to Artist
                </ph-button> -->
                <ph-button size="deactive" class="deactive-btn" @click.native="deactivateAccount">
                    Deactivate
                </ph-button>
                <ph-button style="margin-bottom: 5px;" size="delete" class="del-btn" @click.native="deleteAccount">
                    Delete
                </ph-button>
            </div>
        </div>
        <!-- <hr> -->
        <!-- <div>
            <h3 class="account-type">Account Type - {{ app.user.account_type }}</h3>
        </div> -->
        <!-- <p>
            Deactivate or delete your account permanently.
        </p> -->
        <div class="account-setting">
            <div class="membership">
                <div class="title-block">
                    <h4>Membership</h4>
                </div>
                <div class="status-setting">
                    <div class="account-status">
                        <div class="account-status-text">
                            <span v-if="app.user.roles[0].name === 'standard'">
                                <circle-tick-icon />
                            </span>
                            <span v-if="app.user.roles[0].name !== 'standard'">
                                <circle-empty-icon />
                            </span>
                            <span>Standard</span>
                        </div>
                        <ph-button size="deactive" style="
                    margin-bottom: 5px;" @click.native="downgradeAccount" :loading="downgrading"
                            v-if="app.user.roles[0].name === 'artist' || app.user.roles[0].name === 'pro'">Downgrade</ph-button>
                    </div>
                    <div class="account-status">
                        <div class="account-status-text">
                            <span v-if="app.user.roles[0].name === 'artist'">
                                <circle-tick-icon />
                            </span>
                            <span v-if="app.user.roles[0].name !== 'artist'">
                                <circle-empty-icon />
                            </span>
                            <span>Artist</span>
                        </div>
                        <ph-button size="deactive" style="margin-bottom: 5px;" @click.native="downgradeToArtist"
                            :loading="downgrading" v-if="app.user.roles[0].name === 'pro'">Downgrade</ph-button>
                        <ph-button color="primary-outline" @click.native="upgradeAccount" :loading="upgrading"
                            class="trial-button" v-if="app.user.roles[0].name == 'standard'">
                            Upgrade
                        </ph-button>
                    </div>
                    <div class="account-status">
                        <div class="account-status-text">
                            <span
                                v-if="app.user.roles[0].name === 'pro' && app.user.account_type === 'pro' && app.user.approved_at">
                                <circle-tick-icon />
                            </span>
                            <span
                                v-if="app.user.roles[0].name !== 'pro' && app.user.account_type !== 'pro' && app.user.approved_at">
                                <circle-empty-icon />
                            </span>
                            <span v-if="app.user.account_type === 'standard' && !app.user.approved_at">
                                <circle-exclamation-icon v-tooltip="'Under review'" />
                            </span>

                            <span>Artist PRO <span class="pro-badge">PRO</span></span>
                        </div>
                        <!-- <ph-button size="trail"
                            v-if="(app.user.roles[0].name == 'pro' && subscriptions[0]?.stripe_status === 'canceled' && (subscriptions[0]?.trail_ends_at !== null || subscriptions[0]?.ends_at !== null))"
                            @click.native="subscribe(true)" :loading="loading" class="trial-button">
                            Start Trial
                        </ph-button> -->
                        <!-- <ph-button color="gradient-upgrade" @click.native="subscribe(false)" :loading="loading"
                            class="trial-button"
                            v-if="(subscriptions[0]?.stripe_status === 'canceled' && (subscriptions[0]?.trail_ends_at !== null || subscriptions[0]?.ends_at !== null))">
                            Upgrade
                        </ph-button> -->

                        <ph-button color="gradient-upgrade" v-if="app.user.roles[0].name != 'pro' && subscriptionsLoaded"
                            @click.native="upgradeAccountToPro()" :loading="upgradingPro" class="trial-button">
                            {{ app.user.roles[0].name == 'artist' && subscriptions.length == 0 ? 'Start Trial' :
                                'Upgrade' }} </ph-button>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
            <div class="border-line"></div>
            <div class="membership">
                <div class="title-block">
                    <h4>Membership Payment Method</h4>
                </div>
                <div class="no-subscription-block" v-if="subscriptionsLoaded && subscriptions.length == 0">
                    <p class="no-subscription-label" style="text-align: center;">Not subscribed, free 30 days trail available.</p>
                </div>

                <div v-if="subscriptions.length > 0 && membershipPaymentMethod">
                    <membership-payment-method-view :method="membershipPaymentMethod" />
                    <p>
                        <subscription-plan v-for="subscription in subscriptions"
                            v-if="plansLoaded && subscriptionsLoaded" :subscription="subscription"
                            :plan="subscription.stripe_plan == 'Artist Pro EU' ? plans[0] : plans[1]"
                            :key="subscription.id" :subStatus="stripeSubscriptionStatus" />
                    </p>
                </div>

                <div style="display: flex;justify-content: center" v-if="!subscriptionsLoaded">
                    <dotlottie-player
                        src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json"
                        background="transparent" speed="1" style="width: 150px; height: 150px;" loop
                        autoplay></dotlottie-player>
                </div>

            </div>
        </div>


        <delete-modal></delete-modal>
        <deactivate-modal></deactivate-modal>
        <downgrade-modal></downgrade-modal>
        <downgrade-to-artist-modal></downgrade-to-artist-modal>
        <modal name="modal-account-reg-form" @before-open="beforeOpen" @closed="closed" width="80%" height="auto"
            scrollable style="padding: 10px 35px;display: table-cell;">
            <div class="modal modal-account-reg-form">
                <div class="modal-header">
                    <close-icon class="float-right" @click.native="closeManageModals()"></close-icon>
                    <logo class="modal-logo centered-block" style="width: 185px;" />
                </div>
                <div class="modal-content full-width" v-if="!submitted" style="padding: 10px 35px;">

                    <connect-details :user="app.user" v-if="step === 2" @next-step="step++"
                        @finished="submitted = true"></connect-details>
                    <verification-details :user="app.user" v-if="step === 3"
                        @finished="upgradeAccount"></verification-details>
                </div>
            </div>
        </modal>
    </ph-panel>
</template>

<script>
import CloseIcon from '../../../global/close-icon';
import CircleTickIcon from '../../../icons/circle-tick.vue';
import CircleEmptyIcon from '../../../icons/circle-empty.vue';
import CircleExclamationIcon from '../../../icons/circle-exclamation.vue';
import DeleteModal from './../../../modals/delete';
import DowngradeModal from './../../../modals/downgrade-confirm';
import DowngradeArtistModal from './../../../modals/downgrade-to-artist-confirm';
import DeactivateModal from './../../../modals/deactivate';
import ConnectDetails from './connect-details';
import VerificationDetails from './verification-details';
import MembershipPaymentMethodView from '../../../global/membership-payment-method.vue';
import Subscriptions from "./subscriptions/subscriptions";
import SubscriptionPlan from '../account/subscriptions/subscription-plan'
import { mapState } from "vuex";

export default {
    data() {
        return {
            step: 1,
            upgrading: false,
            upgradingPro: false,
            downgrading: false,
            submitted: false,
            card: null,
            plans: [],
            plansLoaded: false,
            subscriptions: [],
            membershipPaymentMethod: null,
            subscriptionsLoaded: false,
            loading: false,
            stripeSubscriptionStatus: null,
        }
    },
    computed: {
        ...mapState([
            'app'
        ])
    },
    mounted: function () {
        this.getPaymentMethod();
        this.getSubscriptionPlans()
        this.getSubscriptions()
    },
    methods: {
        async getSubscriptionPlans() {
            this.plansLoaded = false
            await axios.get('/api/account/subscription/plans').then(response => {
                this.plansLoaded = true
                this.plans = response.data
                console.log("subs plan", this.plans);
            })
        },
        async getSubscriptions() {
            this.subscriptionsLoaded = false
            await axios.get('/api/account/subscription/subscriptions').then(response => {
                this.subscriptionsLoaded = true;
                this.membershipPaymentMethod = response.data.paymentMethods;
                this.subscriptions = response.data.subscriptions;
                this.stripeSubscriptionStatus = response.data.subscription.status;
                console.log("subssss", this.subscriptions);

            }).catch(err => {
                console.log("asdasdas");
                this.subscriptionsLoaded = true;
            })
        },
        getSubscriptionForPlan(plan) {
            return _.find(this.subscriptions, { 'stripe_plan': this.toSnakeCase(plan.title) })
        },
        toSnakeCase(str) {
            return str &&
                str
                    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
                    .map(x => x.toLowerCase())
                    .join('_')
        },
        subscribe(isTrial) {
            this.loading = true
            axios.get('/api/account/subscription/plan/subscribe/' + isTrial).then(response => {
                this.loading = false
                if (response.data.success) {
                    // window.location.href = response.data.subscription;
                    this.localSubscription = response.data.subscription
                    this.$notify({
                        group: 'main',
                        type: 'success',
                        title: '<img src="/img/confirm.gif" alt="success" width="60%">',
                    })
                    window.location.reload();
                } else {
                    this.$notify({
                        group: 'main',
                        type: 'error',
                        title: response.data.message,
                    })
                }
            }).finally(response => {
                this.loading = false
            })
        },
        getPaymentMethod() {
            axios.get('/api/account/billing/method')
                .then(response => {
                    this.card = response.data.payment_method
                    console.log("billing card", this.card);
                })
        },
        handleRemove() {
            this.card = null
        },
        beforeOpen() {

        },
        closed() {
            if (this.upgrading) {
                this.upgrading = false;
            }
            if (this.downgrading) {
                this.downgrading = false;
            }
        },
        closeManageModals() {
            this.$modal.hide('modal-account-reg-form');
            if (this.upgrading) {
                this.upgrading = false;
            }
            if (this.downgrading) {
                this.downgrading = false;
            }
        },
        deactivateAccount() {
            this.$modal.show('modal-deactivate');
        },
        deleteAccount() {
            this.$modal.show('modal-delete');
        },
        openVerificationModal() {
            this.step = 2;
            this.$modal.show("modal-account-reg-form");
            this.upgrading = true;
        },
        upgradeAccount() {
            this.upgrading = true;
            this.$modal.hide("modal-account-reg-form");
            axios.post('/api/account/upgrade', { user_id: this.app.user })
                .then(response => {
                    this.$store.commit('app/setUser', response.data.user)
                    this.$notify({
                        group: 'main',
                        type: 'success',
                        title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
                        duration: 1500,
                    });
                    this.upgrading = false;
                    if (response.data.url) {
                        window.location.href = response.data.url;
                    } else {
                        window.location.reload();
                    }
                    window.location.href = response.data.url;
                });
        },
        upgradeAccountToPro() {
            this.upgradingPro = true;
            axios.post('/api/account/upgrade/pro', { user_id: this.app.user })
                .then(response => {
                    this.$store.commit('app/setUser', response.data.user)
                    this.$notify({
                        group: 'main',
                        type: 'success',
                        title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
                        duration: 1500,
                    });
                    this.upgradingPro = false;
                    if (response.data.url) {
                        window.location.href = response.data.url;
                    } else if (response.data.sessionCheckoutURL) {
                        window.location.href = response.data.sessionCheckoutURL;
                    }
                    else {
                        window.location.reload();
                    }
                });
        },
        downgradeAccount() {
            this.$modal.show('modal-downgrade');
            // if (this.app.user.roles[0].name != 'artist') {
            //     this.$modal.show('modal-downgrade');
            // } else {
            //     this.downgrading = true
            //     axios.post('/api/account/downgrade', {user_id: this.app.user})
            //         .then(response => {
            //             this.$store.commit('app/setUser', response.data)
            //             this.$notify({
            //                 group: 'main',
            //                 type: 'success',
            //                 title: 'Successfully downgrade account',
            //             });
            //             this.downgrading = false;
            //         }).finally(()=>location.reload())
            // }

        },
        downgradeToArtist() {
            this.$modal.show('modal-to-artist-downgrade');
        }
    },
    components: {
        'delete-modal': DeleteModal,
        'downgrade-modal': DowngradeModal,
        'downgrade-to-artist-modal': DowngradeArtistModal,
        'deactivate-modal': DeactivateModal,
        'connect-details': ConnectDetails,
        'verification-details': VerificationDetails,
        'close-icon': CloseIcon,
        'circle-tick-icon': CircleTickIcon,
        'circle-empty-icon': CircleEmptyIcon,
        'circle-exclamation-icon': CircleExclamationIcon,
        'membership-payment-method-view': MembershipPaymentMethodView,
        'subscriptions': Subscriptions,
        'subscription-plan': SubscriptionPlan,

    }
}
</script>
<style lang="scss" scoped>
.border-line {
    width: 1px;
    background: #30f;
    margin-top: -10px;
}

p {
    margin: 10px 0;
}

.button.medium {
    border-radius: 6px !important;
}

.account-type {
    margin: 20px 0;
    font-weight: bold;
    text-transform: capitalize;
}

.col-header {
    border-bottom: 1px solid #30f;
    padding-bottom: 0.6rem;
    margin-bottom: 20px;

    h2 {
        margin-bottom: 0;
        font-size: 24px;
    }
}

.deactive-btn button {
    border-radius: 6px;
    color: #555756;
    font-size: 15px;

}

.del-btn button {
    border-radius: 6px;
    color: #AE1803;
    font-size: 15px;
}

.account-setting {
    display: flex;
    justify-content: space-between;
}

.membership {
    background-color: #fff;
    padding: 20px;
    width: 45.5%;
}

.title-block {
    display: flex;
    justify-content: center;
}

.title-block h4 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 1px solid #3300ff;
}

.status-setting {
    width: 80%;
}

.account-status {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
}

.account-status span {
    position: relative
}

.pro-badge {
    border-radius: 10px;
    border: 1px solid #3523FB;
    background: #3523FB;
    position: absolute;
    font-size: 8px;
    padding: 2px 4px;
    color: white;
    line-height: 8px;
    top: -10px;
    line-height: 8px;
    left: -5px;
}

.account-status-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    font-weight: bold;
}

.btn-box {
    display: flex;
    gap: 28px;
}
</style>
<style>
.tooltip-content {
    font-family: "Comfortaa", cursive;
}
</style>