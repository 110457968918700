var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "upload-progress" }, [
      _c("div", { staticClass: "progress-outer" }, [
        _c("div", {
          staticClass: "progress-inner",
          style: "width: " + _vm.upload.progress + "%",
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "upload-status" }, [
      _vm._v("\n    " + _vm._s(_vm.upload.message)),
      _vm.upload.progress < 100 ? _c("span", [_vm._v("...")]) : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _c("br"),
      _vm._v(" "),
      _c("span", { staticClass: "bold" }, [
        _vm._v(_vm._s(_vm.upload.progress) + "%"),
      ]),
    ]),
    _vm._v(" "),
    _vm.upload.progress < 100
      ? _c("p", { staticClass: "upload-info italic" }, [
          _vm._v(
            "\n    We're uploading your release. Please don't leave or refresh this\n    page.\n  "
          ),
        ])
      : _c(
          "p",
          { staticClass: "upload-info italic" },
          [
            _vm._v(
              "\n    We need to approve your release before it goes live. You can check\n    the status of your release by visiting your\n    "
            ),
            _c(
              "router-link",
              {
                attrs: { to: "/account/releases" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.closeModal.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n      releases page\n    ")]
            ),
            _vm._v(".\n  "),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }