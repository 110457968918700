var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sidebar-group", {
        attrs: {
          title: "Followed",
          "view-all": "/user/followed",
          items: _vm.user.following.slice(0, 5),
        },
      }),
      _vm._v(" "),
      _c("sidebar-group", {
        attrs: {
          title: "Favourites",
          "view-all": _vm.favouritesLink,
          items: _vm.favourites.slice(0, 5),
        },
      }),
      _vm._v(" "),
      this.user.plays
        ? _c("sidebar-group", {
            attrs: {
              title: "Recently Played",
              "view-all": "/user/recently-played",
              items: this.user.plays.slice(0, 5),
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("sidebar-group", {
        attrs: {
          title: "News",
          "view-all": "/news",
          items: _vm.news.articles.slice(0, 5),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }