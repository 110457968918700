var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ul", { staticClass: "action-sub-menu" }, [
    _c(
      "li",
      [
        _vm._v("\n        Dutty Bassline "),
        _c("i", { staticClass: "fa fa-caret-right" }),
        _vm._v(" "),
        _vm._l(_vm.test, function (x) {
          return _c("action-sub-menu", { key: x, attrs: { actionable: {} } })
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c("li", [_vm._v("D-D-D-Dubstep")]),
    _vm._v(" "),
    _c("li", [_vm._v("Grime Rhymes")]),
    _vm._v(" "),
    _c("li", [_vm._v("Drum & Bass")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }