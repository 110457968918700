var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "artist-type-select" }, [
    _c(
      "ul",
      { staticClass: "gridtypelist list-unstyled list-inline float-left" },
      _vm._l(_vm.$store.state.app.artistTypes, function (artistType, i) {
        return _c(
          "li",
          {
            key: i,
            class: {
              highlighted: _vm.artistTypeId === artistType.id && i == 0,
            },
          },
          [
            _c(
              "label",
              {
                class: [
                  _vm.artistTypeId === artistType.id
                    ? "active"
                    : "" && i == 0
                    ? "active"
                    : "",
                ],
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.artistTypeId,
                      expression: "artistTypeId",
                    },
                  ],
                  attrs: { type: "radio" },
                  domProps: {
                    checked: i == 0,
                    value: artistType.id,
                    checked: _vm._q(_vm.artistTypeId, artistType.id),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectArtistType(artistType)
                    },
                    change: function ($event) {
                      _vm.artistTypeId = artistType.id
                    },
                  },
                }),
                _vm._v("I am a " + _vm._s(artistType.name) + "\n        "),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }