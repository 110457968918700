var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "profile-action", on: { click: _vm.toggle } },
    [
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.user.followed,
              expression: "user.followed",
            },
          ],
        },
        [
          _c("i", { staticClass: "fa fa-check-circle" }),
          _vm._v(" Following\n    "),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.user.followed,
              expression: "!user.followed",
            },
          ],
        },
        [_c("i", { staticClass: "fa fa-plus-circle" }), _vm._v(" Follow\n    ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }