import { render, staticRenderFns } from "./profile-pro.vue?vue&type=template&id=6772d850&scoped=true&"
import script from "./profile-pro.vue?vue&type=script&lang=js&"
export * from "./profile-pro.vue?vue&type=script&lang=js&"
import style0 from "./profile-pro.vue?vue&type=style&index=0&id=6772d850&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6772d850",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.phase.uk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6772d850')) {
      api.createRecord('6772d850', component.options)
    } else {
      api.reload('6772d850', component.options)
    }
    module.hot.accept("./profile-pro.vue?vue&type=template&id=6772d850&scoped=true&", function () {
      api.rerender('6772d850', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/vue/components/pages/user/profile/profile-pro.vue"
export default component.exports