var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.pageComponent, {
    tag: "component",
    attrs: {
      "featured-items": _vm.featuredItems,
      "chart-items": _vm.chartItems,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }