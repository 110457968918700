var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticStyle: { height: "200px", width: "200px" },
      style: _vm.genre.id % 2 != 0 ? "color:#3300ff;background:#fff;" : null,
      attrs: { to: _vm.getRouterObject(_vm.genre) },
    },
    [
      _c("div", { staticClass: "genre-name" }, [
        _vm._v("\n    " + _vm._s(_vm.genre.name) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }