var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h2", { staticStyle: { "text-align": "center" } }, [
        _vm._v("Add any other Directors, Owners and Executives’ "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "persons-block" },
        _vm._l(_vm.$store.state.app.account.persons, function (person, index) {
          return _vm.$store.state.app.account.persons.length > 0
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between",
                    padding: "10px",
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(person.first_name) +
                        " " +
                        _vm._s(person.last_name) +
                        ": " +
                        _vm._s(person.relationship) +
                        ", percentage " +
                        _vm._s(person.percentage) +
                        "% "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "ph-button",
                        {
                          attrs: { size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleEdit($event, index)
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\tEdit\n\t\t\t\t")]
                      ),
                      _vm._v(" "),
                      _c(
                        "ph-button",
                        {
                          attrs: { size: "small" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleRemove($event, index)
                            },
                          },
                        },
                        [_vm._v("\n\t\t\t\t\tRemove\n\t\t\t\t")]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e()
        }),
        0
      ),
      _vm._v(" "),
      _c(
        "ph-button",
        {
          attrs: { size: "medium" },
          nativeOn: {
            click: function ($event) {
              return _vm.handleAddPerson($event)
            },
          },
        },
        [_vm._v("\n\t\tAdd a Person\n\t")]
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "submit-buttons" }, [
        _c(
          "div",
          { staticClass: "button-wrap" },
          [
            _c(
              "ph-button",
              {
                attrs: { size: "medium" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.handleSubmit($event)
                  },
                },
              },
              [_vm._v("\n\t\t\t\tSave & Continue\n\t\t\t")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }