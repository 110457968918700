var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-auth-register",
        width: "80%",
        height: "auto",
        adaptive: "",
      },
    },
    [
      _c("div", { staticClass: "modal modal-auth-register" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("logo", {
              staticClass: "modal-logo centered-block",
              staticStyle: { width: "185px" },
            }),
            _vm._v(" "),
            _c("close-icon", {
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-auth-register")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          _c(
            "p",
            {
              staticClass: "centered-text",
              staticStyle: { "margin-top": "50px" },
            },
            [
              _vm._v("\n        Already a member? "),
              _c("a", { attrs: { href: "#" }, on: { click: _vm.showLogin } }, [
                _vm._v("Login"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "register-selections" },
            _vm._l(_vm.plans, function (plan) {
              return _c(
                "div",
                { key: plan.id, staticClass: "register-selections-option" },
                [
                  plan.role_id == 5
                    ? _c("img", {
                        staticClass: "plan_img",
                        attrs: { src: "/img/artist.jpg" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  plan.role_id == 6
                    ? _c("img", {
                        staticClass: "plan_img",
                        attrs: { src: "/img/artist_pro.jpg" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  plan.role_id == 4
                    ? _c("img", {
                        staticClass: "plan_img",
                        attrs: { src: "/img/standard.jpg" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "option-title",
                      staticStyle: {
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "flex-start",
                      },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            plan.title === "Artist Pro" ? "Artist" : plan.title
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      plan.title == "Artist Pro"
                        ? _c("span", { staticClass: "plan_subtitle" }, [
                            _c("small", [_vm._v("Pro")]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "button-container" },
                    [
                      _c(
                        "ph-button",
                        {
                          attrs: { size: "giant" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.showRegisterForm(plan)
                            },
                          },
                        },
                        [_vm._v("\n              Join\n            ")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "option-features",
                    domProps: { innerHTML: _vm._s(plan.content) },
                  }),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }