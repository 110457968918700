var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded page-news-index" }, [
    _c(
      "div",
      { staticClass: "page-main" },
      [
        _vm.genre
          ? _c(
              "div",
              [
                _c("h2", [_vm._v(_vm._s(_vm.genre.name) + " Samples")]),
                _vm._v(" "),
                _vm._l(_vm.items, function (item) {
                  return _c("div", { key: item.id }, [
                    _c("div", { staticClass: "p-item" }, [
                      _c(
                        "div",
                        { staticClass: "p-item-image" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "sample_single",
                                  params: { releaseid: item.id },
                                },
                              },
                            },
                            [
                              _c("avatar", {
                                attrs: {
                                  size: 130,
                                  src: item.image.files.thumb.url,
                                  tile: true,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "p-item-main" }, [
                        _c("div", { staticClass: "p-item-detail" }, [
                          _c(
                            "div",
                            { staticClass: "p-item-title" },
                            [
                              _c(
                                "router-link",
                                {
                                  staticStyle: { "text-decoration": "none" },
                                  attrs: {
                                    to: {
                                      name: "sample_single",
                                      params: { releaseid: item.id },
                                    },
                                  },
                                },
                                [_c("span", [_vm._v(_vm._s(item.name))])]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "release-description" }, [
                          _vm._v(
                            "\n                            " +
                              _vm._s(item.description) +
                              "\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "p-item-meta" },
                          [
                            _c("actions", { attrs: { actionable: item } }),
                            _vm._v(" "),
                            _c("div", { staticClass: "p-item-time" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s(
                                    _vm.moment(item.release_date).fromNow()
                                  ) +
                                  "\n                            "
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ])
                }),
              ],
              2
            )
          : _c("spinner", {
              staticStyle: { margin: "3em auto" },
              attrs: { "animation-duration": 1000, size: 80, color: "black" },
            }),
        _vm._v(" "),
        _vm.hasAnotherPage && _vm.items.length
          ? _c(
              "div",
              {
                staticClass: "centered-text",
                staticStyle: { margin: "4em 0" },
              },
              [
                _c(
                  "ph-button",
                  {
                    attrs: { size: "large", loading: _vm.loadingNextPage },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.loadNextPage.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n                Show Me More\n            ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "aside",
      { staticClass: "sidebar-right" },
      [
        _c("sidebar-group", {
          attrs: { title: "News", items: _vm.news.articles.slice(0, 5) },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }