<template>
  <div v-if="method">
    <div class="d-flex">
      <div class="card-brand">
        <img :src="`/img/cards/${method.type}.png`">
      </div>
      <div class="card-details">
        <p class="card-name">{{ method.billing_details.name }}</p>
        <p class="card-num">**** **** {{ method[method.type].last4 }}</p>
        <p class="card-num">Sort: {{ method[method.type].sort_code }}</p>
        <!-- <p>Expires {{ card.card.exp_month }}/{{ card.card.exp_year }}</p> -->
      </div>
      <!-- <div class="card-actions" v-if="actions">
        <ph-button class="remove" @click.native.prevent="removeCard" size="small" :loading="submitting">Remove</ph-button>

      </div> -->
    </div>
  </div>
  <div v-else style="text-align: center;">
    No Card details found.
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  props: ['method'],
  data() {
    return {
      submitting: false,
    }
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: white;
  padding: 10px;
  margin-bottom: 20px;
  align-items: center;
  gap: 30px;

  .card-brand {
    display: flex;
    align-items: center;

    img {
      width: 150px;
      margin-right: 15px;
    }
  }

  .card-details {
    margin-top: 10px;
  }

  .card-name,
  .card-num {
    font-weight: bold;
    text-transform: uppercase;
  }

  .card-details p {
    font-size: 20px;
    padding-bottom: 10px;
    padding-right: 65px;
  }

  .card-actions {
    display: flex;
    align-items: center;
  }
}

#current-card {
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fdfdfd;
  border-radius: 5px;
  align-items: center;

  .card-brand {
    padding-right: 1rem;

    img {
      width: 40px
    }
  }

  .card-details {
    font-size: 14px;

    p {
      &:first-child {
        margin-bottom: .5rem;
        font-weight: bold;
      }
    }
  }

  .card-actions {
    align-self: center;
    flex: 1;
    text-align: right;

    .remove {
      color: #ff6e6e;
      text-decoration: none;
    }
  }
}</style>