var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-checkout",
        width: "600px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
      on: {
        closed: _vm.closed,
        "before-close": _vm.closed,
        opened: _vm.opened,
      },
    },
    [
      _c("div", { staticClass: "modal modal-checkout" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("logo", {
              staticClass: "modal-logo centered-block",
              staticStyle: { width: "185px" },
            }),
            _vm._v(" "),
            _c("close-icon", {
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-checkout")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          !_vm.complete
            ? _c("div", [
                _c(
                  "h2",
                  {
                    staticClass: "payment-info-header",
                    staticStyle: { "text-align": "center" },
                  },
                  [_vm._v("Payment Information")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loadedInitialCardData,
                        expression: "loadedInitialCardData",
                      },
                    ],
                  },
                  [
                    !_vm.stripePayment
                      ? _c(
                          "div",
                          [
                            _c("default-card-account", {
                              attrs: { card: _vm.card },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "card-details-info",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.showCardInput.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("Add new card")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.stripePayment,
                            expression: "stripePayment",
                          },
                        ],
                      },
                      [
                        _c("div", {
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: { id: "card-element" },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          attrs: { id: "card-errors", role: "alert" },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "save-card-block" }, [
                          _c("span", { staticClass: "save-card-text" }, [
                            _vm._v(
                              "Would you like to save this card to your account?"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.isSaveTheCard,
                                  expression: "isSaveTheCard",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.isSaveTheCard)
                                  ? _vm._i(_vm.isSaveTheCard, null) > -1
                                  : _vm.isSaveTheCard,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.isSaveTheCard,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.isSaveTheCard = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.isSaveTheCard = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.isSaveTheCard = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.loadedInitialCardData,
                        expression: "!loadedInitialCardData",
                      },
                    ],
                  },
                  [
                    _c("spinner", {
                      staticStyle: { margin: "3em auto" },
                      attrs: {
                        "animation-duration": 1000,
                        size: 60,
                        color: "black",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "h2",
                  {
                    staticClass: "no-top",
                    staticStyle: { "text-align": "center" },
                  },
                  [_vm._v("Order Information")]
                ),
                _vm._v(" "),
                _c("table", [
                  _c("tr", [
                    _c("td", [
                      _vm._v("\n              Cart Subtotal\n            "),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n              £" +
                          _vm._s(_vm.$store.getters["cart/getTotal"]) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("\n              Taxes\n            ")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n              £" +
                          _vm._s(_vm.taxes) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", { staticClass: "border-top bold" }, [
                    _c("td", [_vm._v("\n              Total\n            ")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n              £" +
                          _vm._s(_vm.total) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm.total >=
                parseInt(_vm.settings.purchase_approval_threshold) / 100
                  ? _c("p", { staticClass: "alert" }, [
                      _vm._v(
                        "\n          As this purchase is greater than £" +
                          _vm._s(
                            parseInt(_vm.settings.purchase_approval_threshold) /
                              100
                          ) +
                          ", it\n          will need to be manually approved by an admin.\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.loadedInitialCardData,
                        expression: "!loadedInitialCardData",
                      },
                    ],
                    staticStyle: { "text-align": "center" },
                  },
                  [_c("i", { staticClass: "fa fa-spinner fa-spin" })]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loadedInitialCardData,
                        expression: "loadedInitialCardData",
                      },
                    ],
                    staticStyle: { "text-align": "center" },
                  },
                  [
                    _c(
                      "ph-button",
                      {
                        attrs: {
                          id: "paymentButton",
                          size: "large",
                          loading: _vm.loading,
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.pay.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Pay")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.loadedInitialCardData,
                        expression: "loadedInitialCardData",
                      },
                    ],
                    staticStyle: { "text-align": "center" },
                  },
                  [
                    _vm._v(
                      "\n          By confirming this purchase, you agree to the Phase Terms of Use.\n        "
                    ),
                  ]
                ),
              ])
            : _c(
                "div",
                [
                  _vm.error
                    ? _c(
                        "ph-panel",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { type: "error" },
                        },
                        [
                          _c("h2", { staticClass: "no-top header" }, [
                            _vm._v("Error!"),
                          ]),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              "\n            There was an error processing your payment. You have not been charged.\n          "
                            ),
                          ]),
                        ]
                      )
                    : _c(
                        "ph-panel",
                        {
                          staticStyle: { "text-align": "center" },
                          attrs: { type: "success" },
                        },
                        [
                          _c("h2", { staticClass: "no-top header" }, [
                            _vm._v("Success!"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            [
                              _vm._v(
                                "\n            Your purchase is complete. Visit your\n            "
                              ),
                              _c(
                                "router-link",
                                {
                                  attrs: { to: "/account/mymusic" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.$modal.hide("modal-checkout")
                                    },
                                  },
                                },
                                [_vm._v("music\n            ")]
                              ),
                              _vm._v(
                                "\n            page to download your tracks now!\n          "
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                ],
                1
              ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }