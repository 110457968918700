var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.link
    ? _c("router-link", { attrs: { to: _vm.link } }, [
        _c("div", { staticClass: "info-button" }, [
          _c("span", [_c("i", { staticClass: "fa fa-info-circle" })]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }