var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ph-panel",
    { attrs: { id: "billing" } },
    [
      _c("h2", [_vm._v("Payment Methods")]),
      _vm._v(" "),
      _c("existing-card-account", {
        attrs: { cards: _vm.cards, account: "bill", actions: true },
        on: { handleRemove: _vm.handleRemove },
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "add-card-btn",
          on: { click: _vm.openAddPaymentMethodModal },
        },
        [_c("i", { staticClass: "fa fa-plus" })]
      ),
      _vm._v(" "),
      _c("AddPaymentMethod", { attrs: { clientSecret: _vm.clientSecret } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }