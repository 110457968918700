var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("p", [_vm._v("\n        Supported file types: mp4\n    ")]),
    _vm._v(" "),
    _c("div", { ref: "dropZone", staticClass: "drop-zone" }, [
      _c("span", { ref: "browseButton", staticClass: "browse-button" }, [
        _vm._v("\n            Browse\n        "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }