import { render, staticRenderFns } from "./thread.vue?vue&type=template&id=e24bea36&scoped=true&"
import script from "./thread.vue?vue&type=script&lang=js&"
export * from "./thread.vue?vue&type=script&lang=js&"
import style0 from "./thread.vue?vue&type=style&index=0&id=e24bea36&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e24bea36",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.phase.uk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e24bea36')) {
      api.createRecord('e24bea36', component.options)
    } else {
      api.reload('e24bea36', component.options)
    }
    module.hot.accept("./thread.vue?vue&type=template&id=e24bea36&scoped=true&", function () {
      api.rerender('e24bea36', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/vue/components/pages/user/thread.vue"
export default component.exports