var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "message-thread" },
    [
      _c(
        "div",
        { staticClass: "avatar-wrap" },
        [
          _vm.getReceiverBadge(_vm.thread)
            ? _c("avatar", {
                attrs: {
                  verified: _vm.isVerified(_vm.thread),
                  size: 98,
                  src: _vm.getReceiver(_vm.thread, "avatar"),
                  labels: {
                    tr: {
                      text: _vm.getReceiverBadge(_vm.thread),
                      color: "blue",
                    },
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.getReceiverBadge(_vm.thread)
            ? _c("avatar", {
                attrs: { size: 98, src: _vm.getReceiver(_vm.thread, "avatar") },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "detail" }, [
        _vm.thread.last_message
          ? _c("div", { staticClass: "info-top" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.getReceiver(_vm.thread, "name")) +
                  " "
              ),
              _c("small", [
                _vm._v(
                  _vm._s(_vm.moment(_vm.thread.last_message.date).calendar())
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.thread.last_message
          ? _c("div", { staticClass: "message" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.thread.last_message.body) +
                  "\n        "
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "actions" }, [
          _c(
            "div",
            { staticClass: "action" },
            [
              _c("like-button", {
                attrs: { likeable: _vm.thread },
                on: { like: _vm.liked, unlike: _vm.unliked },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "action" },
            [_c("report-button", { attrs: { reportable: _vm.thread } })],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "action",
              on: {
                click: function ($event) {
                  return _vm.handleRemoveConversation(_vm.thread.id)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-trash" })]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "action" },
            [
              _c(
                "router-link",
                { attrs: { to: { path: `/user/thread/${_vm.thread.id}` } } },
                [_vm._v("Expand")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("remove-conversation"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }