var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "comment" }, [
    _c(
      "div",
      { staticClass: "comment-avatar" },
      [_c("avatar", { attrs: { src: _vm.data.user.avatar.files.thumb.url } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "comment-main" }, [
      _c("div", { staticClass: "comment-upper" }, [
        _c("div", { staticClass: "comment-name" }, [
          _vm._v("\n        " + _vm._s(_vm.data.user.name) + "\n      "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "comment-time" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.moment(_vm.data.created_at).fromNow()) +
              "\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "comment-acitons" }, [
        !_vm.newcomment
          ? _c("div", {
              staticClass: "comment-message",
              domProps: { innerHTML: _vm._s(_vm.bodyHtml) },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.newcomment
          ? _c("div", { staticClass: "comment-message" }, [
              _vm._v(_vm._s(_vm.data.body)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "comment-actions" },
          [
            _c("report-button", {
              attrs: { reportable: _vm.data, hidden: "" },
            }),
            _vm._v(" "),
            this.app.user.id == _vm.data.user_id
              ? _c("delete-button", {
                  attrs: { deleteable: _vm.data, hidden: "" },
                })
              : _vm._e(),
            _vm._v(" "),
            this.app.user.id == _vm.data.user_id
              ? _c("edit-comment-button", {
                  attrs: { comment: _vm.data, hidden: "" },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }