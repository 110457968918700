<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 63.99 40.6">
        <title>Phase Logo Lines</title>
        <path d="M61.55,13.94a2.34,2.34,0,0,0-2.44,2.38v3.9H55V8.52a2.44,2.44,0,0,0-4.88,0V20.22H45.82V5.93a2.44,2.44,0,0,0-4.88,0V20.22H36.59V2.38a2.44,2.44,0,0,0-4.88,0V20.22H27.58V9.5a2.44,2.44,0,0,0-4.88,0V20.31h-4V6.44a2.34,2.34,0,0,0-2.44-2.38,2.34,2.34,0,0,0-2.44,2.38V20.22H9.5V13.37A2.34,2.34,0,0,0,7.06,11a2.34,2.34,0,0,0-2.44,2.38v6.85H0v7.2A2.34,2.34,0,0,0,2.44,29.8a2.34,2.34,0,0,0,2.44-2.38V20.76h4.4V34.35a2.34,2.34,0,0,0,2.44,2.38,2.34,2.34,0,0,0,2.44-2.38V20.57h4V31.38a2.34,2.34,0,0,0,2.44,2.38A2.34,2.34,0,0,0,23,31.38V20.57h4.12V38.22a2.44,2.44,0,0,0,4.88,0V20.57h4.31v14.1a2.44,2.44,0,0,0,4.88,0V20.57h4.28V32.28a2.44,2.44,0,0,0,4.88,0V20.57h4.22v3.9a2.44,2.44,0,0,0,4.88,0v-3.9H64V16.33A2.34,2.34,0,0,0,61.55,13.94Z"/>
    </svg>
</template>

<script>
  export default {
    name: 'logo-lines',
  }
</script>

<style lang="scss" scoped>

</style>