var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded page-news-index" }, [
    _c("div", { staticClass: "page-main" }, [
      _c("div", { staticClass: "genres" }, [
        _c(
          "div",
          { staticClass: "genres-container" },
          _vm._l(_vm.app.genres, function (genre) {
            return _c(
              "div",
              { key: genre.name, staticClass: "genre" },
              [
                _c(
                  "router-link",
                  { attrs: { to: { path: `/samples/${genre.id}` } } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "genre-bg",
                        staticStyle: { "background-size": "cover" },
                        style: {
                          "background-image": `url(${genre.image.files.original.url})`,
                        },
                      },
                      [
                        _c("div", { staticClass: "genre-name" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(genre.name) +
                              "\n                  "
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "aside",
      { staticClass: "sidebar-right" },
      [
        _c("sidebar-group", {
          attrs: { title: "News", items: _vm.news.articles.slice(0, 5) },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }