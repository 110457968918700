import { render, staticRenderFns } from "./sample-packs.vue?vue&type=template&id=248bfe66&"
import script from "./sample-packs.vue?vue&type=script&lang=js&"
export * from "./sample-packs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.phase.uk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('248bfe66')) {
      api.createRecord('248bfe66', component.options)
    } else {
      api.reload('248bfe66', component.options)
    }
    module.hot.accept("./sample-packs.vue?vue&type=template&id=248bfe66&", function () {
      api.rerender('248bfe66', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/vue/components/pages/sample-packs/sample-packs.vue"
export default component.exports