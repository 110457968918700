<template>
    <modal name="modal-remove-conversation" width="500px" height="auto" scrollable adaptive>
        <div class="modal modal-remove-conversation">
            <div class="modal-header">
                <close-icon class="float-right" @click.native="$modal.hide('modal-remove-conversation')"></close-icon>
            </div>
            <div class="modal-content content-section">
                <h2>Remove Conversation</h2>
                <h3>Are you sure you want to remove this conversation?</h3>

                <div class="save-button">
                    <ph-button size="large" @click.native="removeConversation" :loading="submitting" color="danger-outline">Delete</ph-button>
                </div>

                <p class="description">Removing this conversation will delete all messages in the thread. This action cannot be undone, so please make sure you want to proceed.</p>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapState } from 'vuex';
import store from 'store';
import CloseIcon from 'global/close-icon';
export default {
    data() {
        return {
            submitting: false,
            response: null,
        }
    },
    created: function () {

    },
    mounted: function () {

    },
    computed: {
        ...mapState([
            'app',
            'messenger'
        ])
    },
    methods: {
        fetchThreads() {
            store.dispatch('messenger/fetchThreads').then(() => {
                next();
            });
        },
        removeConversation() {
            this.submitting = true;
            axios.post('/api/threads/remove/' + this.messenger.removeConversationId)
                .then(response => {
                    this.fetchThreads();
                    this.$modal.hide('modal-remove-conversation');
                    this.submitting = false;
                })
                .catch(error => console.log(error));
        }
    },
    components: {
        CloseIcon,
    }
}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

h2 {
    margin-bottom: 0;
}

p {
    margin: 1em 0;
}

textarea {
    box-sizing: border-box;
    border: 1px solid $color-grey2;
    padding: 5px;
    width: 100%;
    height: 50px;
}

.save-button {
    margin-top: 1em;
    text-align: center;
}

.error-msg {
    font-size: 70%;
    color: red;
}
</style>