var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "verify-details" }, [
    _c("h1", [_vm._v("Verification Details")]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("p", [
          _vm._v(
            "These documents are required to verify your identity, these are necessary checks that have to be carried\n            out in order to sell on phase."
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", { attrs: { for: "document" } }, [_vm._v("Add Document")]),
          _vm._v(" "),
          _c("input", {
            attrs: {
              type: "file",
              id: "document",
              name: "document",
              accept: ".jpeg,.jpg,.png",
            },
            on: { change: _vm.uploadOnChange },
          }),
          _vm._v(" "),
          _c("small", { staticClass: "filename" }, [
            _vm._v(_vm._s(_vm.documentName)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("label", { attrs: { for: "additional_document" } }, [
            _vm._v("Additional document"),
          ]),
          _vm._v(" "),
          _c("input", {
            attrs: {
              type: "file",
              id: "additional_document",
              name: "additional_document",
              accept: ".jpeg,.jpg,.png",
            },
            on: { change: _vm.uploadOnChange2 },
          }),
          _vm._v(" "),
          _c("small", { staticClass: "filename" }, [
            _vm._v(_vm._s(_vm.addDocumentName)),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "ph-button",
          {
            attrs: { loading: _vm.loading },
            nativeOn: {
              click: function ($event) {
                return _vm.submitForm.apply(null, arguments)
              },
            },
          },
          [_vm._v("Upload")]
        ),
        _vm._v(" "),
        _c(
          "ph-button",
          {
            nativeOn: {
              click: function ($event) {
                return _vm.skip.apply(null, arguments)
              },
            },
          },
          [_vm._v("Skip")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }