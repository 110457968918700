<template>
  <modal name="add-payment-method" width="600px" height="auto" @closed="closed" @before-close="closed" @opened="opened"
    scrollable adaptive>
    <div class="modal add-payment-method">
      <div class="modal-header">
        <logo class="modal-logo centered-block" style="width: 185px;" />
        <close-icon @click.native="$modal.hide('add-payment-method')"></close-icon>
      </div>
      <div class="modal-content">
        <!-- Stripe Elements Placeholder -->
        <div id="card-element" style="margin: 20px 0px;"></div>

        <ph-button id="card-button" size="small" :loading="submitting" color="primary-outline">Save</ph-button>
      </div>
    </div>
  </modal>
</template>

<script>
import Vue from 'vue'
import Logo from "global/logo";
import PhButton from 'global/ph-button'
import CloseIcon from 'global/close-icon'
import { HalfCircleSpinner as Spinner } from 'epic-spinners'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      // State
      user: null,
      submitting: false,
    }
  },
  props: {
    clientSecret: Object
  },
  computed: {
    ...mapState(['app']),
  },
  methods: {
    payment() {
      const stripe = Stripe(process.env.MIX_VUE_APP_STRIPE_KEY)
      const elements = stripe.elements()
      const cardElement = elements.create('card', { hidePostalCode: true })
      const cardButton = document.getElementById('card-button')

      cardElement.mount('#card-element')

      cardButton.addEventListener('click', async () => {
        this.submitting = true
        const { setupIntent, error } = await stripe.confirmCardSetup(
          this.clientSecret.client_secret, {
          payment_method: {
            card: cardElement,
            billing_details: { name: this.app.user.name },
          },
        },
        )

        if (error) {
          this.submitting = false
        } else {
          await axios.post('/api/account/billing', {
            payment_method: setupIntent.payment_method
          }).then(({ data }) => {
            this.submitting = false

            // Update user details in store
            this.$store.commit('app/setUser', data.user);
            this.$modal.hide('add-payment-method');
            Vue.notify({
              group: 'main',
              type: 'success',
              title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
              duration: 1500,
            })
          }).catch(error => {
            this.submitting = false
          }).finally(() => {
            window.location.reload();
          })
        }
      })
    },
    opened() {
      // Rehydrate the user on open
      this.user = this.$store.getters['app/getUser'];
      this.payment()
    },
    closed() {

    },
  },
  components: {
    PhButton,
    CloseIcon,
    Spinner,
  },
}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

.payment-info-header {
  margin-bottom: 10px;
}

.card-details-info {
  text-decoration: underline;
  margin: 1em 0;
  cursor: pointer;
}

p:first-of-type {
  margin-bottom: 2em;
}

p:last-of-type {
  margin-top: 1em;
}

input {
  font-size: 100%;
}

table {
  width: 100%;
  margin-bottom: 2em;
}

tr.border-top {
  border-top: 1px solid;
}

td {
  padding: 1em 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "No";
  height: 26px;
  width: 26px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  color: #000;
  transition: .4s;
  border-radius: 50%;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  /* Font size set to 12px */
}

input:checked+.slider:before {
  content: "Yes";
  transform: translateX(30px);
  background-color: #3300ff;
  color: #fff;
}

.save-card-block {
  margin: 20px 0px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.save-card-block .save-card-text {
  font-size: 14px;
}
</style>
