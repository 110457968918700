var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    this.$store.state.app.account.bussiness_type == "individual"
      ? _c("h1", [_vm._v("Professional details")])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { id: "account-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c("p", [
          _vm._v("Tell us a few details about how you earn money with Phase"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Your Website:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.site,
                    expression: "site",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      max: 255,
                      regex:
                        /^((https?):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
                    },
                    expression:
                      "{ max:255, regex: /^((https?):\\/\\/)?(www.)?[a-z0-9]+\\.[a-z]+(\\/[a-zA-Z0-9#]+\\/?)*$/ }",
                  },
                ],
                staticStyle: { "margin-top": "8px", "margin-bottom": "8px" },
                attrs: {
                  type: "text",
                  name: "site",
                  placeholder: "www.example.com",
                  "data-vv-validate-on": "blur",
                },
                domProps: { value: _vm.site },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.site = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(
                  "\n\t\t\t\t\t\t\t" +
                    _vm._s(_vm.errors.first("site")) +
                    "\n\t\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("site"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Product Description:")]),
            _vm._v(" "),
            _c("div", [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.prod_des,
                    expression: "prod_des",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max: 255",
                    expression: "'required|max: 255'",
                  },
                ],
                staticStyle: {
                  "margin-top": "8px",
                  "margin-bottom": "8px",
                  border: "1px solid grey",
                  "border-radius": "3px",
                },
                attrs: {
                  rows: "5",
                  name: "prod_des",
                  "data-vv-as": "product descrpiton",
                  "data-vv-validate-on": "blur",
                },
                domProps: { value: _vm.prod_des },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.prod_des = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(
                  "\n\t\t\t\t\t\t\t" +
                    _vm._s(_vm.errors.first("prod_des")) +
                    "\n\t\t\t\t\t\t"
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "submit-buttons" }, [
          _c(
            "div",
            { staticClass: "button-wrap" },
            [
              _c(
                "ph-button",
                { attrs: { size: "medium", loading: _vm.submitting } },
                [_vm._v("\n\t\t\t\t\t\tSave & Continue\n\t\t\t\t\t")]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }