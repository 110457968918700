var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", [_vm._v("Verification Details")]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("p", [
          _vm._v(
            "The following details are required to provide verification and a method of payment for sales"
          ),
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c(
          "ph-button",
          {
            attrs: { loading: _vm.loading },
            nativeOn: {
              click: function ($event) {
                return _vm.submitForm.apply(null, arguments)
              },
            },
          },
          [_vm._v("Upload")]
        ),
        _vm._v(" "),
        _c(
          "ph-button",
          {
            nativeOn: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$emit("finished")
              },
            },
          },
          [_vm._v("\n            Skip\n        ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("label", { attrs: { for: "document" } }, [_vm._v("Document")]),
      _vm._v(" "),
      _c("input", {
        attrs: {
          type: "file",
          id: "document",
          name: "document",
          accept: ".jpeg,.jpg,.png",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("label", { attrs: { for: "additional_document" } }, [
        _vm._v("Additional document"),
      ]),
      _vm._v(" "),
      _c("input", {
        attrs: {
          type: "file",
          id: "additional_document",
          name: "additional_document",
          accept: ".jpeg,.jpg,.png",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }