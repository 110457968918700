import { render, staticRenderFns } from "./verify-circle-icon.vue?vue&type=template&id=6fe73a25&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.phase.uk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6fe73a25')) {
      api.createRecord('6fe73a25', component.options)
    } else {
      api.reload('6fe73a25', component.options)
    }
    module.hot.accept("./verify-circle-icon.vue?vue&type=template&id=6fe73a25&", function () {
      api.rerender('6fe73a25', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/vue/components/icons/verify-circle-icon.vue"
export default component.exports