var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-item" }, [
    _c(
      "div",
      { staticClass: "p-item-image" },
      [
        _c(
          "router-link",
          { attrs: { to: _vm.getRouterObject(_vm.mutableTrack) } },
          [
            _c("avatar", {
              attrs: {
                size: 130,
                tile: true,
                labels: _vm.labels,
                src: _vm.mutableTrack.release.image.files.medium.url,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "p-item-main" }, [
      _c("div", { staticClass: "p-item-detail" }, [
        _c(
          "div",
          { staticClass: "p-item-title" },
          [
            _c(
              "router-link",
              {
                staticStyle: { "text-decoration": "none" },
                attrs: { to: _vm.getRouterObject(_vm.mutableTrack) },
              },
              [_c("span", [_vm._v(_vm._s(_vm.mutableTrack.name))])]
            ),
            _vm._v(" "),
            _c("play-pause-button", {
              attrs: { track: _vm.item, type: _vm.streamable },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "p-item-subtitle" }, [
          _vm._v(
            "\n        " + _vm._s(_vm.mutableTrack.release.name) + "\n      "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p-item-meta" },
        [
          _c("actions", {
            attrs: { actionable: _vm.mutableTrack },
            on: {
              like: _vm.liked,
              unlike: _vm.unliked,
              share: _vm.shared,
              commented: _vm.commented,
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "p-item-time" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.moment(_vm.mutableTrack.created_at).fromNow()) +
                "\n      "
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }