var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("notifications", {
        attrs: {
          group: "main",
          position: "top center",
          classes: "vue-notification-phase",
          width: "400px",
        },
      }),
      _vm._v(" "),
      _c("vue-snotify"),
      _vm._v(" "),
      _c("modals"),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "body",
          class: { "slideout-open": _vm.slideoutActive },
          attrs: { id: "page-content" },
        },
        [
          _c("div", { staticClass: "page" }, [
            _c(
              "main",
              {
                staticClass: "viewport",
                staticStyle: { "min-height": "100vh" },
              },
              [
                _c("top-bar", {
                  on: {
                    slideoutToggle: function ($event) {
                      _vm.mobileSlideoutActivte = !_vm.mobileSlideoutActivte
                    },
                    sidebarToggle: _vm.toggleSlideout,
                  },
                }),
                _vm._v(" "),
                _vm.app.user.loggedin || _vm.$route.path !== "/"
                  ? _c("user-bar")
                  : _vm._e(),
                _vm._v(" "),
                !_vm.app.user.loggedin && _vm.$route.path === "/"
                  ? _c("only-music-bar")
                  : _vm._e(),
                _vm._v(" "),
                _vm.search.visible
                  ? _c("search-view", { key: "search-view" })
                  : _vm._e(),
                _vm._v(" "),
                _c("router-view", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.search.visible,
                      expression: "!search.visible",
                    },
                  ],
                  key: "router-view",
                }),
                _vm._v(" "),
                _c("vue-progress-bar"),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("main-footer"),
          _vm._v(" "),
          _vm.slideoutActive
            ? _c("slideout-menu", {
                on: { slideoutToggle: _vm.toggleSlideout },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.mobileSlideoutActivte
            ? _c("mobile-slideout-menu", {
                on: {
                  slideoutToggle: function ($event) {
                    _vm.mobileSlideoutActivte = !_vm.mobileSlideoutActivte
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }