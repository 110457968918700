var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "notification-card",
        on: {
          click: function ($event) {
            return _vm.dismiss(_vm.item.id)
          },
        },
      },
      [
        _vm._v(
          "\n            " + _vm._s(_vm.item.data.message) + "\n            "
        ),
        _c("a", { staticClass: "notification-dismiss" }, [_vm._v("Dismiss")]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }