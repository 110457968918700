var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.user
      ? _c(
          "div",
          [
            _vm.isPro
              ? _c("user-profile-pro", { attrs: { user: _vm.user } })
              : _c("user-profile-fan", { attrs: { user: _vm.user } }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }