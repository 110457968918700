var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded page-news-index" }, [
    _c(
      "div",
      { staticClass: "page-main" },
      [
        _vm.release
          ? _c("div", [
              _c("h2", [
                _vm._v("Tracks for sample " + _vm._s(_vm.release.name)),
              ]),
              _vm._v(" "),
              _vm.release.tracks.length
                ? _c(
                    "div",
                    _vm._l(_vm.release.tracks, function (item) {
                      return _c(
                        "div",
                        { key: item.id },
                        [_c("music-item", { attrs: { item: item } })],
                        1
                      )
                    }),
                    0
                  )
                : _c("div", [
                    _vm._v("\n        No Tracks for this sample.\n      "),
                  ]),
            ])
          : _c("spinner", {
              staticStyle: { margin: "3em auto" },
              attrs: { "animation-duration": 1000, size: 80, color: "black" },
            }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "aside",
      { staticClass: "sidebar-right" },
      [
        _c("sidebar-group", {
          attrs: { title: "News", items: _vm.news.articles.slice(0, 5) },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }