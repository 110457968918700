var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass: "fa-layers fa-fw player-control",
      on: { click: _vm.togglePlayback },
    },
    [
      _c("span", [
        _c("i", {
          staticClass: "fa fa-fw fa-circle",
          attrs: { "data-fa-transform": "grow-" + _vm.size },
        }),
      ]),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.playing,
              expression: "!playing",
            },
          ],
        },
        [
          _c("i", {
            staticClass: "fa fw-fw fa-play",
            staticStyle: { color: "white" },
            attrs: { "data-fa-transform": "shrink-" + (_vm.size / 8 + 3) },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.playing,
              expression: "playing",
            },
          ],
        },
        [
          _c("i", {
            staticClass: "fa fw-fw fa-pause",
            staticStyle: { color: "white" },
            attrs: { "data-fa-transform": "shrink-" + (_vm.size / 8 + 3) },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }