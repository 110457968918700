var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ph-panel",
    { attrs: { id: "subscriptions" } },
    [
      _c("h2", [_vm._v("Subscriptions")]),
      _vm._v(" "),
      _c("p", [_vm._v("\n    All subscriptions are billed monthly.\n  ")]),
      _vm._v(" "),
      _vm._l(_vm.subscriptions, function (subscription) {
        return _vm.plansLoaded && _vm.subscriptionsLoaded
          ? _c("subscription-plan", {
              key: subscription.id,
              attrs: {
                subscription: subscription,
                plan:
                  subscription.stripe_plan == "Artist Pro EU"
                    ? _vm.plans[0]
                    : _vm.plans[1],
              },
            })
          : _vm._e()
      }),
      _vm._v(" "),
      _vm.subscriptionsLoaded && _vm.subscriptions.length == 0
        ? _c(
            "div",
            { staticClass: "no-subscription-block" },
            [
              _c("p", { staticClass: "no-subscription-label" }, [
                _vm._v("Not subscribed, free 30 days trail available."),
              ]),
              _vm._v(" "),
              _c(
                "ph-button",
                {
                  staticClass: "trial-button",
                  attrs: { size: "medium", loading: _vm.loading },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.subscribe.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n      Start Trial\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !(_vm.plansLoaded && _vm.subscriptionsLoaded),
              expression: "!(plansLoaded && subscriptionsLoaded)",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "phase-loading" },
            [
              _c("dotlottie-player", {
                staticStyle: { width: "150px", height: "150px" },
                attrs: {
                  src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                  background: "transparent",
                  speed: "1",
                  loop: "",
                  autoplay: "",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }