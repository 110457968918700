var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-profile-unsaved-changes",
        width: "500px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
    },
    [
      _c("div", { staticClass: "modal modal-profile-unsaved-changes" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-profile-unsaved-changes")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content content-section" }, [
          _c("h2", [_vm._v("Profile changes")]),
          _vm._v(" "),
          _c("h3", [
            _vm._v("You have unsaved changes, would you like to save these?"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "save-button" },
            [
              _c(
                "ph-button",
                {
                  attrs: {
                    size: "large",
                    loading: _vm.submitting,
                    color: "primary-outline",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$emit("handleSaveChanges")
                    },
                  },
                },
                [_vm._v("Save")]
              ),
              _vm._v(" "),
              _c(
                "ph-button",
                {
                  attrs: { size: "large", color: "secondary-outline" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$emit("handleCancelChanges")
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }