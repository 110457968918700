var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "250px",
        height: "250px",
        viewBox: "0 0 480 480",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("title", [_vm._v("Information Processed")]),
      _vm._v(" "),
      _c("desc", [_vm._v("Static icon for processed information")]),
      _vm._v(" "),
      _c("ellipse", {
        attrs: { fill: "#3300ff", cx: "240", cy: "240", rx: "240", ry: "240" },
      }),
      _vm._v(" "),
      _c(
        "g",
        { attrs: { id: "checkmark_group", transform: "translate(120, 120)" } },
        [
          _c("circle", {
            attrs: {
              cx: "120",
              cy: "120",
              r: "100",
              fill: "none",
              stroke: "#FFFFFF",
              "stroke-width": "15",
            },
          }),
          _vm._v(" "),
          _c("polyline", {
            attrs: {
              points: "80,120 110,150 170,90",
              fill: "none",
              stroke: "#FFFFFF",
              "stroke-width": "15",
              "stroke-linecap": "round",
              "stroke-linejoin": "round",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }