var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidebar-group" },
    [
      _vm.title
        ? _c("div", { staticClass: "sidebar-group-title" }, [
            _vm._v("\n        " + _vm._s(_vm.title) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "sidebar-group-items" },
        _vm._l(_vm.items, function (item, index) {
          return _c("sidebar-group-item", { key: index, attrs: { item: item } })
        }),
        1
      ),
      _vm._v(" "),
      _vm.link
        ? _c("router-link", { attrs: { to: _vm.link } }, [
            _vm._v("\n        View All "),
            _c("i", { staticClass: "fa fa-angle-double-right" }),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }