var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-report",
        width: "500px",
        height: "auto",
        scrollable: "",
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal modal-report" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-report")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content content-section" }, [
          _c("h2", { staticStyle: { "text-align": "center" } }, [
            _vm._v("Report " + _vm._s(_vm.reportable.type)),
          ]),
          _vm._v(" "),
          _c("h3", { staticStyle: { "text-align": "center" } }, [
            _vm._v(
              "You can report content on Phase if you believe it does not confirm to the content policy."
            ),
          ]),
          _vm._v(" "),
          !_vm.response
            ? _c("div", [
                _c(
                  "textarea",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.message,
                        expression: "message",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    attrs: {
                      name: "message",
                      disabled: _vm.submitting,
                      placeholder:
                        "Please briefly explain why this " +
                        _vm.reportable.type +
                        " breaks the rules",
                    },
                    domProps: { value: _vm.message },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.message = $event.target.value
                      },
                    },
                  },
                  [_vm._v("<b>test</b>")]
                ),
                _vm._v(" "),
                _vm.errors.has("message")
                  ? _c("span", { staticClass: "error-msg" }, [
                      _vm._v(_vm._s(_vm.errors.first("message"))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "save-button" },
                  [
                    _c(
                      "ph-button",
                      {
                        attrs: {
                          size: "large",
                          loading: _vm.submitting,
                          color: "primary-outline",
                        },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.submit.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Submit")]
                    ),
                  ],
                  1
                ),
              ])
            : _c(
                "div",
                [
                  _vm.response.success
                    ? _c("ph-panel", { attrs: { type: "success" } }, [
                        _c("h2", { staticClass: "no-top header" }, [
                          _vm._v("Success!"),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n                        Thank you for your report! We will review it and decide on the appropriate action.\n                    "
                          ),
                        ]),
                      ])
                    : _c("ph-panel", { attrs: { type: "error" } }, [
                        _c("h2", { staticClass: "no-top header" }, [
                          _vm._v("Report failed!"),
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.response.message) +
                              "\n                    "
                          ),
                        ]),
                      ]),
                ],
                1
              ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }