var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "sidebar-group",
        {
          attrs: {
            title: _vm.user.account_type !== "pro" ? _vm.user.name : null,
          },
        },
        [
          _vm.isMe
            ? _c(
                "div",
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: "account_default" } } },
                    [_c("ph-button", [_vm._v("Profile")])],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("sidebar-group", [
        _c("div", { staticClass: "sidebar-group-title" }, [
          _c("span", [
            _c("i", { staticClass: "fa fa-user" }),
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.mutableUser.follower_count
                    ? _vm.mutableUser.follower_count
                    : 0
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("span", [
            _c("i", { staticClass: "fa fa-music" }),
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.mutableUser.releases_count
                    ? _vm.mutableUser.releases_count
                    : 0
                ) +
                "\n      "
            ),
          ]),
        ]),
        _vm._v(" "),
        !_vm.isMe && _vm.isLogin
          ? _c("div", { staticClass: "sidebar-group-subtitle" }, [
              _c(
                "div",
                { staticStyle: { display: "flex", "align-items": "center" } },
                [
                  _c("follow-action", {
                    staticStyle: { margin: "0" },
                    attrs: { user: _vm.mutableUser },
                    on: { update: _vm.followStatusUpdated },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticStyle: { margin: "0 10px", cursor: "pointer" } },
                    [
                      _c("share-button", {
                        attrs: {
                          shareable: _vm.mutableUser,
                          "show-title": true,
                        },
                        on: { share: _vm.shared },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: { path: "/user/messages/" + _vm.user.path },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-envelope" })]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "sidebar-group-content user-bio" }, [
          _vm._v("\n      " + _vm._s(_vm.user.bio) + "\n    "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "profile-social" }, [
          _c(
            "a",
            {
              staticClass: "profile-social-item",
              attrs: { href: _vm.user.social_web },
            },
            [_c("i", { staticClass: "fa fa-globe" })]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "profile-social-item",
              attrs: { href: _vm.user.social_youtube },
            },
            [_c("i", { staticClass: "fab fa-youtube" })]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "profile-social-item",
              attrs: { href: _vm.user.social_twitter },
            },
            [_c("i", { staticClass: "fab fa-twitter" })]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "profile-social-item",
              attrs: { href: _vm.user.social_facebook },
            },
            [_c("i", { staticClass: "fab fa-facebook-f" })]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm.isPro && _vm.isMe
            ? _c("sidebar-group", { attrs: { title: "Merchandise" } }, [
                _c(
                  "div",
                  { staticClass: "sidebar-group-content" },
                  [
                    _c(
                      "ph-button",
                      {
                        attrs: { size: "small" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showMerchCreateModal.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("\n          Add merchandise\n        ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.merchs, function (item, index) {
                      return _c("sidebar-group-item", {
                        key: index,
                        attrs: { item: item },
                      })
                    }),
                    _vm._v(" "),
                    _vm.merchs.length > 0
                      ? _c(
                          "router-link",
                          { attrs: { to: { name: "profile_merch" } } },
                          [_vm._v(" >> View All")]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isPro && _vm.isMe
            ? _c("sidebar-group", { attrs: { title: "Events" } }, [
                _c(
                  "div",
                  { staticClass: "sidebar-group-content" },
                  [
                    _c(
                      "ph-button",
                      {
                        attrs: { size: "small" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showEventCreateModal.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("\n          Add events\n        ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.events, function (item, index) {
                      return _c("sidebar-group-item", {
                        key: index,
                        attrs: { item: item },
                      })
                    }),
                    _vm._v(" "),
                    _vm.events.length > 0
                      ? _c(
                          "router-link",
                          { attrs: { to: { name: "profile_events" } } },
                          [_vm._v(" >> View All")]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }