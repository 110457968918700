import { render, staticRenderFns } from "./person-options.vue?vue&type=template&id=c2092708&scoped=true&"
import script from "./person-options.vue?vue&type=script&lang=js&"
export * from "./person-options.vue?vue&type=script&lang=js&"
import style0 from "./person-options.vue?vue&type=style&index=0&id=c2092708&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2092708",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.phase.uk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c2092708')) {
      api.createRecord('c2092708', component.options)
    } else {
      api.reload('c2092708', component.options)
    }
    module.hot.accept("./person-options.vue?vue&type=template&id=c2092708&scoped=true&", function () {
      api.rerender('c2092708', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/vue/components/modals/auth/register-steps/person-options.vue"
export default component.exports