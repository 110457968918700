var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "ph-panel",
        [
          _c("h2", [_vm._v("All Releases")]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "ph-button",
            {
              attrs: { color: _vm.graphSelected("releases", "revenue") },
              nativeOn: {
                click: function ($event) {
                  return _vm.selectGraph("releases", "revenue")
                },
              },
            },
            [_vm._v("\n            Revenue\n        ")]
          ),
          _vm._v(" "),
          _c(
            "ph-button",
            {
              attrs: { color: _vm.graphSelected("releases", "volume") },
              nativeOn: {
                click: function ($event) {
                  return _vm.selectGraph("releases", "volume")
                },
              },
            },
            [_vm._v("\n            Volume\n        ")]
          ),
          _vm._v(" "),
          _vm.stats.releases.selected === "revenue"
            ? _c(
                "div",
                [
                  _vm.stats.releases.revenue
                    ? _c("chart", {
                        attrs: {
                          name: "Sales Revenue (All Releases)",
                          "data-type": "currency",
                          data: _vm.stats.releases.revenue,
                          "x-label": "Month",
                          "y-label": "Total Sales",
                        },
                      })
                    : _c("div", { staticClass: "loading" }, [
                        _c(
                          "div",
                          { staticClass: "phase-loading" },
                          [
                            _c("dotlottie-player", {
                              staticStyle: { width: "150px", height: "150px" },
                              attrs: {
                                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                                background: "transparent",
                                speed: "1",
                                loop: "",
                                autoplay: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.stats.releases.selected === "volume"
            ? _c(
                "div",
                [
                  _vm.stats.releases.volume
                    ? _c("chart", {
                        attrs: {
                          name: "Sales Volume (All Releases)",
                          "data-type": "integer",
                          data: _vm.stats.releases.volume,
                          "x-label": "Month",
                          "y-label": "Total Sales Volume",
                        },
                      })
                    : _c("div", { staticClass: "loading" }, [
                        _c(
                          "div",
                          { staticClass: "phase-loading" },
                          [
                            _c("dotlottie-player", {
                              staticStyle: { width: "150px", height: "150px" },
                              attrs: {
                                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                                background: "transparent",
                                speed: "1",
                                loop: "",
                                autoplay: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "ph-panel",
        [
          _c("h2", [_vm._v("All Tracks")]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "ph-button",
            {
              attrs: { color: _vm.graphSelected("tracks", "revenue") },
              nativeOn: {
                click: function ($event) {
                  return _vm.selectGraph("tracks", "revenue")
                },
              },
            },
            [_vm._v("\n            Revenue\n        ")]
          ),
          _vm._v(" "),
          _c(
            "ph-button",
            {
              attrs: { color: _vm.graphSelected("tracks", "volume") },
              nativeOn: {
                click: function ($event) {
                  return _vm.selectGraph("tracks", "volume")
                },
              },
            },
            [_vm._v("\n            Volume\n        ")]
          ),
          _vm._v(" "),
          _vm.stats.tracks.selected === "revenue"
            ? _c(
                "div",
                [
                  _vm.stats.tracks.revenue
                    ? _c("chart", {
                        attrs: {
                          name: "Sales Revenue (All Tracks)",
                          "data-type": "currency",
                          data: _vm.stats.tracks.revenue,
                          "x-label": "Month",
                          "y-label": "Total Sales",
                        },
                      })
                    : _c("div", { staticClass: "loading" }, [
                        _c(
                          "div",
                          { staticClass: "phase-loading" },
                          [
                            _c("dotlottie-player", {
                              staticStyle: { width: "150px", height: "150px" },
                              attrs: {
                                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                                background: "transparent",
                                speed: "1",
                                loop: "",
                                autoplay: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.stats.tracks.selected === "volume"
            ? _c(
                "div",
                [
                  _vm.stats.tracks.volume
                    ? _c("chart", {
                        attrs: {
                          name: "Sales Volume (All Tracks)",
                          "data-type": "integer",
                          data: _vm.stats.tracks.volume,
                          "x-label": "Month",
                          "y-label": "Total Sales Volume",
                        },
                      })
                    : _c("div", { staticClass: "loading" }, [
                        _c(
                          "div",
                          { staticClass: "phase-loading" },
                          [
                            _c("dotlottie-player", {
                              staticStyle: { width: "150px", height: "150px" },
                              attrs: {
                                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                                background: "transparent",
                                speed: "1",
                                loop: "",
                                autoplay: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }