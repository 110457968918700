<template>
    <modal name="modal-profile-unsaved-changes" width="500px" height="auto" scrollable adaptive>
        <div class="modal modal-profile-unsaved-changes">
            <div class="modal-header">
                <close-icon class="float-right" @click.native="$modal.hide('modal-profile-unsaved-changes')"></close-icon>
            </div>
            <div class="modal-content content-section">
                <h2>Profile changes</h2>
                <h3>You have unsaved changes, would you like to save these?</h3>

                <div class="save-button">
                    <ph-button size="large" @click.native="$emit('handleSaveChanges')" :loading="submitting" color="primary-outline">Save</ph-button>
                    <ph-button size="large" @click.native="$emit('handleCancelChanges')" color="secondary-outline">Cancel</ph-button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapState } from 'vuex';
import store from 'store';
import CloseIcon from 'global/close-icon';
export default {
    data() {
        return {
            response: null,
        }
    },
    props: ['submitting'],
    created: function () {

    },
    mounted: function () {

    },
    computed: {
        ...mapState([
            'app',
        ])
    },
    methods: {
    },
    components: {
        CloseIcon,
    }
}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

h2 {
    margin-bottom: 0;
}

p {
    margin: 1em 0;
}

textarea {
    box-sizing: border-box;
    border: 1px solid $color-grey2;
    padding: 5px;
    width: 100%;
    height: 50px;
}

.save-button {
    margin-top: 1em;
    text-align: center;
}

.error-msg {
    font-size: 70%;
    color: red;
}
</style>