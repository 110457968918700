var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loading
        ? _c("spinner", {
            staticStyle: { margin: "3em auto" },
            attrs: { "animation-duration": 1000, size: 60, color: "black" },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.releases.length > 0
        ? _c(
            "div",
            _vm._l(_vm.releases, function (release) {
              return _c("item", { key: release.id, attrs: { item: release } })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.releases.length && !_vm.loading
        ? _c("div", [_c("p", [_vm._v("No releases yet")])])
        : _vm._e(),
      _vm._v(" "),
      _vm.releases.length && !_vm.loading && _vm.moreToFetch
        ? _c(
            "div",
            { staticClass: "centered-text loadMoreButton" },
            [
              _c(
                "ph-button",
                {
                  attrs: { size: "large", loading: _vm.fetchingMore },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.getNextPage.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n            Show Me More\n        ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }