var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.$store.state.player.status.set,
          expression: "$store.state.player.status.set",
        },
      ],
      ref: "player",
      staticClass: "player",
    },
    [
      _c(
        "audio",
        {
          ref: "audio",
          on: {
            loadedmetadata: _vm.loaded,
            timeupdate: _vm.timeUpdate,
            ended: _vm.ended,
          },
        },
        [
          _vm.$store.state.player.status.set
            ? _c("source", { attrs: { src: _vm.trackUrl, type: "audio/mp3" } })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      !_vm.expanded
        ? _c("div", { staticClass: "player" }, [
            _c("div", { staticClass: "controls" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "control", on: { click: _vm.expandPlayer } },
                  [
                    _c("i", {
                      staticClass: "fa fa-expand-arrows-alt fa-fw",
                      attrs: { "data-fa-transform": "shrink-3" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticClass: "control volume-wrap" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.$store.state.player.status.muted,
                          expression: "!$store.state.player.status.muted",
                        },
                      ],
                      staticClass: "control",
                      on: { click: _vm.toggleMute },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-volume-up fa-fw",
                        attrs: { "data-fa-transform": "shrink-3" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$store.state.player.status.muted,
                          expression: "$store.state.player.status.muted",
                        },
                      ],
                      staticClass: "control",
                      on: { click: _vm.toggleMute },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-volume-off fa-fw",
                        attrs: { "data-fa-transform": "shrink-3" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "volume-bar-wrap",
                      on: { mousedown: _vm.onBarMouseDown },
                    },
                    [
                      _c("div", { ref: "bar", staticClass: "volume-bar" }, [
                        _c("div", {
                          staticClass: "volume",
                          style: {
                            width: _vm.$store.state.player.status.muted
                              ? 0
                              : `${Math.trunc(_vm.volume * 100)}%`,
                          },
                        }),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "control",
                    class: {
                      active: _vm.isInCart(_vm.$store.state.player.track),
                    },
                    on: { click: _vm.toggleCart },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-shopping-cart",
                      attrs: { "data-fa-transform": "shrink-3" },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "center" }, [
                _c(
                  "span",
                  { staticClass: "control", on: { click: _vm.skipBack } },
                  [_c("i", { staticClass: "fa fa-step-backward" })]
                ),
                _vm._v("\n                     \n                    "),
                _c(
                  "span",
                  {
                    staticClass: "fa-layers fa-fw",
                    on: { click: _vm.togglePlayback },
                  },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.$store.state.player.status.playing,
                            expression: "!$store.state.player.status.playing",
                          },
                        ],
                      },
                      [
                        _c("i", {
                          staticClass: "fa fw-fw fa-play",
                          staticStyle: { color: "white" },
                          attrs: { "data-fa-transform": "shrink-3" },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$store.state.player.status.playing,
                            expression: "$store.state.player.status.playing",
                          },
                        ],
                      },
                      [
                        _c("i", {
                          staticClass: "fa fw-fw fa-pause",
                          staticStyle: { color: "white" },
                          attrs: { "data-fa-transform": "shrink-3" },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._v("\n                     \n"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right" }, [
                _c(
                  "span",
                  {
                    staticClass: "controls-repeat",
                    class: { active: _vm.$store.state.player.repeat },
                    on: { click: _vm.toggleRepeat },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-redo",
                      attrs: { "data-fa-transform": "shrink-3" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "controls-shuffle",
                    class: { active: _vm.$store.state.player.shuffle },
                    on: {
                      click: function ($event) {
                        _vm.shuffle = !_vm.shuffle
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-random",
                      attrs: { "data-fa-transform": "shrink-3" },
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.track.release && _vm.track.release.uploader
              ? _c("div", { staticClass: "details" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.track.release.uploader.name) +
                      " - " +
                      _vm._s(_vm.track.name) +
                      "\n            "
                  ),
                ])
              : _c("div", { staticClass: "details" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.track.name) +
                      "\n            "
                  ),
                ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "progress",
                on: {
                  click: _vm.progressClick,
                  mousedown: _vm.startProgressDrag,
                  mousemove: _vm.doProgressDrag,
                  mouseup: _vm.endProgressDrag,
                },
              },
              [
                _c("div", {
                  staticClass: "bar",
                  style:
                    "width:" +
                    _vm.$store.state.player.status.position * 100 +
                    "%;",
                }),
              ]
            ),
          ])
        : _c("div", { staticClass: "expanded" }, [
            _c("div", { staticClass: "controls" }, [
              _c("div", { staticClass: "left" }, [
                _c(
                  "span",
                  { staticClass: "control", on: { click: _vm.expandPlayer } },
                  [
                    _c("i", {
                      staticClass: "fa fa-expand-arrows-alt fa-fw",
                      attrs: { "data-fa-transform": "shrink-3" },
                    }),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "control",
                      class: {
                        active: _vm.isInCart(_vm.$store.state.player.track),
                      },
                      on: { click: _vm.toggleCart },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-shopping-cart",
                        attrs: { "data-fa-transform": "shrink-3" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("like-button", {
                    attrs: { likeable: _vm.track },
                    on: { like: _vm.liked, unlike: _vm.unliked },
                  }),
                  _vm._v(" "),
                  _c("share-button", { attrs: { shareable: _vm.track } }),
                  _vm._v(" "),
                  _c("report-button", { attrs: { reportable: _vm.track } }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.track.release && _vm.track.release.uploader
              ? _c("div", { staticClass: "details" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.track.release.uploader.name) +
                      " - " +
                      _vm._s(_vm.track.name) +
                      "\n            "
                  ),
                ])
              : _c("div", { staticClass: "details" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.track.name) +
                      "\n            "
                  ),
                ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("avatar", {
                  attrs: {
                    src: _vm.track.release.image.files.medium.url,
                    size: 250,
                    tile: true,
                    center: false,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "controls-bottom" }, [
              _c("div", { staticClass: "controls" }, [
                _c("div", { staticClass: "left volume-wrap" }, [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.$store.state.player.status.muted,
                          expression: "!$store.state.player.status.muted",
                        },
                      ],
                      staticClass: "control",
                      on: { click: _vm.toggleMute },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-volume-up fa-fw",
                        attrs: { "data-fa-transform": "shrink-3" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$store.state.player.status.muted,
                          expression: "$store.state.player.status.muted",
                        },
                      ],
                      staticClass: "control",
                      on: { click: _vm.toggleMute },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-volume-off fa-fw",
                        attrs: { "data-fa-transform": "shrink-3" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "volume-bar-wrap",
                      on: { mousedown: _vm.onBarMouseDown },
                    },
                    [
                      _c("div", { ref: "bar", staticClass: "volume-bar" }, [
                        _c("div", {
                          staticClass: "volume",
                          style: {
                            width: _vm.$store.state.player.status.muted
                              ? 0
                              : `${Math.trunc(_vm.volume * 100)}%`,
                          },
                        }),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "center" }, [
                  _c(
                    "span",
                    { staticClass: "control", on: { click: _vm.skipBack } },
                    [_c("i", { staticClass: "fa fa-step-backward" })]
                  ),
                  _vm._v(
                    "\n                         \n                        "
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "fa-layers fa-fw",
                      on: { click: _vm.togglePlayback },
                    },
                    [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.$store.state.player.status.playing,
                              expression: "!$store.state.player.status.playing",
                            },
                          ],
                        },
                        [
                          _c("i", {
                            staticClass: "fa fw-fw fa-play",
                            staticStyle: { color: "white" },
                            attrs: { "data-fa-transform": "shrink-3" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.$store.state.player.status.playing,
                              expression: "$store.state.player.status.playing",
                            },
                          ],
                        },
                        [
                          _c("i", {
                            staticClass: "fa fw-fw fa-pause",
                            staticStyle: { color: "white" },
                            attrs: { "data-fa-transform": "shrink-3" },
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v("\n                         \n"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "span",
                    {
                      staticClass: "controls-repeat",
                      class: { active: _vm.$store.state.player.repeat },
                      on: { click: _vm.toggleRepeat },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-redo",
                        attrs: { "data-fa-transform": "shrink-3" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "controls-shuffle",
                      class: { active: _vm.$store.state.player.shuffle },
                      on: {
                        click: function ($event) {
                          _vm.shuffle = !_vm.shuffle
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-random",
                        attrs: { "data-fa-transform": "shrink-3" },
                      }),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "progress",
                  on: {
                    click: _vm.progressClick,
                    mousedown: _vm.startProgressDrag,
                    mousemove: _vm.doProgressDrag,
                    mouseup: _vm.endProgressDrag,
                  },
                },
                [
                  _c("div", {
                    staticClass: "bar",
                    style:
                      "width:" +
                      _vm.$store.state.player.status.position * 100 +
                      "%;",
                  }),
                ]
              ),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "control" }, [
      _c("i", {
        staticClass: "fa fa-fw fa-circle",
        staticStyle: { color: "blue" },
        attrs: { "data-fa-transform": "grow-13" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "control" }, [
      _c("i", {
        staticClass: "fa fa-fw fa-circle",
        staticStyle: { color: "blue" },
        attrs: { "data-fa-transform": "grow-13" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }