var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-item" }, [
    _c(
      "div",
      { staticClass: "p-item-image" },
      [
        _c(
          "router-link",
          { attrs: { to: _vm.getRouterObject(_vm.news) } },
          [
            _vm.news.image
              ? _c("avatar", {
                  attrs: { size: 130, src: _vm.news.image.files.original.url },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "p-item-main" }, [
      _c("div", { staticClass: "p-item-detail" }, [
        _c(
          "div",
          { staticClass: "p-item-title" },
          [
            _c(
              "router-link",
              { attrs: { to: _vm.getRouterObject(_vm.news) } },
              [_c("span", [_vm._v(_vm._s(_vm.news.title))])]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "release-description" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.news.content.trunc(110)) + "\n        "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p-item-meta" },
        [
          _c("actions", {
            attrs: { actionable: _vm.news, actiontype: "news" },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "p-item-time" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.moment(_vm.news.published_at).fromNow()) +
                "\n            "
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }