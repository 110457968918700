var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-video-player",
        width: "500px",
        height: "auto",
        scrollable: "",
      },
      on: { beforeOpen: _vm.beforeOpen, opened: _vm.opened },
    },
    [
      _c("div", { staticClass: "modal modal-video-player" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-video-player")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          _c("h2", [_vm._v(_vm._s(_vm.video.title))]),
          _vm._v(" "),
          _c(
            "video",
            {
              ref: "video",
              attrs: { controls: "", width: "450", height: "350", id: "video" },
            },
            [
              _c("source", {
                attrs: {
                  src: _vm.video.asset.files.hls_playlist,
                  type: "application/x-mpegURL",
                },
              }),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }