var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("canvas", {
    ref: "confettiCanvas",
    attrs: { id: "confettiCanvas" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }