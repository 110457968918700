var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "news-index-article" },
    [
      _c("router-link", { attrs: { to: "/article/" + _vm.data.path } }, [
        _c("div", {
          staticClass: "news-index-article-image",
          style:
            "background-image: url(" + _vm.data.image.files.original.url + ")",
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "news-index-article-info" },
        [
          _c("router-link", { attrs: { to: "/article/" + _vm.data.path } }, [
            _c("h2", [_vm._v(_vm._s(_vm.data.title))]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "index-article-info-meta" },
            [
              _vm._v("\n            Article by "),
              _c(
                "router-link",
                {
                  staticClass: "blue",
                  attrs: { to: "/user/" + _vm.data.user.path },
                },
                [_vm._v(_vm._s(_vm.data.user.name))]
              ),
              _vm._v(" in\n\n            "),
              _vm._l(_vm.data.categories, function (category, index) {
                return _c(
                  "span",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "blue",
                        attrs: { to: "/news" + category.path },
                      },
                      [_vm._v(_vm._s(category.title))]
                    ),
                    _vm.data.categories[index + 1]
                      ? _c("span", [_vm._v(", ")])
                      : _vm._e(),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c("br"),
              _vm._v(
                "\n\n            " +
                  _vm._s(
                    _vm.moment(_vm.data.published_at).format("MMMM D, YYYY")
                  ) +
                  "\n        "
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c("actions", {
            attrs: { actionable: _vm.data },
            on: {
              like: _vm.commitlike,
              unlike: _vm.commitunlike,
              share: _vm.commitShare,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }