var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "250px",
        height: "250px",
        viewBox: "0 0 480 480",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Email verificata")]),
      _vm._v(" "),
      _c("desc", [_vm._v("una lettera con un marchio di approvazione")]),
      _vm._v(" "),
      _c("defs", [
        _c(
          "filter",
          {
            attrs: {
              x: "-50%",
              y: "-50%",
              width: "200%",
              height: "200%",
              filterUnits: "objectBoundingBox",
              id: "material_shadow",
            },
          },
          [
            _c("feOffset", {
              attrs: {
                dx: "0",
                dy: "8",
                in: "SourceAlpha",
                result: "shadowOffsetOuter1",
              },
            }),
            _vm._v(" "),
            _c("feGaussianBlur", {
              attrs: {
                stdDeviation: "4",
                in: "shadowOffsetOuter1",
                result: "shadowBlurOuter1",
              },
            }),
            _vm._v(" "),
            _c("feColorMatrix", {
              attrs: {
                values: "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.24 0",
                type: "matrix",
                in: "shadowBlurOuter1",
                result: "shadowMatrixOuter1",
              },
            }),
            _vm._v(" "),
            _c("feOffset", {
              attrs: {
                dx: "0",
                dy: "0",
                in: "SourceAlpha",
                result: "shadowOffsetOuter2",
              },
            }),
            _vm._v(" "),
            _c("feGaussianBlur", {
              attrs: {
                stdDeviation: "4",
                in: "shadowOffsetOuter2",
                result: "shadowBlurOuter2",
              },
            }),
            _vm._v(" "),
            _c("feColorMatrix", {
              attrs: {
                values: "0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.12 0",
                type: "matrix",
                in: "shadowBlurOuter2",
                result: "shadowMatrixOuter2",
              },
            }),
            _vm._v(" "),
            _c(
              "feMerge",
              [
                _c("feMergeNode", { attrs: { in: "shadowMatrixOuter1" } }),
                _vm._v(" "),
                _c("feMergeNode", { attrs: { in: "shadowMatrixOuter2" } }),
                _vm._v(" "),
                _c("feMergeNode", { attrs: { in: "SourceGraphic" } }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "filter",
          {
            attrs: {
              id: "mail_body_shadow",
              x: "-50%",
              y: "-50%",
              width: "200%",
              height: "200%",
              filterUnits: "objectBoundingBox",
            },
          },
          [
            _c("feOffset", {
              attrs: {
                dx: "0",
                dy: "-4",
                in: "SourceAlpha",
                result: "shadowOffsetInner1",
              },
            }),
            _vm._v(" "),
            _c("feComposite", {
              attrs: {
                in: "shadowOffsetInner1",
                in2: "SourceAlpha",
                operator: "arithmetic",
                k2: "-1",
                k3: "1",
                result: "shadowInnerInner1",
              },
            }),
            _vm._v(" "),
            _c("feColorMatrix", {
              attrs: {
                values:
                  "0 0 0 0 0.6352941176   0 0 0 0 0.7647058824   0 0 0 0 0.8549019608  0 0 0 1 0",
                type: "matrix",
                in: "shadowInnerInner1",
                result: "shadowInnerColored",
              },
            }),
            _vm._v(" "),
            _c("feBlend", {
              attrs: {
                in2: "SourceGraphic",
                in1: "shadowInnerColored",
                mode: "normal",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: "mail_top_path",
            d: "M0.157001562,63.598126 L180,176 L360,63.5 L360,36 C360,16.11 343.89,0 324,0 L36,0 C16.11,0 0.18,16.11 0.18,36 L0.157001562,63.598126 Z",
          },
        }),
        _vm._v(" "),
        _c(
          "filter",
          {
            attrs: {
              id: "mail_top_shadow",
              x: "-50%",
              y: "-50%",
              width: "200%",
              height: "200%",
              filterUnits: "objectBoundingBox",
            },
          },
          [
            _c("feOffset", {
              attrs: {
                dx: "0",
                dy: "4",
                in: "SourceAlpha",
                result: "shadowOffsetInner1",
              },
            }),
            _vm._v(" "),
            _c("feComposite", {
              attrs: {
                in: "shadowOffsetInner1",
                in2: "SourceAlpha",
                operator: "arithmetic",
                k2: "-1",
                k3: "1",
                result: "shadowInnerInner1",
              },
            }),
            _vm._v(" "),
            _c("feColorMatrix", {
              attrs: {
                values:
                  "0 0 0 0 0.960784314   0 0 0 0 1   0 0 0 0 1  0 0 0 1 0",
                type: "matrix",
                in: "shadowInnerInner1",
                result: "shadowInnerTop",
              },
            }),
            _vm._v(" "),
            _c(
              "feMerge",
              [
                _c("feMergeNode", { attrs: { in: "SourceGraphic" } }),
                _vm._v(" "),
                _c("feMergeNode", { attrs: { in: "shadowInnerTop" } }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "ellipse",
        {
          attrs: {
            id: "bg_circle",
            fill: "#3300ff",
            cx: "240",
            cy: "240",
            rx: "240",
            ry: "240",
          },
        },
        [
          _c("animate", {
            attrs: {
              id: "bg_circle_loop_rx",
              attributeName: "rx",
              from: "240",
              to: "240",
              begin: "0s",
              dur: "2s",
              fill: "freeze",
              repeatCount: "indefinite",
              calcMode: "spline",
              values: "240; 230; 240",
              keyTimes: "0; 0.5; 1",
              keySplines: "0.5 0.0 0.5 1; 0.5 0.0 0.5 1",
            },
          }),
          _vm._v(" "),
          _c("animate", {
            attrs: {
              id: "bg_circle_loop_ry",
              attributeName: "ry",
              from: "230",
              to: "230",
              begin: "0s",
              dur: "2s",
              fill: "freeze",
              repeatCount: "indefinite",
              calcMode: "spline",
              values: "230; 240; 230",
              keyTimes: "0; 0.5; 1",
              keySplines: "0.5 0.0 0.5 1; 0.5 0.0 0.5 1",
            },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "mail_recenter_group",
            transform: "translate(240, 240)",
          },
        },
        [
          _c(
            "g",
            { attrs: { id: "mail_rotate_group" } },
            [
              _c(
                "g",
                {
                  attrs: { id: "mail_rescale_group", transform: "scale(0, 0)" },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "mail_pivot_group",
                        transform: "translate(-180, -144)",
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            id: "mail_body_shadow_group",
                            filter: "url(#material_shadow)",
                          },
                        },
                        [
                          _c("rect", {
                            attrs: {
                              id: "mail_body",
                              x: "0",
                              y: "0",
                              width: "360",
                              height: "288",
                              rx: "36",
                              fill: "#E7F0F5",
                              filter: "url(#mail_body_shadow)",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          id: "long_shadow",
                          d: "M346.53273,280.082956 L180,176 L359.525271,30.1357171 C359.837597,32.0440524 360,34.0029734 360,36 L360,252 C360,263.359577 354.745271,273.486202 346.53273,280.082956 Z",
                          "fill-opacity": "0.05",
                          fill: "#004B70",
                        },
                      }),
                      _vm._v(" "),
                      _c("use", {
                        attrs: {
                          id: "mail-top",
                          fill: "#EBF6FB",
                          "fill-rule": "evenodd",
                          "xlink:href": "#mail_top_path",
                          filter: "url(#mail_top_shadow)",
                        },
                      }),
                      _vm._v(" "),
                      _c("polygon", {
                        attrs: {
                          id: "mail_top_border_fat",
                          fill: "#BFDBE5",
                          points:
                            "360 63.5 180 176 0.157001562 63.598126 0.176645458 40.0254506 180 152.415047 360 39.9150472 360 63.5",
                        },
                      }),
                      _vm._v(" "),
                      _c("polygon", {
                        attrs: {
                          id: "mail_top_border_thin",
                          fill: "#92C8D6",
                          points:
                            "360 63.5 180 176 0.157001562 63.598126 0.160930341 58.8835909 180 171.283009 360 58.7830094 360 63.5",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "text",
                        {
                          attrs: {
                            x: "270",
                            y: "240",
                            "font-size": "120",
                            "font-weight": "bold",
                            fill: "#FFA500",
                          },
                        },
                        [_vm._v("!")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("animateTransform", {
                    attrs: {
                      id: "mail_appear_scale",
                      attributeName: "transform",
                      type: "scale",
                      additive: "replace",
                      from: "0 0",
                      to: "1 1",
                      begin: "1.6s",
                      dur: "0.675s",
                      fill: "freeze",
                      repeatCount: "1",
                      calcMode: "spline",
                      values: "0 0; 1.1 1.1; 0.95 0.95; 1 1",
                      keyTimes: "0; 0.33; 0.66; 1",
                      keySplines: "0.0 0.0 0.5 1; 0.5 0.0 0.5 1; 0.5 0.0 0.2 1",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("animateTransform", {
                attrs: {
                  id: "mail_rotation_loop",
                  attributeName: "transform",
                  type: "rotate",
                  from: "0 0 0",
                  to: "360 0 0",
                  dur: "1.35s",
                  repeatCount: "indefinite",
                  calcMode: "spline",
                  values: "2.5 0 0;-2.5 0 0; 2.5 0 0",
                  keyTimes: "0; 0.5; 1",
                  keySplines:
                    "\n            0.5 0.0 0.5 1;\n            0.5 0.0 0.5 1",
                },
              }),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }