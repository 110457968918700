var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select-wrapper" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selected,
            expression: "selected",
          },
        ],
        attrs: { name: _vm.name },
        on: {
          change: [
            function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selected = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
            _vm.input,
          ],
        },
      },
      [
        _c("option", { attrs: { disabled: "", value: "" } }, [
          _vm._v(_vm._s(_vm.title)),
        ]),
        _vm._v(" "),
        _vm._l(_vm.options, function (v, k) {
          return _c(
            "option",
            { domProps: { value: k, selected: k === _vm.value } },
            [_vm._v("\n            " + _vm._s(v) + "\n        ")]
          )
        }),
      ],
      2
    ),
    _vm._v(" "),
    _vm._m(0),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "select-controls" }, [
      _c("span", [_vm._v("|")]),
      _vm._v(" "),
      _c("i", { staticClass: "fa fa-caret-down" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }