var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.merch.body || _vm.merch.attachment
    ? _c("post", { attrs: { post: _vm.merch, id: _vm.id } })
    : _vm.merch.title
    ? _c("div", { staticClass: "p-item" }, [
        _c(
          "div",
          { staticClass: "p-item-image" },
          [
            _vm.merch.image
              ? _c("avatar", {
                  attrs: {
                    size: 130,
                    src: _vm.merch.image.files.medium.url,
                    tile: true,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "p-item-main" }, [
          _c("div", { staticClass: "p-item-detail" }, [
            _c("div", { staticClass: "p-item-title" }, [
              _c("span", [_vm._v(_vm._s(_vm.merch.title))]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "event-date-location" }, [
            _c("div", { staticClass: "merch-description" }, [
              _c("p", [_vm._v(_vm._s(_vm.merch.description))]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "p-item-meta" },
              [
                _c("actions", {
                  attrs: {
                    actionable: _vm.merch,
                    id: _vm.merch.id,
                    actiontype: "merch",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "p-item-time" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.moment(_vm.merch.created_at).fromNow()) +
                      "\n                "
                  ),
                ]),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }