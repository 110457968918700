var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("add-text", {
        attrs: { type: "post", addTextAble: _vm.user },
        on: { success: _vm.addStatusUpdate },
      }),
      _vm._v(" "),
      _vm._l(_vm.activity, function (action, index) {
        return _c("feed-action", {
          key: index,
          attrs: { index: index, action: action, "this-user": _vm.mutableUser },
          on: { "delete-action": _vm.fetchActivity },
        })
      }),
      _vm._v(" "),
      _c("spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loadingActivity,
            expression: "loadingActivity",
          },
        ],
        staticStyle: { margin: "3em auto" },
        attrs: { "animation-duration": 1000, size: 60, color: "black" },
      }),
      _vm._v(" "),
      _vm.nextStart
        ? _c(
            "div",
            {
              staticClass: "centered-text",
              staticStyle: { margin: "0 0 4em 0" },
            },
            [
              _c(
                "ph-button",
                {
                  attrs: {
                    ize: "medium",
                    color: "blue2",
                    loading: _vm.loadingNextPage,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.loadNextPage.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Load More")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }