var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", { staticClass: "atc-button", on: { click: _vm.toggle } }, [
    _vm._v("\n    £" + _vm._s((_vm.product.price / 100).toFixed(2)) + "\n    "),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
      },
      [_c("i", { staticClass: "fas fa-spinner fa-spin" })]
    ),
    _vm._v(" "),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isInCart(_vm.product) && !_vm.loading,
            expression: "!isInCart(product) && !loading",
          },
        ],
      },
      [_c("i", { staticClass: "fas fa-cart-plus" })]
    ),
    _vm._v(" "),
    _c(
      "span",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isInCart(_vm.product) && !_vm.loading,
            expression: "isInCart(product) && !loading",
          },
        ],
      },
      [_c("i", { staticClass: "fas fa-check" })]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }