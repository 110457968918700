var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-faq",
        width: "700px",
        height: "auto",
        scrollable: "",
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal modal-faq" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-faq")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.faq
          ? _c("div", { staticClass: "modal-content content-section" }, [
              _c("h2", [_vm._v(_vm._s(_vm.faq.question))]),
              _vm._v(" "),
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.faq.answer.replace(/(?:\r\n|\r|\n)/g, "<br>")
                  ),
                },
              }),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }