<template>
    <div>
        <avatar-track :fullWidth="true" :tile="true" :src="track.release.image.files.medium.url" :track="track"
            :recent="track.is_recent" />
        <router-link class="track-tile" :to="getRouterObject(track)">
            <!-- <div v-if="track.release" class="track-tile-image">
            <avatar :tile="true" :size="size" :src="track.release.image.files.medium.url" :recent="track.is_recent">
            </avatar>
        </div> -->
            <div class="track-tile-text">
                {{ track.name }}
            </div>
            <small class="track-artist-name">{{ track.artist.name }}</small>
        </router-link>
    </div>
</template>

<script>
import Avatar from 'global/avatar';
import AvatarTrack from "../../global/avatar-track";

export default {
    props: {
        size: Number,
        track: Object,
    },
    data() {
        return {

        }
    },
    created: function () {

    },
    mounted() {
        console.log(this.track)
    },
    components: {
        Avatar,
        AvatarTrack
    }
}
</script>

<style lang="scss" scoped>
.track-tile {
    text-decoration: none;
}

.track-tile-text {
    text-align: left;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 5px;
    font-weight: bold;
}

.track-artist-name {
    font-size: 11px;
}
</style>