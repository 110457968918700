var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "close-icon" }, [
    _c("i", { staticClass: "fa fa-times", class: "fa-" + _vm.zoom + "x" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }