var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "30",
        height: "30",
        viewBox: "0 0 32 32",
        fill: "none",
      },
    },
    [
      _c("circle", {
        attrs: { cx: "16", cy: "16", r: "15.5", stroke: "#3523FB" },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }