var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.featuredItems
        ? _c("div", [
            _c("h1", [_vm._v("Featured")]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "home-featured" },
              [
                _c("split-grid", {
                  attrs: { data: _vm.featuredItems, type: "Featured" },
                }),
              ],
              1
            ),
          ])
        : _c("spinner", {
            staticStyle: { margin: "3em auto" },
            attrs: { "animation-duration": 1000, size: 80, color: "black" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }