var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "nav_wrapper" } }, [
    _c(
      "header",
      {
        staticClass: "top-bar",
        class: {
          searching: _vm.search.visible,
          "top-bar-border-bottom": _vm.app.user.loggedin,
        },
      },
      [
        _c(
          "div",
          { staticClass: "logo-bars-block" },
          [
            _c(
              "div",
              {
                staticClass: "top mobile-toggle",
                class: {
                  "bars-primary":
                    _vm.app.user.loggedin || _vm.$route.path !== "/",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("sidebarToggle")
                  },
                },
              },
              [_c("i", { staticClass: "fa-solid fa-bars" })]
            ),
            _vm._v(" "),
            _vm.app.user.loggedin
              ? _c(
                  "router-link",
                  { staticClass: "logo", attrs: { to: "/" } },
                  [_c("logo-lines", { staticStyle: { width: "100px" } })],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.app.user.loggedin && !_vm.search.visible
          ? _c(
              "router-link",
              { staticClass: "logo", attrs: { to: "/" } },
              [
                _c("logo", {
                  staticStyle: { width: "250px" },
                  attrs: { color: _vm.logoColor },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "tagline" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.app.settings["logo_title"]) +
                      "\n            "
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.app.user.loggedin || (!_vm.app.user.loggedin && _vm.search.visible)
          ? _c("div", { staticClass: "main-search" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.toggleSearch.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.searchTerm,
                        expression: "searchTerm",
                      },
                    ],
                    staticClass: "search-input",
                    attrs: { type: "text", placeholder: "SEARCH" },
                    domProps: { value: _vm.searchTerm },
                    on: {
                      keyup: function ($event) {
                        $event.preventDefault()
                        return _vm.onEnter($event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.searchTerm = $event.target.value
                      },
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "search-toggle",
                  class: _vm.searchClass,
                  on: { click: _vm.toggleSearch },
                },
                [_c("i", { staticClass: "fa fa-search" })]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.search.visible,
                expression: "search.visible",
              },
            ],
            staticClass: "search-close",
            on: { click: _vm.closeSearch },
          },
          [_c("i", { staticClass: "fa fa-times" })]
        ),
        _vm._v(" "),
        !_vm.search.visible
          ? _c(
              "div",
              {
                staticClass: "user-actions",
                class: { "right-padded": !_vm.app.user.loggedin },
              },
              [
                _vm.app.user.loggedin
                  ? _c(
                      "div",
                      { staticClass: "user-loggedIn-actions" },
                      [
                        _vm.$can("create releases")
                          ? _c(
                              "a",
                              {
                                staticClass: "user-bar-item fa-layers fa-fw",
                                attrs: { href: "javascript:void(0)" },
                                on: { click: _vm.showUpload },
                              },
                              [
                                !_vm.app.user.stripe_account_id ||
                                !_vm.app.user.approved_at
                                  ? _c("template", { slot: "tooltip" }, [
                                      !_vm.app.user.stripe_account_id
                                        ? _c("p", [
                                            _vm._v(
                                              "Complete verification in your account section"
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.app.user.stripe_account_id &&
                                      !_vm.app.user.approved_at
                                        ? _c("p", [
                                            _vm._v(
                                              "We're just verifying your\n                            account\n                            first."
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("template", { slot: "tooltip" }, [
                                  _c("p", [
                                    _vm._v(
                                      "Upload restriction reached: upgrade to receive unlimited uploads"
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("i", { staticClass: "fa fa-upload" }),
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "user-bar-item",
                            attrs: { to: "/account/releases" },
                          },
                          [_c("i", { staticClass: "fa fa-music" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "user-bar-item",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.toggleNotification.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "fa-layers fa-fw" }, [
                              _c("i", { staticClass: "fa fa-bell" }),
                              _vm._v(" "),
                              _vm.notificationsCount > 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "fa-layers-counter message-counter",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(_vm.notificationsCount) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("notification-dropdown", {
                          attrs: {
                            show: _vm.showNotificationDropdown,
                            notifications: _vm.notifications,
                          },
                          on: {
                            dismiss: function ($event) {
                              return _vm.handleDismissNotification($event)
                            },
                            clearall: function ($event) {
                              return _vm.handleClearAllNotification($event)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "user-bar-item",
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.toggleMessages.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "fa-layers fa-fw" }, [
                              _c("i", { staticClass: "fa fa-message" }),
                              _vm._v(" "),
                              _vm.messenger.unreadThreads.length
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "fa-layers-counter message-counter",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm.messenger.unreadThreads.length
                                          )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("message-dropdown", {
                          attrs: {
                            show: _vm.messages.show,
                            threads: _vm.messenger?.unreadThreads,
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "user-bar-item",
                            attrs: { to: "/account/mymusic" },
                          },
                          [_c("i", { staticClass: "fa fa-download" })]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "user-bar-item fa-layers fa-fw",
                            attrs: { href: "#" },
                            on: { click: _vm.showCart },
                          },
                          [
                            _c("i", { staticClass: "fa fa-shopping-cart" }),
                            _vm._v(" "),
                            _vm.cart.items.length
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "fa-layers-counter message-counter",
                                  },
                                  [_vm._v(_vm._s(_vm.cart.items.length))]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "user-bar-item",
                            attrs: { to: "/user/" + _vm.app.user.path },
                          },
                          [
                            _vm.app.user.avatar
                              ? _c("avatar", {
                                  attrs: {
                                    src: _vm.app.user.avatar.files.medium.url,
                                    alt: _vm.app.user.avatar.alt,
                                    size: 35,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.app.user.loggedin
                  ? _c(
                      "ph-button",
                      {
                        attrs: { color: _vm.buttonClass, other: "thick" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.showAuthModal.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                Create Account >\n            "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.app.user.loggedin
                  ? _c(
                      "ph-button",
                      {
                        attrs: { color: _vm.buttonClass, other: "thick" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.$modal.show("modal-auth-login")
                          },
                        },
                      },
                      [_vm._v("Sign In >")]
                    )
                  : _c(
                      "ph-button",
                      {
                        attrs: { other: "thick", loading: _vm.loggingOut },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.logout.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("\n                Logout\n            ")]
                    ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "header",
      { staticClass: "responsive" },
      [
        _c(
          "div",
          {
            staticClass: "top",
            attrs: { id: "mobile-toggle" },
            on: {
              click: function ($event) {
                return _vm.$emit("slideoutToggle")
              },
            },
          },
          [_vm._m(0)]
        ),
        _vm._v(" "),
        !_vm.search.visible
          ? _c(
              "router-link",
              { staticClass: "logo", attrs: { to: "/" } },
              [_c("logo", { staticStyle: { width: "145px" } })],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.search.visible
          ? _c("form", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerm,
                    expression: "searchTerm",
                  },
                ],
                attrs: { type: "text", placeholder: "SEARCH" },
                domProps: { value: _vm.searchTerm },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchTerm = $event.target.value
                  },
                },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "search-toggle",
            class: _vm.searchClass,
            on: { click: _vm.toggleSearch },
          },
          [_c("i", { staticClass: "fa fa-search" })]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top mobile-toggle" }, [
      _c("i", { staticClass: "fa fa-bars" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }