var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid" },
    _vm._l(_vm.genres, function (item) {
      return _c(
        "div",
        { key: item.id, staticClass: "grid_item" },
        [
          _c("router-link", { attrs: { to: _vm.getRouterObject(item) } }, [
            _c("img", {
              attrs: {
                src: "https://images.unsplash.com/photo-1468164016595-6108e4c60c8b?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=80",
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("p", { staticClass: "type" }, [_vm._v(_vm._s(item.type))]),
              _vm._v(" "),
              _c("p", { staticClass: "name" }, [
                _vm._v(_vm._s(item.name || item.title)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "overlay" }),
          ]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }