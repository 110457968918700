var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loadingPosts,
            expression: "loadingPosts",
          },
        ],
        staticStyle: { margin: "3em auto" },
        attrs: { "animation-duration": 1000, size: 60, color: "black" },
      }),
      _vm._v(" "),
      _vm._l(_vm.posts, function (post) {
        return _c("feed-action", {
          key: post.id,
          attrs: {
            action: post,
            "this-user": _vm.mutableUser,
            commentable: _vm.commentable,
          },
        })
      }),
      _vm._v(" "),
      !_vm.posts.length && !_vm.loadingPosts
        ? _c(
            "div",
            [
              !_vm.isShow
                ? _c("span", { staticClass: "not-found" }, [
                    _vm._v(
                      "\n            This user has not posted yet.\n        "
                    ),
                  ])
                : _c(
                    "ph-button",
                    {
                      staticStyle: { display: "block", "text-align": "center" },
                      attrs: { size: "medium" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleAllLink()
                        },
                      },
                    },
                    [_vm._v("Create  a post")]
                  ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }