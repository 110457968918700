var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      (_vm.release && _vm.release.status === "live") ||
      (_vm.release &&
        _vm.app.user.all_permissions.includes("view admin dashboard"))
        ? _c("track-release", { attrs: { item: _vm.release } })
        : _vm._e(),
      _vm._v(" "),
      _vm.release &&
      _vm.release.status !== "live" &&
      !_vm.app.user.all_permissions.includes("view admin dashboard")
        ? _c("div", { staticClass: "center-content" }, [
            _vm._v("\n        This release has not been approved yet.\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.release
        ? _c("spinner", {
            staticStyle: { margin: "3em auto" },
            attrs: { "animation-duration": 1000, size: 80, color: "black" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }