var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "footer",
    {
      staticClass: "main",
      style:
        _vm.currentRouteName == "message_thread" || "login"
          ? "margin-top:0px;"
          : "margin-top:40px;",
    },
    [
      _c("div", { staticClass: "footer-upper" }, [
        _c(
          "div",
          { staticClass: "footer-logo" },
          [_c("logo", { attrs: { color: "black" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footer-links" },
          [
            _c("navigation-list", {
              staticClass: "footer-menu",
              attrs: { items: _vm.navigation.footer_one },
            }),
            _vm._v(" "),
            _c("navigation-list", {
              staticClass: "footer-menu",
              attrs: { items: _vm.navigation.footer_two },
            }),
            _vm._v(" "),
            _c("navigation-list", {
              staticClass: "footer-menu",
              attrs: { items: _vm.navigation.footer_three },
            }),
            _vm._v(" "),
            _c("navigation-list", {
              staticClass: "footer-menu",
              attrs: { items: _vm.navigation.footer_four },
            }),
            _vm._v(" "),
            _c("navigation-list", {
              staticClass: "footer-menu",
              attrs: { items: _vm.navigation.footer_five },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "footer-lower" }, [
        _c("div", [_vm._v("© phase " + _vm._s(new Date().getFullYear()))]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("navigation-list", {
              staticClass: "footer-lower-menu",
              attrs: { items: _vm.navigation.footer_lower },
            }),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer-social" }, [
      _c("a", { attrs: { href: "https://facebook.com" } }, [
        _c("i", { staticClass: "fab fa-facebook" }),
      ]),
      _vm._v(" "),
      _c("a", { attrs: { href: "https://twitter.com" } }, [
        _c("i", { staticClass: "fab fa-twitter" }),
      ]),
      _vm._v(" "),
      _c("a", { attrs: { href: "https://linkedin.com" } }, [
        _c("i", { staticClass: "fab fa-linkedin" }),
      ]),
      _vm._v(" "),
      _c("a", { attrs: { href: "https://instagram.com" } }, [
        _c("i", { staticClass: "fab fa-instagram" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }