var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid" },
    _vm._l(_vm.data, function (item) {
      return _c(
        "div",
        { key: item.id, staticClass: "grid_item" },
        [
          _c("router-link", { attrs: { to: _vm.getRouterObject(item) } }, [
            _vm._v(
              "\n            " +
                _vm._s(item.type) +
                "\n            " +
                _vm._s(item.name || item.title) +
                "\n        "
            ),
          ]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }