var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-account-responsive" },
    [
      _c(
        "overlay-notify",
        {
          attrs: { "is-visible": _vm.isOverlayVisible },
          on: { close: _vm.hideOverlay },
        },
        [
          _c("div", { staticClass: "overlay-content" }, [
            !_vm.infoProcessed
              ? _c(
                  "div",
                  [
                    _c("InfoProcessingIcon"),
                    _vm._v(" "),
                    _c("div", { staticStyle: { "margin-top": "10px" } }, [
                      _vm._v(
                        "Your subscription and payment details are being processed. Please wait while we\n          confirm your bank account information and activate your subscription."
                      ),
                    ]),
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "overLayClose",
                      on: { click: _vm.hideOverlay },
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-times",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.infoProcessed && _vm.isErrorInInfo
                    ? _c("div", [
                        _c("div", [
                          _vm._v(
                            "There was an error processing your subscription. Please verify your details and try again."
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.infoProcessed && !_vm.isErrorInInfo
                    ? _c(
                        "div",
                        [
                          _c("InfoProcessedIcon"),
                          _vm._v(" "),
                          _c("h1", { staticStyle: { "margin-top": "20px" } }, [
                            _vm._v("Account Upgraded"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "10px",
                                "font-size": "24px",
                              },
                            },
                            [_vm._v("Welcome to the club!")]
                          ),
                          _vm._v(" "),
                          _c("ConfettiEffect", {
                            attrs: { isActive: _vm.isConfettiEffect },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
          ]),
        ]
      ),
      _vm._v(" "),
      !_vm.app.user.approved_at
        ? _c("ph-panel", { attrs: { id: "approved" } }, [
            _c("p", [_vm._v("This account is waiting for approval")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.app.user.status === "frozen"
        ? _c("ph-panel", [
            _c("p", [_vm._v("Your account is currently frozen")]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("manage"),
      _vm._v(" "),
      _c("notifications"),
      _vm._v(" "),
      _c("ph-panel", { attrs: { id: "email" } }, [
        _c("h2", [_vm._v("Security")]),
        _vm._v(" "),
        _c("div", { staticClass: "two-col-panel-block" }, [
          _c(
            "div",
            { staticClass: "col-panel-block" },
            [
              _c("div", { staticClass: "heading-panel-block" }, [
                _c("h4", [_vm._v("PASSWORD")]),
              ]),
              _vm._v(" "),
              _c("table", [
                _c("tr", [
                  _c("td", [_vm._v("Current Password")]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.disable",
                          value: "required",
                          expression: "'required'",
                          modifiers: { disable: true },
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password.current,
                          expression: "password.current",
                        },
                      ],
                      staticClass: "input",
                      attrs: {
                        type: "password",
                        name: "password-current",
                        "data-vv-scope": "form-password",
                        placeholder: "Current Password",
                      },
                      domProps: { value: _vm.password.current },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.password, "current", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "error-msg" }, [
                      _vm._v(
                        _vm._s(
                          _vm.errors.first("form-password.password-current")
                        )
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("New Password")]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate.disable",
                          value: { required: true },
                          expression: "{ required: true }",
                          modifiers: { disable: true },
                        },
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.password.new,
                          expression: "password.new",
                        },
                      ],
                      staticClass: "input",
                      attrs: {
                        type: "password",
                        name: "password-new",
                        "data-vv-scope": "form-password",
                        placeholder: "New Password",
                      },
                      domProps: { value: _vm.password.new },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.password, "new", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "error-msg" }, [
                      _vm._v(
                        _vm._s(_vm.errors.first("form-password.password-new"))
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _vm.password.new
                  ? _c("tr", [
                      _c("td", [_vm._v("Confirm Password")]),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate.disable",
                              value: {
                                is: _vm.password.new,
                                required: true,
                                max: 255,
                              },
                              expression:
                                "{\n                is: password.new,\n                required: true,\n                max: 255,\n              }",
                              modifiers: { disable: true },
                            },
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.password.confirm,
                              expression: "password.confirm",
                            },
                          ],
                          attrs: {
                            type: "password",
                            name: "password-confirm",
                            "data-vv-scope": "form-password",
                            placeholder: "Confirm Password",
                          },
                          domProps: { value: _vm.password.confirm },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.password,
                                "confirm",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(
                            _vm._s(
                              _vm.errors.first("form-password.password-confirm")
                            )
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c(
                "ph-button",
                {
                  attrs: { size: "small", loading: _vm.password.submitting },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.savePassword.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "border-line" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-panel-block" },
            [
              _c("div", { staticClass: "heading-panel-block" }, [
                _c("h4", [_vm._v("EMAIL")]),
              ]),
              _vm._v(" "),
              _c("table", [
                _c("tr", [
                  _c("td", [_vm._v("Your Email")]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.app.user.email))]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("New Email")]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "div",
                      { class: { "email-input": true, valid: _vm.isValid } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.email.address,
                              expression: "email.address",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|email",
                              expression: "'required|email'",
                            },
                          ],
                          attrs: {
                            type: "email",
                            name: "email-address",
                            placeholder: "Enter new address",
                            "data-vv-scope": "form-email",
                            "data-vv-validate-on": "focusout",
                          },
                          domProps: { value: _vm.email.address },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.email,
                                "address",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("svg", { attrs: { viewBox: "0 0 18 18" } }, [
                          _c("path", {
                            attrs: {
                              d: "M11.5,10.5 C6.4987941,17.5909626 1,3.73719105 11.5,6 C10.4594155,14.5485365 17,13.418278 17,9 C17,4.581722 13.418278,1 9,1 C4.581722,1 1,4.581722 1,9 C1,13.418278 4.581722,17 9,17 C13.418278,17 17,13.42 17,9",
                            },
                          }),
                          _vm._v(" "),
                          _c("polyline", {
                            attrs: { points: "5 9.25 8 12 13 6" },
                          }),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("span", { staticClass: "error-msg" }, [
                      _vm._v(
                        _vm._s(_vm.errors.first("form-email.email-address"))
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "ph-button",
                {
                  attrs: { size: "small", loading: _vm.email.submitting },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.saveEmail.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Save")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("billing"),
      _vm._v(" "),
      _c("invoices"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }