var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "edit-track" }, [
    _c("div", { staticClass: "upload-heading" }, [
      _c("div", [
        _vm._v(
          _vm._s(_vm.currentTrackNumber) + ". " + _vm._s(_vm.currentTrack.title)
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "subheading" }, [
        _vm._v("Click and drag tracks to reorder them"),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "upload-detail" }, [
      _c("div", { staticClass: "upload-meta" }, [
        _c(
          "form",
          [
            _c("table", { staticClass: "track-info__table" }, [
              _c("tr", [
                _c("td", [_vm._v("Title*")]),
                _vm._v(" "),
                _c("td", { staticClass: "form-td" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentTrack.title,
                        expression: "currentTrack.title",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:50",
                        expression: "'required|max:50'",
                      },
                    ],
                    key: _vm.currentTrackNumber,
                    attrs: {
                      type: "text",
                      name: "track_title",
                      placeholder: "Track Name",
                      "data-vv-as": "track title",
                    },
                    domProps: { value: _vm.currentTrack.title },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.currentTrack, "title", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.errors.first("track_title"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Format*")]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "form-td" },
                  [
                    _c("ph-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      key: _vm.currentTrackNumber,
                      attrs: {
                        name: "format",
                        title: "Format",
                        options: { mp3: "MP3", wav: "WAV" },
                      },
                      model: {
                        value: _vm.currentTrack.format,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentTrack, "format", $$v)
                        },
                        expression: "currentTrack.format",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "error-message" }, [
                      _vm._v(_vm._s(_vm.errors.first("format"))),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "tr",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTrack.format,
                      expression: "currentTrack.format",
                    },
                  ],
                  key: _vm.currentTrackNumber,
                },
                [
                  _c("td", [
                    _vm._v(
                      "Audio File* " + _vm._s(`.${_vm.currentTrack.format}`)
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "form-td" },
                    [
                      !_vm.track.file
                        ? _c(
                            "ph-button",
                            {
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.selectFile.apply(null, arguments)
                                },
                              },
                            },
                            [_vm._v("Choose File")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.track.file
                        ? _c("input", {
                            directives: [
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required",
                                expression: "'required'",
                              },
                            ],
                            key: _vm.currentTrackNumber,
                            ref: "fileInput",
                            staticStyle: { display: "none" },
                            attrs: {
                              type: "file",
                              name: "file",
                              accept: `.${_vm.currentTrack.format}`,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.setTrackFile($event, _vm.track)
                              },
                            },
                          })
                        : _c("div", { staticClass: "selected-file" }, [
                            _c("div", { staticClass: "selected-file__left" }, [
                              _vm._m(0),
                              _vm._v(" "),
                              _c("p", { staticClass: "selected-file__name" }, [
                                _vm._v(_vm._s(_vm.currentTrack.file.name)),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("span", { on: { click: _vm.removeFile } }, [
                              _c("i", { staticClass: "fas fa-times" }),
                            ]),
                          ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "error-message" }, [
                        _vm._v(_vm._s(_vm.errors.first("file"))),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("Key*")]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "form-td" },
                  [
                    _c("ph-select", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      key: _vm.currentTrackNumber,
                      attrs: {
                        name: "key",
                        title: "Key",
                        options: {
                          a: "A",
                          "a+": "A#",
                          b: "B",
                          c: "C",
                          "c+": "C#",
                          d: "D",
                          "d+": "D#",
                          e: "E",
                          f: "F",
                          "f+": "F#",
                          g: "G",
                          "g+": "G#",
                        },
                      },
                      model: {
                        value: _vm.currentTrack.key,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentTrack, "key", $$v)
                        },
                        expression: "currentTrack.key",
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "error-message" }, [
                      _vm._v(_vm._s(_vm.errors.first("key"))),
                    ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", [_vm._v("BPM*")]),
                _vm._v(" "),
                _c("td", { staticClass: "form-td" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentTrack.bpm,
                        expression: "currentTrack.bpm",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|between:40,250",
                        expression: "'required|between:40,250'",
                      },
                    ],
                    key: _vm.currentTrackNumber,
                    attrs: {
                      type: "number",
                      name: "bpm",
                      min: "40",
                      max: "250",
                    },
                    domProps: {
                      value: _vm.currentTrack.bpm,
                      value: _vm.currentTrack.bpm,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.currentTrack, "bpm", $event)
                      },
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.currentTrack, "bpm", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.errors.first("bpm"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticStyle: { "vertical-align": "middle" } }, [
                  _vm._v("Price*"),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "form-td" },
                  [
                    _c("price-range", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      key: _vm.currentTrackNumber,
                      attrs: {
                        min: 50,
                        max: 300,
                        value: _vm.currentTrack.price,
                        name: "price",
                        step: 1,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.currentTrack, "price", $event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "error-message" }, [
                      _vm._v(_vm._s(_vm.errors.first("price"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "ph-button",
              {
                staticClass: "centered-inline submit-button",
                attrs: { size: "medium", color: "primary-outline" },
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.attemptSubmit.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\t\tUpload release\n\t\t\t\t")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fas fa-file" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }