var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "phase-loading" },
          [
            _c("dotlottie-player", {
              staticStyle: { width: "150px", height: "150px" },
              attrs: {
                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                background: "transparent",
                speed: "1",
                loop: "",
                autoplay: "",
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          [
            _c("ph-panel", [
              _c("h2", { staticStyle: { "margin-top": "30px" } }, [
                _vm._v("Invoices"),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.invoices && _vm.invoices.length > 0
                ? _c("table", { attrs: { cellspacing: "10" } }, [
                    _c("thead", [
                      _c("tr", [
                        _c("td", [_vm._v("Date")]),
                        _vm._v(" "),
                        _c("td", [_vm._v("Payment")]),
                        _vm._v(" "),
                        _c("td", [_vm._v("Status")]),
                        _vm._v(" "),
                        _c("td", [_vm._v("Invoice")]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tbody",
                      _vm._l(_vm.invoices.data, function (invoice) {
                        return _c("tr", [
                          _c("td", [_vm._v(_vm._s(_vm.invoiceDate(invoice)))]),
                          _vm._v(" "),
                          _c("td", [_vm._v(_vm._s(_vm.amountPaid(invoice)))]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(invoice.paid ? "Paid" : "Outstanding")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "a",
                              {
                                staticClass: "view_link",
                                attrs: { href: invoice.invoice_pdf },
                              },
                              [_vm._v("View")]
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ])
                : _c("div", [
                    _vm._v("\n        You do not have any invoices.\n      "),
                  ]),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }