var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticStyle: { "text-align": "center" } }, [
      _vm._v("Verification"),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { id: "account-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c("p", { staticStyle: { "text-align": "center" } }, [
          _vm._v(
            "The following details are required to provide verification and a method of\n\t\t\tpayment for sales, Details given\n\t\t\tmust be full legal and registered names"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Business Type:")]),
            _vm._v(" "),
            _c(
              "ul",
              {
                staticClass:
                  "gridtypelist list-unstyled list-inline float-left",
              },
              [
                _c("li", [
                  _c(
                    "label",
                    { class: [_vm.type === "individual" ? "active" : ""] },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:255",
                            expression: "'required|max:255'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.type,
                            expression: "type",
                          },
                        ],
                        attrs: { type: "radio", value: "individual" },
                        domProps: { checked: _vm._q(_vm.type, "individual") },
                        on: {
                          click: function ($event) {
                            return _vm.bussinessTypeChanged()
                          },
                          change: function ($event) {
                            _vm.type = "individual"
                          },
                        },
                      }),
                      _vm._v(" Individual\n\t\t\t\t\t\t"),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "label",
                    { class: [_vm.type === "company" ? "active" : ""] },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:255",
                            expression: "'required|max:255'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.type,
                            expression: "type",
                          },
                        ],
                        attrs: { type: "radio", value: "company" },
                        domProps: { checked: _vm._q(_vm.type, "company") },
                        on: {
                          click: function ($event) {
                            return _vm.bussinessTypeChanged()
                          },
                          change: function ($event) {
                            _vm.type = "company"
                          },
                        },
                      }),
                      _vm._v(" Company\n\t\t\t\t\t\t"),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("li", [
                  _c(
                    "label",
                    { class: [_vm.type === "non_profit" ? "active" : ""] },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:255",
                            expression: "'required|max:255'",
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.type,
                            expression: "type",
                          },
                        ],
                        attrs: { type: "radio", value: "non_profit" },
                        domProps: { checked: _vm._q(_vm.type, "non_profit") },
                        on: {
                          click: function ($event) {
                            return _vm.bussinessTypeChanged()
                          },
                          change: function ($event) {
                            _vm.type = "non_profit"
                          },
                        },
                      }),
                      _vm._v(" Non Profit\n\t\t\t\t\t\t"),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.type == "company" || _vm.type == "non_profit"
          ? _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "input" }, [
                _c("div", [_vm._v("Business Structure:")]),
                _vm._v(" "),
                _c("div", [
                  _vm.type == "company"
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.business_structure,
                              expression: "business_structure",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            name: "business_structure",
                            "data-vv-as": "business structure",
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.business_structure = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { value: "", disabled: "", selected: "" },
                            },
                            [_vm._v("Select Structure")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { value: "incorporated_partnership" } },
                            [_vm._v("Limited Liability Partnership (LLP)")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { value: "unincorporated_partnership" } },
                            [_vm._v("Unincorporated Partnership")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { value: "private_company" } },
                            [_vm._v("Private Company")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type == "non_profit"
                    ? _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.business_structure,
                              expression: "business_structure",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          staticStyle: { width: "100%" },
                          attrs: {
                            name: "business_structure",
                            "data-vv-as": "business structure",
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.business_structure = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { value: "", disabled: "", selected: "" },
                            },
                            [_vm._v("Select Structure")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { value: "incorporated_non_profit" } },
                            [_vm._v("Incorporated Non-Profit")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { value: "unincorporated_non_profit" } },
                            [_vm._v("Unincorporated Non-Profit")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.errors.first("business_structure"))),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.type == "individual"
          ? _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "input" }, [
                _c("div", [_vm._v("First Name:")]),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.first_name,
                        expression: "first_name",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:255",
                        expression: "'required|max:255'",
                      },
                    ],
                    attrs: {
                      type: "text",
                      name: "first_name",
                      tabindex: "1",
                      "data-vv-as": "first name",
                    },
                    domProps: { value: _vm.first_name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.first_name = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.errors.first("first_name"))),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "input" }, [
                _c("div", [_vm._v("Last Name:")]),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.last_name,
                        expression: "last_name",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:255",
                        expression: "'required|max:255'",
                      },
                    ],
                    attrs: {
                      type: "text",
                      name: "last_name",
                      tabindex: "1",
                      "data-vv-as": "last name",
                    },
                    domProps: { value: _vm.last_name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.last_name = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.errors.first("last_name"))),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.type == "company" || _vm.type == "non_profit"
          ? _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "input" }, [
                _c("div", [_vm._v("Company Name:")]),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.company_name,
                        expression: "company_name",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:255",
                        expression: "'required|max:255'",
                      },
                    ],
                    attrs: {
                      type: "text",
                      name: "company_name",
                      tabindex: "1",
                      "data-vv-as": "company name",
                    },
                    domProps: { value: _vm.company_name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.company_name = $event.target.value
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.errors.first("company_name"))),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Country:")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("country-select", {
                  staticStyle: { width: "100%", "margin-top": "8px" },
                  on: { change: _vm.artistCountryChanged },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error-message" }, [
                  _vm._v(_vm._s(_vm.errors.first("country"))),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "hover-text" }, [
          _c("i", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "my text",
                expression: "'my text'",
              },
            ],
            staticClass: "fa fa-info-circle",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v("\n\t\t\tCountry not\n\t\t\tlisted?\n\t\t\t"),
          _c("p", { staticClass: "tooltip-text", attrs: { id: "left" } }, [
            _vm._v(
              "Unfortunately, if your country is not listed we are not yet able to setup\n\t\t\t\tand verify you as\n\t\t\t\tan\n\t\t\t\tArtist or\n\t\t\t\tArtist PRO."
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "submit-buttons" }, [
          _c(
            "div",
            { staticClass: "button-wrap" },
            [
              _c(
                "ph-button",
                { attrs: { size: "medium", loading: _vm.submitting } },
                [_vm._v("\n\t\t\t\t\tSave & Continue\n\t\t\t\t")]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }