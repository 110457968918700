<template>
    <canvas id="confettiCanvas" ref="confettiCanvas"></canvas>
</template>

<script>
export default {
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    mounted() {
        if (this.isActive) {
            this.showConfetti();
        }
    },
    watch: {
        isActive(newValue) {
            if (newValue) {
                this.showConfetti();
            }
        },
    },
    methods: {
        showConfetti() {
            // Your custom confetti JS code here
            const canvas = this.$refs.confettiCanvas;
            const ctx = canvas.getContext('2d');
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            const confettiParticles = [];
            const particleCount = 100;

            function randomInRange(min, max) {
                return Math.random() * (max - min) + min;
            }

            class ConfettiParticle {
                constructor() {
                    this.x = Math.random() * canvas.width;
                    this.y = Math.random() * canvas.height - canvas.height;
                    this.size = randomInRange(5, 10);
                    this.color = `hsl(${Math.random() * 360}, 100%, 50%)`;
                    this.speed = randomInRange(1, 3);
                    this.angle = randomInRange(0, 2 * Math.PI);
                    this.shape = Math.floor(Math.random() * 3); // Random shape (0 = rectangle, 1 = triangle, 2 = line)
                }

                update() {
                    this.y += this.speed;
                    this.x += Math.sin(this.angle) * 2;

                    if (this.y > canvas.height) {
                        this.y = -this.size;
                        this.x = Math.random() * canvas.width;
                    }
                }

                draw() {
                    ctx.beginPath();
                    switch (this.shape) {
                        case 0: // Rectangle
                            ctx.rect(this.x, this.y, this.size * 2, this.size); // Random rectangles
                            break;
                        case 1: // Triangle
                            ctx.moveTo(this.x, this.y);
                            ctx.lineTo(this.x + this.size, this.y + this.size);
                            ctx.lineTo(this.x - this.size, this.y + this.size);
                            ctx.closePath();
                            break;
                        case 2: // Line
                            ctx.moveTo(this.x, this.y);
                            ctx.lineTo(this.x + this.size * 2, this.y);
                            break;
                    }
                    ctx.fillStyle = this.color;
                    ctx.fill();
                }
            }

            for (let i = 0; i < particleCount; i++) {
                confettiParticles.push(new ConfettiParticle());
            }

            function renderConfetti() {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                confettiParticles.forEach(particle => {
                    particle.update();
                    particle.draw();
                });
                requestAnimationFrame(renderConfetti);
            }

            renderConfetti();
        },
    }
}
</script>

<style scoped>
#confettiCanvas {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}
</style>