var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "page-content-padded",
      staticStyle: {
        margin: "18px",
        padding: "1.5rem",
        "border-radius": "5px",
        background: "#f1f1f1",
      },
    },
    [
      _vm.currentThread?.messages?.length > 0
        ? _c("div", { staticClass: "thread-sidebar" }, [
            _c(
              "div",
              { staticClass: "thread-list" },
              _vm._l(_vm.threads, function (thread) {
                return _c(
                  "div",
                  {
                    class: [
                      "thread-item",
                      { active: _vm.isActiveThread(thread.id) },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.$router.push(`/user/thread/${thread.id}`)
                      },
                    },
                  },
                  [
                    _c("avatar", {
                      attrs: {
                        src: _vm.getReceiver(thread, "avatar"),
                        size: 40,
                        center: false,
                      },
                    }),
                    _vm._v(" "),
                    _c("h4", [_vm._v(_vm._s(_vm.getReceiver(thread, "name")))]),
                  ],
                  1
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.currentThread?.messages?.length > 0
        ? _c("div", { staticClass: "page-main single-thread-page" }, [
            _c(
              "div",
              { staticClass: "thread-header" },
              [
                _c("avatar", {
                  attrs: {
                    src: _vm.getReceiver(_vm.currentThread, "avatar"),
                    size: 70,
                    center: false,
                  },
                }),
                _vm._v(" "),
                _c("h4", [
                  _vm._v(_vm._s(_vm.getReceiver(_vm.currentThread, "name"))),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "thread-messages", attrs: { id: "messages" } },
              _vm._l(_vm.currentThread.messages, function (message) {
                return _c(
                  "div",
                  { key: message.id, staticClass: "thread-message-row" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "thread-message",
                        class: _vm.didSendMessage(message),
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "message-avatar" },
                          [
                            _c("avatar", {
                              attrs: {
                                src: _vm.getMessageSender(message, "avatar"),
                                size: 40,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "message-body",
                            class: _vm.unreadMessage(message.views[0].view),
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(message.body) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.didSendMessage(message) == "mine"
                          ? _c("div", { staticClass: "actions" }, [
                              _c(
                                "div",
                                { staticClass: "action" },
                                [
                                  _c("delete-button", {
                                    attrs: { deleteable: message },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "message-time" }, [
                          _c("small", [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.moment(message.date).calendar()) +
                                "\n                            "
                            ),
                            _vm.didSendMessage(message) == "mine"
                              ? _c("span", [
                                  !message.views[0].view
                                    ? _c("i", { staticClass: "fa fa-check" })
                                    : _c("i", {
                                        staticClass: "fa fa-check-double",
                                      }),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "thread-respond" },
              [
                _c("add-text", {
                  staticStyle: { "margin-bottom": "0rem" },
                  attrs: { type: "message", thread: _vm.currentThread },
                  on: {
                    keydown: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      $event.preventDefault()
                    },
                    success: function ($event) {
                      return _vm.appendNewMessage($event)
                    },
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.currentThread?.messages
        ? _c("dotlottie-player", {
            staticStyle: { width: "200px", height: "200px", margin: "auto" },
            attrs: {
              src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
              background: "transparent",
              speed: "1",
              loop: "",
              autoplay: "",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }