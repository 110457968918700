var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-item" }, [
    _c(
      "div",
      { staticClass: "p-item-image" },
      [
        _c("router-link", {
          attrs: { to: _vm.getRouterObject(_vm.mutableTrack) },
        }),
        _vm._v(" "),
        _c("avatar-track", {
          attrs: {
            size: 130,
            tile: true,
            labels: _vm.labels,
            src: _vm.mutableTrack.release.image.files.medium.url,
            track: _vm.mutableTrack,
            recent: _vm.mutableTrack.is_recent,
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "p-item-main" }, [
      _c("div", { staticClass: "p-item-detail" }, [
        _c(
          "div",
          { staticClass: "p-item-title" },
          [
            _c(
              "router-link",
              {
                staticStyle: { "text-decoration": "none" },
                attrs: { to: _vm.getRouterObject(_vm.mutableTrack) },
              },
              [_c("span", [_vm._v(_vm._s(_vm.mutableTrack.name))])]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "release-description" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.mutableTrack.release.uploader.name) +
              "\n            "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "trackProgress",
          staticClass: "p-item-track-progress",
          on: {
            click: _vm.doClick,
            mousedown: _vm.startDrag,
            mousemove: _vm.doDrag,
            mouseup: _vm.endDrag,
          },
        },
        [
          _c("div", { staticClass: "p-item-track-progress-bar-background" }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "p-item-track-progress-bar",
              style: "width: " + _vm.defaultTrack.progress + "%",
            },
            [_c("div", { staticClass: "p-item-track-playhead" })]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p-item-meta" },
        [
          _c("actions", {
            attrs: { actionable: _vm.mutableTrack, actiontype: "track" },
            on: { like: _vm.liked, unlike: _vm.unliked },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "p-item-time" }, [
            _vm._v(
              "\n                " +
                _vm._s(_vm.moment(_vm.mutableTrack.created_at).fromNow()) +
                "\n            "
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }