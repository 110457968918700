var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-remove-conversation",
        width: "500px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
    },
    [
      _c("div", { staticClass: "modal modal-remove-conversation" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-remove-conversation")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content content-section" }, [
          _c("h2", [_vm._v("Remove Conversation")]),
          _vm._v(" "),
          _c("h3", [
            _vm._v("Are you sure you want to remove this conversation?"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "save-button" },
            [
              _c(
                "ph-button",
                {
                  attrs: {
                    size: "large",
                    loading: _vm.submitting,
                    color: "danger-outline",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.removeConversation.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Delete")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "description" }, [
            _vm._v(
              "Removing this conversation will delete all messages in the thread. This action cannot be undone, so please make sure you want to proceed."
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }