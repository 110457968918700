var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contact-form" },
    [
      _c("h2", [_vm._v("Still didn't find what you're looking for?")]),
      _vm._v(" "),
      _c("h3", [_vm._v("Send us a message using the form below.")]),
      _vm._v(" "),
      _vm.error === true
        ? _c("ph-panel", { attrs: { type: "error" } }, [
            _c("h4", [_vm._v("Error!")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            We encountered an unknown error when submitting this form. Please try again later.\n        "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.submitted
        ? _c(
            "form",
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input.email,
                    expression: "input.email",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email",
                    expression: "'required|email'",
                  },
                ],
                attrs: {
                  type: "email",
                  placeholder: "Email",
                  name: "email",
                  disabled: _vm.submitting,
                },
                domProps: { value: _vm.input.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.input, "email", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("email"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input.name,
                    expression: "input.name",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  type: "text",
                  placeholder: "Name",
                  name: "name",
                  disabled: _vm.submitting,
                },
                domProps: { value: _vm.input.name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.input, "name", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("name"))),
              ]),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.input.message,
                    expression: "input.message",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  placeholder: "Message",
                  rows: "10",
                  name: "message",
                  disabled: _vm.submitting,
                },
                domProps: { value: _vm.input.message },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.input, "message", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("message"))),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "ph-button",
                {
                  attrs: { size: "medium", loading: _vm.submitting },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Send")]
              ),
            ],
            1
          )
        : _c("ph-panel", { attrs: { type: "success" } }, [
            _c("h4", [_vm._v("Success!")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n            Your message was submitted successsfully.\n        "
              ),
            ]),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }