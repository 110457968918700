var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded page-news-index" }, [
    _c("div", { staticClass: "page-main" }, [
      _vm.page.page
        ? _c("h1", [_vm._v(_vm._s(_vm.page.page.title))])
        : _vm._e(),
      _vm._v(" "),
      _vm.page.metas
        ? _c("div", [_c("img", { attrs: { src: _vm.page.metas.image } })])
        : _vm._e(),
      _vm._v(" "),
      _vm.page.metas
        ? _c("div", { domProps: { innerHTML: _vm._s(_vm.page.metas.content) } })
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "aside",
      { staticClass: "sidebar-right" },
      [
        _c("sidebar-group", {
          attrs: { title: "News", items: _vm.news.articles.slice(0, 5) },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }