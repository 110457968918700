var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.itemIs("post")
    ? _c("post", { attrs: { post: _vm.item, id: _vm.id } })
    : _vm.itemIs("release")
    ? _c("release", { attrs: { release: _vm.item } })
    : _vm.itemIs("track")
    ? _c("p-track", { attrs: { track: _vm.item } })
    : _vm.itemIs("asset")
    ? _c("asset", { attrs: { asset: _vm.item } })
    : _vm.itemIs("user")
    ? _c("user", { attrs: { user: _vm.item } })
    : _vm.itemIs("news")
    ? _c("news", { attrs: { news: _vm.item } })
    : _vm.itemIs("event")
    ? _c("event", { attrs: { event: _vm.item } })
    : _vm.itemIs("share")
    ? _c("share", { attrs: { share: _vm.item } })
    : _vm.itemIs("video")
    ? _c("p-video", { attrs: { video: _vm.item } })
    : _vm.itemIs("merch")
    ? _c("merch", { attrs: { merch: _vm.item, id: _vm.id } })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }