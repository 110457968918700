var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded page-news-index" }, [
    _c(
      "div",
      { staticClass: "page-main" },
      [
        _vm.music.items.length
          ? _c(
              "div",
              { staticClass: "pad-sides" },
              _vm._l(_vm.music.items, function (item) {
                return _c(
                  "div",
                  { key: item.id },
                  [_c("music-item", { attrs: { item: item } })],
                  1
                )
              }),
              0
            )
          : _c("spinner", {
              staticStyle: { margin: "3em auto" },
              attrs: { "animation-duration": 1000, size: 80, color: "black" },
            }),
        _vm._v(" "),
        _vm.hasAnotherPage && _vm.music.items.length
          ? _c(
              "div",
              {
                staticClass: "centered-text",
                staticStyle: { margin: "4em 0" },
              },
              [
                _c(
                  "ph-button",
                  {
                    attrs: { size: "large", loading: _vm.loadingNextPage },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.loadNextPage.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n        Show Me More\n      ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "aside",
      { staticClass: "sidebar-right" },
      [
        _c("sidebar-group", {
          attrs: { title: "News", items: _vm.news.articles.slice(0, 5) },
        }),
        _vm._v(" "),
        _c("sidebar-group", {
          attrs: { title: "Merch", items: _vm.merch.items.slice(0, 5) },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }