var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-item", style: `background: ${_vm.backgroundColor}` },
    [
      _c(
        "div",
        { staticClass: "p-item-image" },
        [
          _c(
            "router-link",
            { attrs: { to: _vm.getRouterObject(_vm.mutableTrack) } },
            [
              _c("avatar", {
                attrs: {
                  size: 130,
                  tile: true,
                  src: _vm.mutableTrack.release.image.files.medium.url,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "p-item-main" }, [
        _c(
          "div",
          { staticClass: "p-item-detail" },
          [
            _c(
              "div",
              { staticClass: "p-item-title" },
              [
                _c(
                  "router-link",
                  {
                    staticStyle: { "text-decoration": "none" },
                    attrs: { to: _vm.getRouterObject(_vm.mutableTrack) },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.mutableTrack.name))])]
                ),
                _vm._v(" "),
                _c("play-pause-button", {
                  attrs: { track: _vm.item.track, type: "streamable" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "p-item-subtitle" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.mutableTrack.release.uploader.name) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("social-sharing", {
              attrs: {
                url: "https://phase.test/",
                title: `I have just purchased new music ${_vm.mutableTrack.name} by ${_vm.mutableTrack.release.uploader.name}`,
                description: "",
                quote: `I have just purchased new music ${_vm.mutableTrack.name} by ${_vm.mutableTrack.release.uploader.name}`,
                hashtags: "phase,music",
                "twitter-user": "phase",
              },
              inlineTemplate: {
                render: function () {
                  var _vm = this,
                    _c = _vm._self._c
                  return _c(
                    "div",
                    { staticClass: "mt-4 social-share" },
                    [
                      _c("network", { attrs: { network: "facebook" } }, [
                        _c("i", { staticClass: "fab fa-facebook" }),
                      ]),
                      _vm._v(" "),
                      _c("network", { attrs: { network: "twitter" } }, [
                        _c("i", { staticClass: "fab fa-twitter" }),
                      ]),
                    ],
                    1
                  )
                },
                staticRenderFns: [],
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "download-block" }, [
          _vm.item.count < 5
            ? _c(
                "a",
                {
                  attrs: {
                    href: `/api/mymusic/download/mp3/${_vm.mutableTrack.id}`,
                    title: "Download Track",
                  },
                },
                [
                  _c("ph-button", [
                    _c("i", { staticClass: "fas fa-download" }),
                  ]),
                ],
                1
              )
            : _c("span", [_vm._v("You have reached your download limit")]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }