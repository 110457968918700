var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "page-content-padded page-profile-fan",
      staticStyle: { overflow: "hidden" },
    },
    [
      _c(
        "aside",
        { staticClass: "sidebar-left" },
        [
          _c("profile-avatar", {
            staticClass: "centered-block",
            attrs: { id: "profile-avatar", user: _vm.mutableUser, size: 190 },
          }),
          _vm._v(" "),
          _c("user-left-sidebar", { attrs: { user: _vm.user } }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-main" },
        [
          _c("profile-navigation", {
            attrs: { user: _vm.user, routeNameSubview: _vm.routeSubview },
            on: { linkclick: _vm.linkClicked },
          }),
          _vm._v(" "),
          _vm.linkclicked == 0
            ? _c("router-view", { attrs: { user: _vm.user } })
            : _vm._e(),
          _vm._v(" "),
          _vm.linkclicked == 1
            ? _c("main-subview", {
                attrs: { user: _vm.user, path: _vm.subviewpath },
                on: { linkclickSubview: _vm.linkClicked },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "aside",
        { staticClass: "sidebar-right" },
        [
          _c("user-right-sidebar", {
            attrs: { user: _vm.user, news: _vm.news },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }