var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-cart",
        width: "1030px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
      on: { opened: _vm.opened, closed: _vm.closed },
    },
    [
      _c("div", { staticClass: "modal modal-cart" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("logo", { staticStyle: { width: "183px" } }),
            _vm._v(" "),
            _c("close-icon", {
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-cart")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-content" },
          [
            _vm.cart.loading
              ? _c(
                  "p",
                  [_c("spinner", { attrs: { size: 50, color: "black" } })],
                  1
                )
              : [
                  _vm.cart.items.length
                    ? _c("div", [
                        _c(
                          "table",
                          [
                            _c("tr", [
                              _c("th", [
                                _vm._v(
                                  "\n                Name\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  "\n                Artist\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  "\n                Length\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  "\n                Format\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  "\n                Price\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("th"),
                            ]),
                            _vm._v(" "),
                            _vm._l(
                              _vm.$store.state.cart.items,
                              function (item) {
                                return _c("cart-item", {
                                  key: item.id,
                                  attrs: { item: item },
                                })
                              }
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "cart-bottom-container" }, [
                          _c(
                            "div",
                            { staticClass: "cart-bottom" },
                            [
                              _c("div", { staticClass: "cart-subtotal" }, [
                                _c("div", [
                                  _vm._v(
                                    "\n                  Sub Total:\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "£" +
                                      _vm._s(
                                        _vm.$store.getters["cart/getTotal"]
                                      )
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm.getUserLoggedIn
                                ? _c(
                                    "ph-button",
                                    {
                                      attrs: { size: "large" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.showCheckout.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                Check out\n              "
                                      ),
                                    ]
                                  )
                                : _c(
                                    "ph-button",
                                    {
                                      attrs: { size: "large" },
                                      nativeOn: {
                                        click: function ($event) {
                                          return _vm.showCheckout.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                Login to Check out\n              "
                                      ),
                                    ]
                                  ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _c("div", { staticClass: "centered-text" }, [
                        _vm._v(
                          "\n          You have no items in your cart!\n        "
                        ),
                      ]),
                ],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }