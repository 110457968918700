var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.app.user.loggedin
    ? _c(
        "span",
        {
          staticClass: "report-button",
          class: { hidden: _vm.hidden },
          attrs: { title: "Edit " + _vm.comment.type },
          on: { click: _vm.showEditCommentModal },
        },
        [_vm._m(0)]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fas fa-pen" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }