var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "navigation-list" },
    _vm._l(_vm.items, function (item) {
      return _vm.auth(item)
        ? _c(
            "li",
            {
              key: item.title,
              class: _vm.getListItemClass(item),
              on: {
                click: function ($event) {
                  return _vm.$emit("menuClicked")
                },
              },
            },
            [
              _vm.getAction(item) == "to"
                ? _c(
                    "span",
                    { class: _vm.getLinkClass(item) },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "navigation-list__item",
                          attrs: { to: item.to },
                        },
                        [
                          item.icon
                            ? _c("img", {
                                attrs: {
                                  src: `/img/${item.icon}.png`,
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n                " +
                              _vm._s(item.title) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _c(
                    "span",
                    { class: _vm.getLinkClass(item) },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "navigation-list__item",
                          attrs: { to: "#" },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.$modal.show(item.modal)
                            },
                          },
                        },
                        [
                          item.icon
                            ? _c("img", {
                                attrs: {
                                  src: `/img/${item.icon}.png`,
                                  alt: "",
                                },
                              })
                            : _vm._e(),
                          _vm._v(
                            "\n                " +
                              _vm._s(item.title) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
            ]
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }