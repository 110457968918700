var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "upload-image",
      style:
        "background-image: url(" +
        _vm.previewUrl +
        "); width: " +
        _vm.displaySize +
        "px; height: " +
        _vm.displaySize +
        "px;",
    },
    [
      _c("input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "size:5012",
            expression: "'size:5012'",
          },
        ],
        attrs: { type: "file", accept: "image/*", name: "image" },
        on: { change: _vm.previewImage },
      }),
      _vm._v(" "),
      !_vm.previewUrl
        ? _c("span", { staticClass: "upload-plus" }, [
            _c("i", {
              staticClass: "fa fa-plus-circle",
              attrs: { "data-fa-transform": "grow-40" },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.previewUrl
        ? _c("div", { staticClass: "upload-image-inner" }, [
            _vm._v("\n        Upload Image\n        "),
            _c("div", { staticClass: "image-dimensions" }, [
              _vm._v(
                "\n            Minimum size " +
                  _vm._s(_vm.minWidth) +
                  "px " +
                  _vm._s(_vm.minHeight) +
                  "px\n        "
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c("span", { staticClass: "error-msg" }, [
                _vm._v(_vm._s(_vm.errors.first("image"))),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }