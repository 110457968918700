var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      class: "masonry-item " + _vm.item.component,
      attrs: { to: _vm.getRouterObject(_vm.item) },
    },
    [
      _c("img", {
        staticClass: "masonry-image masonry-image-release",
        attrs: { src: _vm.imgUrl, alt: _vm.item.title },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "masonry-inner masonry-inner-fixed" }, [
        _c("h4", [_vm._v("Video")]),
        _vm._v(" "),
        _c("h2", [_vm._v(_vm._s(_vm.item.title))]),
      ]),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "p-player-control fa-layers fa-fw player-vertical" },
        [
          _c("span", [
            _c("i", {
              staticClass: "fa fa-fw fa-circle",
              staticStyle: { color: "#ff0000" },
              attrs: { "data-fa-transform": "grow-30" },
            }),
          ]),
          _vm._v(" "),
          _c("span", [
            _c("i", {
              staticClass: "fa fw-fw fa-play",
              staticStyle: { color: "white" },
              attrs: { "data-fa-transform": "shrink-4" },
            }),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }