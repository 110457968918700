var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "discover" },
    [
      _c(
        "filter-container",
        [
          _c(
            "genre-filter",
            {
              model: {
                value: _vm.filters.genres,
                callback: function ($$v) {
                  _vm.$set(_vm.filters, "genres", $$v)
                },
                expression: "filters.genres",
              },
            },
            [
              _c("div", { staticClass: "section-wrapper" }, [
                _c("h4", { staticClass: "section-number" }, [_vm._v("1")]),
                _vm._v(" "),
                _c("h3", { staticClass: "section-title" }, [
                  _vm._v("Choose your interest"),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "class-filter",
            {
              attrs: { single: true },
              model: {
                value: _vm.filters.classes,
                callback: function ($$v) {
                  _vm.$set(_vm.filters, "classes", $$v)
                },
                expression: "filters.classes",
              },
            },
            [
              _c("div", { staticClass: "section-wrapper" }, [
                _c("h4", { staticClass: "section-number" }, [_vm._v("2")]),
                _vm._v(" "),
                _c("h3", { staticClass: "section-title" }, [
                  _vm._v("Select a Class"),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "filter-filter",
            {
              attrs: { single: true },
              model: {
                value: _vm.filters.filter,
                callback: function ($$v) {
                  _vm.$set(_vm.filters, "filter", $$v)
                },
                expression: "filters.filter",
              },
            },
            [
              _c("div", { staticClass: "section-wrapper" }, [
                _c("h4", { staticClass: "section-number" }, [_vm._v("3")]),
                _vm._v(" "),
                _c("h3", { staticClass: "section-title" }, [
                  _vm._v("Select a Filter"),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "bpm-filter" }, [
            _c("h3", [_vm._v("BPM")]),
            _vm._v(" "),
            _c("p", { staticClass: "bpm-values" }, [
              _vm._v(_vm._s(_vm.sliderValue[0] + " > " + _vm.sliderValue[1])),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { style: _vm.noFiltersSelected ? "opacity:0.5" : "opacity:1;" },
              [
                _c("vue-slider", {
                  attrs: {
                    min: 0,
                    max: 200,
                    "enable-cross": false,
                    lazy: true,
                  },
                  on: { change: _vm.getFilteredResults },
                  model: {
                    value: _vm.sliderValue,
                    callback: function ($$v) {
                      _vm.sliderValue = $$v
                    },
                    expression: "sliderValue",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "discover-results" }, [
        _vm.noFiltersSelected
          ? _c(
              "div",
              {
                staticClass: "discover-start",
                staticStyle: { "margin-top": "32px" },
              },
              [
                _c("div", [
                  _vm._v("Select some of the filters to discover new music"),
                ]),
              ]
            )
          : _vm.results &&
            (_vm.filters.classes.length ||
              _vm.filters.genres.length ||
              _vm.filters.filter.length ||
              _vm.filters.bpm.length)
          ? _c("div", [
              _c("div", { staticClass: "discover-section" }, [
                _c("h2", { staticStyle: { "margin-top": "0" } }, [
                  _vm._v("Results"),
                ]),
                _vm._v(" "),
                _vm.results.length === 0 ? _c("p") : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "discover-row" },
                  _vm._l(_vm.results, function (album, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "discover-result" },
                      [
                        _c("release-tile", {
                          attrs: { release: album, size: 150 },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _vm.nextPageUrl
                ? _c(
                    "div",
                    {
                      staticClass: "centered-text",
                      staticStyle: { margin: "0 0 4em 0" },
                    },
                    [
                      _c(
                        "ph-button",
                        {
                          attrs: {
                            size: "medium",
                            color: "blue2",
                            loading: _vm.loadingNextPage,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.loadNextPage.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("Load More")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _c(
              "div",
              [
                _c("spinner", {
                  staticStyle: { margin: "3em auto" },
                  attrs: {
                    "animation-duration": 1000,
                    size: 60,
                    color: "black",
                  },
                }),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }