var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("home-hero"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-content-padded", staticStyle: { height: "auto" } },
        [
          _c(
            "div",
            { staticClass: "page-main" },
            [
              _vm.featuredItems.length
                ? _c("home-featured", {
                    attrs: { "featured-items": _vm.featuredItems },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("h1", [_vm._v("Latest News")]),
                  _vm._v(" "),
                  _c("home-latest-news", {
                    attrs: { "latest-news": _vm.news.articles.slice(0, 6) },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("h1", [_vm._v("Charts")]),
                  _vm._v(" "),
                  _c("home-charts", {
                    attrs: { "chart-items": _vm.chartItems },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("h1", [_vm._v("Latest Releases")]),
                  _vm._v(" "),
                  _c("home-latest-releases", {
                    attrs: { "latest-releases": _vm.app.releases.slice(0, 3) },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.app.user.loggedin
                ? _c(
                    "div",
                    { staticClass: "home-banner" },
                    [
                      _c("overlay"),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "hero-content" },
                        [
                          _c("h1", [_vm._v("Ready to join phase? It's free!")]),
                          _vm._v(" "),
                          _c(
                            "ph-button",
                            {
                              attrs: { color: "white", size: "giant" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.showAuthModal()
                                },
                              },
                            },
                            [
                              _c("h2", [_vm._v("Register")]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n              Find Out More\n            "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "aside",
            { staticClass: "sidebar-right" },
            [
              _c("sidebar-group", {
                attrs: { title: "News", items: _vm.news.articles.slice(0, 5) },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.app.user.loggedin
        ? _c("div", { staticClass: "home-banner-push" })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }