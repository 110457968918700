var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "avatar",
      class: { center: _vm.center },
      style: "width:" + _vm.size + "px;height:" + _vm.size + "px;",
    },
    [
      _vm.labels.tl
        ? _c(
            "ph-label",
            {
              staticClass: "tl",
              attrs: { size: "small", color: _vm.labels.tl.color },
            },
            [_vm._v("\n    " + _vm._s(_vm.labels.tl.text) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("img", {
        class: { tile: _vm.tile },
        attrs: { src: _vm.src, alt: _vm.alt },
      }),
      _vm._v(" "),
      _vm.labels.tr && _vm.labels.tr.text != "Verified"
        ? _c(
            "ph-label",
            {
              staticClass: "tr",
              attrs: { size: "small", color: _vm.labels.tr.color },
            },
            [_vm._v("\n    " + _vm._s(_vm.labels.tr.text) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.verified
        ? _c(
            "label",
            { staticClass: "tr-verified", attrs: { size: "small" } },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.recent
        ? _c("div", { staticClass: "is-new-badge" }, [_vm._v("\n    new\n  ")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "verified-tick" }, [
      _c("img", {
        staticStyle: { height: "25px", width: "25px" },
        attrs: { src: "/img/tick.jpg" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }