var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "share" },
    [
      _c("div", { staticClass: "share-message" }, [
        _vm._v("\n        " + _vm._s(_vm.share.message) + "\n    "),
      ]),
      _vm._v(" "),
      _c("item", { attrs: { item: _vm.share.shareable } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }