var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded" }, [
    _c(
      "div",
      { staticClass: "page-main" },
      [
        _vm.order
          ? _c(
              "div",
              [
                _c("p", { staticStyle: { "margin-bottom": "20px" } }, [
                  _vm._v(
                    "Your order for the following featured dates for the release " +
                      _vm._s(_vm.order.featured_dates[0].release.name) +
                      "\n        failed"
                  ),
                ]),
                _vm._v(" "),
                _vm._l(_vm.order.featured_dates, function (date, index) {
                  return _c("ul", { key: index }, [
                    _c("li", [
                      _vm._v(
                        _vm._s(
                          _vm.moment(date.featured_date).format("Do MMMM YYYY")
                        )
                      ),
                    ]),
                  ])
                }),
                _vm._v(" "),
                _c("p", { staticStyle: { "margin-top": "20px" } }, [
                  _vm._v(
                    "Please make payment for " + _vm._s(_vm.calculateTotal)
                  ),
                ]),
                _vm._v(" "),
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "ph-button",
                  {
                    attrs: { loading: _vm.loading },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.pay.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Pay")]
                ),
              ],
              2
            )
          : _c("spinner", {
              staticStyle: { margin: "3em auto" },
              attrs: { "animation-duration": 1000, size: 80, color: "black" },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticStyle: { width: "350px", "margin-top": "50px" } },
      [
        _c("div", {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { id: "card-element" },
        }),
        _vm._v(" "),
        _c("div", { attrs: { id: "card-errors", role: "alert" } }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }