var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    this.$store.state.app.account.business_type == "individual"
      ? _c("h1", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Verify your\n\t\tpersonal details"),
        ])
      : _vm._e(),
    _vm._v(" "),
    this.$store.state.app.account.business_type == "company" ||
    this.$store.state.app.account.business_type == "non_profit"
      ? _c("h1", { staticStyle: { "text-align": "center" } }, [
          _vm._v("\n\t\tTell us more about your business"),
        ])
      : _vm._e(),
    _vm._v(" "),
    this.$store.state.app.account.business_type == "individual"
      ? _c(
          "form",
          {
            attrs: { id: "account-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.handlePersonalSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("p", { staticStyle: { "text-align": "center" } }, [
              _vm._v(
                "Phase collects this information to verify your identity and keep your account\n\t\t\tsafe"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "input" }, [
                _c("div", [_vm._v("DOB:")]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "flex dob-fields",
                    staticStyle: { "flex-direction": "column" },
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.individual.dob.day,
                          expression: "individual.dob.day",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|between:1,31",
                          expression: "'required|between:1,31'",
                        },
                      ],
                      attrs: {
                        name: "day",
                        id: "day",
                        type: "text",
                        placeholder: "Day",
                        "data-vv-validate-on": "blur",
                      },
                      domProps: { value: _vm.individual.dob.day },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.individual.dob,
                            "day",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.individual.dob.month,
                          expression: "individual.dob.month",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|between:1,12",
                          expression: "'required|between:1,12'",
                        },
                      ],
                      attrs: {
                        name: "month",
                        id: "month",
                        type: "text",
                        placeholder: "Month",
                        "data-vv-validate-on": "blur",
                      },
                      domProps: { value: _vm.individual.dob.month },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.individual.dob,
                            "month",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.individual.dob.year,
                          expression: "individual.dob.year",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|min_value:1900|max_value:2020",
                          expression:
                            "'required|min_value:1900|max_value:2020'",
                        },
                      ],
                      attrs: {
                        name: "year",
                        id: "year",
                        type: "text",
                        maxlength: "4",
                        placeholder: "Year",
                        "data-vv-validate-on": "blur",
                      },
                      domProps: { value: _vm.individual.dob.year },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.individual.dob,
                            "year",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "error-message flex" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(
                            _vm.errors.first("day") ||
                              _vm.errors.first("month") ||
                              _vm.errors.first("year")
                          ) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "input" }, [
                _c("div", [_vm._v("Address:")]),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.individual.address.line1,
                        expression: "individual.address.line1",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticStyle: {
                      "margin-top": "8px",
                      "margin-bottom": "8px",
                    },
                    attrs: {
                      type: "text",
                      name: "line1",
                      placeholder: "Line 1",
                      "data-vv-validate-on": "blur",
                    },
                    domProps: { value: _vm.individual.address.line1 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.individual.address,
                          "line1",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.errors.first("line1")) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.individual.address.line2,
                        expression: "individual.address.line2",
                      },
                    ],
                    staticStyle: {
                      "margin-top": "8px",
                      "margin-bottom": "8px",
                    },
                    attrs: {
                      type: "text",
                      name: "line2",
                      placeholder: "Line 2",
                      "data-vv-validate-on": "blur",
                    },
                    domProps: { value: _vm.individual.address.line2 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.individual.address,
                          "line2",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.individual.address.city,
                        expression: "individual.address.city",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticStyle: {
                      "margin-top": "8px",
                      "margin-bottom": "8px",
                    },
                    attrs: {
                      type: "text",
                      name: "city",
                      placeholder: "City",
                      "data-vv-validate-on": "blur",
                    },
                    domProps: { value: _vm.individual.address.city },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.individual.address,
                          "city",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.errors.first("city"))),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.individual.address.postal_code,
                        expression: "individual.address.postal_code",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticStyle: {
                      "margin-top": "8px",
                      "margin-bottom": "8px",
                    },
                    attrs: {
                      type: "text",
                      name: "postal_code",
                      placeholder: "Post Code",
                      "data-vv-validate-on": "blur",
                      "data-vv-as": "postal code",
                    },
                    domProps: { value: _vm.individual.address.postal_code },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.individual.address,
                          "postal_code",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.errors.first("postal_code"))),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "input" }, [
                _c("div", [_vm._v("Identity Document:")]),
                _vm._v(" "),
                !_vm.identity_document
                  ? _c(
                      "div",
                      [
                        _c(
                          "ph-button",
                          {
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.selectFile.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Choose File")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "ext:jpeg,jpg,doc,docx",
                              expression: "'ext:jpeg,jpg,doc,docx'",
                            },
                          ],
                          key: 1,
                          ref: "fileInput",
                          staticStyle: { display: "none" },
                          attrs: {
                            type: "file",
                            name: "identity_document",
                            accept: ".pdf, .jpeg, .jpg, .doc, .docx",
                            "data-vv-validate-on": "blur",
                            "data-vv-as": "identity document",
                          },
                          on: {
                            change: [
                              function ($event) {
                                return _vm.setFile($event)
                              },
                              _vm.handleIdentityDocumentChange,
                            ],
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "selected-file__left",
                            staticStyle: { display: "flex" },
                          },
                          [
                            _vm._m(0),
                            _vm._v("\n\t\t\t\t\t\t \n\t\t\t\t\t\t"),
                            _c("p", { staticClass: "selected-file__name" }, [
                              _vm._v(_vm._s(_vm.identity_document.name)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { on: { click: _vm.removeFile } }, [
                          _c("i", { staticClass: "fas fa-times" }),
                        ]),
                      ]
                    ),
                _vm._v(" "),
                _c("span", { staticClass: "error-message" }, [
                  _vm._v(_vm._s(_vm.errors.first("identity_document"))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "submit-buttons" }, [
              _c(
                "div",
                { staticClass: "button-wrap" },
                [
                  _c(
                    "ph-button",
                    { attrs: { size: "medium", loading: _vm.submitting } },
                    [_vm._v("\n\t\t\t\t\tSave & Continue\n\t\t\t\t")]
                  ),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    this.$store.state.app.account.business_type == "company" ||
    this.$store.state.app.account.business_type == "non_profit"
      ? _c(
          "form",
          {
            attrs: { id: "account-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.handleSubmit.apply(null, arguments)
              },
            },
          },
          [
            _c("p", { staticStyle: { "text-align": "center" } }, [
              _vm._v(
                "Phase collects this information to better\n\t\t\tserve your business and help meet the requirements of regulators, financial partners, and our service\n\t\t\tagreements"
              ),
            ]),
            _vm._v(" "),
            this.$store.state.app.account.business_structure !=
            "unincorporated_partnership"
              ? _c("div", { staticClass: "flex" }, [
                  _c("div", { staticClass: "input" }, [
                    _c("div", [
                      _vm._v("Companies House Registration Number (CRN):"),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.company.crn,
                            expression: "company.crn",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: { required: true, max: 8, min: 7 },
                            expression: "{ required: true, max: 8, min: 7 }",
                          },
                        ],
                        attrs: {
                          type: "text",
                          name: "crn",
                          placeholder: "1234567",
                          "data-vv-validate-on": "blur",
                        },
                        domProps: { value: _vm.company.crn },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.company, "crn", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "error-message" }, [
                        _vm._v(_vm._s(_vm.errors.first("crn"))),
                      ]),
                      _vm._v(" "),
                      _c("small", [
                        _vm._v(
                          "We only need your 7 or 8 digit company number."
                        ),
                      ]),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "input" }, [
                _c("div", [_vm._v("Business Phone Number:")]),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.company.business_number,
                        expression: "company.business_number",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: { required: true, regex: /((\+?))\d{10,14}/ },
                        expression:
                          "{ required: true, regex: /((\\+?))\\d{10,14}/ }",
                      },
                    ],
                    attrs: {
                      type: "text",
                      name: "phone",
                      placeholder: "+441234567890",
                      "data-vv-validate-on": "blur",
                    },
                    domProps: { value: _vm.company.business_number },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.company,
                          "business_number",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.errors.first("phone"))),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "input" }, [
                _c("div", { staticStyle: { "padding-bottom": "56px" } }, [
                  _vm._v("Registered Business Address:"),
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.company.address.line1,
                        expression: "company.address.line1",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticStyle: {
                      "margin-top": "8px",
                      "margin-bottom": "8px",
                    },
                    attrs: {
                      type: "text",
                      name: "line1",
                      placeholder: "Line 1",
                      "data-vv-validate-on": "blur",
                    },
                    domProps: { value: _vm.company.address.line1 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.company.address,
                          "line1",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t" +
                        _vm._s(_vm.errors.first("line1")) +
                        "\n\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.company.address.line2,
                        expression: "company.address.line2",
                      },
                    ],
                    staticStyle: {
                      "margin-top": "8px",
                      "margin-bottom": "8px",
                    },
                    attrs: {
                      type: "text",
                      name: "line2",
                      placeholder: "Line 2",
                      "data-vv-validate-on": "blur",
                    },
                    domProps: { value: _vm.company.address.line2 },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.company.address,
                          "line2",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.company.address.city,
                        expression: "company.address.city",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticStyle: {
                      "margin-top": "8px",
                      "margin-bottom": "8px",
                    },
                    attrs: {
                      type: "text",
                      name: "city",
                      placeholder: "City",
                      "data-vv-validate-on": "blur",
                    },
                    domProps: { value: _vm.company.address.city },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.company.address,
                          "city",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.errors.first("city"))),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.company.address.postal_code,
                        expression: "company.address.postal_code",
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    staticStyle: {
                      "margin-top": "8px",
                      "margin-bottom": "8px",
                    },
                    attrs: {
                      type: "text",
                      name: "postal_code",
                      placeholder: "Post Code",
                      "data-vv-validate-on": "blur",
                      "data-vv-as": "postal code",
                    },
                    domProps: { value: _vm.company.address.postal_code },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.company.address,
                          "postal_code",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "error-message" }, [
                    _vm._v(_vm._s(_vm.errors.first("postal_code"))),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "This address must match the address filed with the UK tax authority."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("small", [
                    _vm._v(
                      "You can use your home address if you don't have a business address."
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "flex" }, [
              _c("div", { staticClass: "input" }, [
                _c("div", [_vm._v("Identity Document:")]),
                _vm._v(" "),
                !_vm.identity_document
                  ? _c(
                      "div",
                      [
                        _c(
                          "ph-button",
                          {
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.selectFile.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Choose File")]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "ext:jpeg,jpg,doc,docx",
                              expression: "'ext:jpeg,jpg,doc,docx'",
                            },
                          ],
                          key: 1,
                          ref: "fileInput",
                          staticStyle: { display: "none" },
                          attrs: {
                            type: "file",
                            name: "identity_document",
                            accept: ".pdf, .jpeg, .jpg, .doc, .docx",
                            "data-vv-validate-on": "blur",
                            "data-vv-as": "identity document",
                          },
                          on: {
                            change: [
                              function ($event) {
                                return _vm.setFile($event)
                              },
                              _vm.handleIdentityDocumentChange,
                            ],
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "selected-file__left",
                            staticStyle: { display: "flex" },
                          },
                          [
                            _vm._m(1),
                            _vm._v("\n\t\t\t\t\t\t \n\t\t\t\t\t\t"),
                            _c("p", { staticClass: "selected-file__name" }, [
                              _vm._v(_vm._s(_vm.identity_document.name)),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { on: { click: _vm.removeFile } }, [
                          _c("i", { staticClass: "fas fa-times" }),
                        ]),
                      ]
                    ),
                _vm._v(" "),
                _c("span", { staticClass: "error-message" }, [
                  _vm._v(_vm._s(_vm.errors.first("identity_document"))),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "submit-buttons" }, [
              _c(
                "div",
                { staticClass: "button-wrap" },
                [
                  _c(
                    "ph-button",
                    { attrs: { size: "medium", loading: _vm.submitting } },
                    [_vm._v("\n\t\t\t\t\tContinue\n\t\t\t\t")]
                  ),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fas fa-file" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fas fa-file" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }