var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search-filters" }, [
    _c("div", { staticClass: "desktop-filters" }, [_vm._t("default")], 2),
    _vm._v(" "),
    _c("div", { staticClass: "mobile-filters" }, [
      _c("div", { staticClass: "filter-toggle" }, [
        _c(
          "a",
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.menuOpen = !_vm.menuOpen
              },
            },
          },
          [
            !_vm.menuOpen
              ? _c("span", [_vm._v("Show Filters")])
              : _c("span", [_vm._v("Hide Filters")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.menuOpen
        ? _c(
            "div",
            { staticClass: "mobile-filters-wrap" },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }