var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("avatar-track", {
        attrs: {
          fullWidth: true,
          tile: true,
          src: _vm.track.release.image.files.medium.url,
          track: _vm.track,
          recent: _vm.track.is_recent,
        },
      }),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "track-tile",
          attrs: { to: _vm.getRouterObject(_vm.track) },
        },
        [
          _c("div", { staticClass: "track-tile-text" }, [
            _vm._v("\n            " + _vm._s(_vm.track.name) + "\n        "),
          ]),
          _vm._v(" "),
          _c("small", { staticClass: "track-artist-name" }, [
            _vm._v(_vm._s(_vm.track.artist.name)),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }