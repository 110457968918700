var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("avatar-track", {
        attrs: {
          fullWidth: true,
          tile: true,
          src: _vm.release.image.files.medium.url,
          track: _vm.release.tracks[0],
          recent: _vm.release.is_recent,
        },
      }),
      _vm._v(" "),
      _vm.mode === "charts"
        ? _c("div", { staticClass: "release-tile-chart-position" }, [
            _c("div", { staticClass: "position" }, [
              _vm._v("# " + _vm._s(_vm.chartPosition)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "line" }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "router-link",
        {
          staticClass: "release-tile",
          attrs: { to: _vm.getRouterObject(_vm.release) },
        },
        [
          _c("div", { staticClass: "release-tile-image" }),
          _vm._v(" "),
          _c("div", { staticClass: "release-tile-text" }, [
            _c("p", { staticClass: "release-name" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.release.name ? _vm.release.name : "release name") +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _vm.release.uploader
              ? _c("small", { staticClass: "mt-5 uploaded-by" }, [
                  _vm._v(_vm._s(_vm.release.uploader.name)),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }