import { render, staticRenderFns } from "./report.vue?vue&type=template&id=5b536042&scoped=true&"
import script from "./report.vue?vue&type=script&lang=js&"
export * from "./report.vue?vue&type=script&lang=js&"
import style0 from "./report.vue?vue&type=style&index=0&id=5b536042&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b536042",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.phase.uk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5b536042')) {
      api.createRecord('5b536042', component.options)
    } else {
      api.reload('5b536042', component.options)
    }
    module.hot.accept("./report.vue?vue&type=template&id=5b536042&scoped=true&", function () {
      api.rerender('5b536042', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/vue/components/modals/report.vue"
export default component.exports