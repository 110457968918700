var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.video.title &&
    _vm.video.asset &&
    _vm.video.asset.files.video_thumbnail
    ? _c("div", { staticClass: "video video-clear" }, [
        _vm.app.user && _vm.app.user.id === _vm.video.user_id
          ? _c(
              "div",
              {
                staticClass: "delete-video",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$modal.show("modal-delete-confirm", {
                      deleteable: _vm.video,
                    })
                  },
                },
              },
              [_c("i", { staticClass: "fa fa-trash" })]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "p-video-main" }, [
          _c("div", { staticClass: "p-video-text" }, [
            _c("div", { staticClass: "p-video-detail" }, [
              _c("h5", [_vm._v(_vm._s(_vm.video.title))]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.video.description))]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "video",
            {
              attrs: {
                controls: "",
                width: "100%",
                height: "300",
                id: "video" + _vm.video.id,
                poster: _vm.video.asset.files.video_thumbnail.url,
              },
            },
            [
              _c("source", {
                attrs: {
                  src: _vm.video.asset.files.original.url,
                  type: _vm.video.asset.files.original.mime,
                },
              }),
            ]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }