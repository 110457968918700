var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.app.user.loggedin
    ? _c(
        "span",
        {
          staticClass: "share-button",
          class: { shared: _vm.mutableShareable.is_shared },
          attrs: { title: "Share " + _vm.mutableShareable.type },
          on: { click: _vm.showShareModal },
        },
        [
          _c("span", [
            _c("i", { staticClass: "fa-solid fa-share-from-square" }),
            _vm._v(" "),
            _vm.showTitle ? _c("span", [_vm._v("Share")]) : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }