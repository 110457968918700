var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.latestReleases
        ? _c(
            "div",
            { staticClass: "home-latest-releases" },
            _vm._l(_vm.latestReleases, function (release, index) {
              return _c("router-link", {
                key: index,
                staticClass: "release",
                class: { primary: index === 0 },
                style:
                  "background-image: url(" +
                  release.image.files.original.url +
                  ")",
                attrs: { to: _vm.getRouterObject(release) },
              })
            }),
            1
          )
        : _c("spinner", {
            staticStyle: { margin: "3em auto" },
            attrs: { "animation-duration": 1000, size: 80, color: "black" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }