<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 153.2 40.6">
    <title>Phase Logo Full</title>
    <path
      :style="'fill:' + color + ';'"
      name="lines"
      d="M61.55,13.94a2.34,2.34,0,0,0-2.44,2.38v3.9H55V8.52a2.44,2.44,0,0,0-4.88,0V20.22H45.82V5.93a2.44,2.44,0,0,0-4.88,0V20.22H36.59V2.38a2.44,2.44,0,0,0-4.88,0V20.22H27.58V9.5a2.44,2.44,0,0,0-4.88,0V20.31h-4V6.44a2.34,2.34,0,0,0-2.44-2.38,2.34,2.34,0,0,0-2.44,2.38V20.22H9.5V13.37A2.34,2.34,0,0,0,7.06,11a2.34,2.34,0,0,0-2.44,2.38v6.85H0v7.2A2.34,2.34,0,0,0,2.44,29.8a2.34,2.34,0,0,0,2.44-2.38V20.76h4.4V34.35a2.34,2.34,0,0,0,2.44,2.38,2.34,2.34,0,0,0,2.44-2.38V20.57h4V31.38a2.34,2.34,0,0,0,2.44,2.38A2.34,2.34,0,0,0,23,31.38V20.57h4.12V38.22a2.44,2.44,0,0,0,4.88,0V20.57h4.31v14.1a2.44,2.44,0,0,0,4.88,0V20.57h4.28V32.28a2.44,2.44,0,0,0,4.88,0V20.57h4.22v3.9a2.44,2.44,0,0,0,4.88,0v-3.9H64V16.33A2.34,2.34,0,0,0,61.55,13.94Z"
    />
    <g class="phase" :style="'fill:' + color + ';'">
      <path
        name="p"
        d="M83.46,13.3a8.56,8.56,0,0,0-2.8-1.76,9.26,9.26,0,0,0-7.65.36,8.34,8.34,0,0,0-3.27,2.89,7.41,7.41,0,0,0-1.27,4.1l-.12,9.28c0,.09,0,.19,0,.29v.79l0,3.33h.05c.15,3,4.8,2.91,4.8-.14v-4a2.79,2.79,0,0,0,0-.33v-.55l0-1.23h0l.1-7.18s0-.07,0-.1,0-.1,0-.15a3.69,3.69,0,0,1,1.22-2.59,4,4,0,0,1,5.61.08,3.71,3.71,0,0,1,1.16,2.77,3.49,3.49,0,0,1-.58,1.91,4.07,4.07,0,0,1-.66.78,4.17,4.17,0,0,1-.84.6l-.28.13-5.74,3.75h0l.34.15.34.15a9.72,9.72,0,0,0,6.63.09,8.52,8.52,0,0,0,2.85-1.68,8,8,0,0,0,2-2.59,8,8,0,0,0,.1-6.51A8.27,8.27,0,0,0,83.46,13.3Z"
      />
      <path
        name="h"
        d="M95.92,11.14a5.7,5.7,0,0,0-2.1.38A4.85,4.85,0,0,0,92.1,12.6l0-2.15a2.74,2.74,0,0,0,0-.38V8.33l0-2.55h-.05c-.31-2.79-4.79-2.69-4.79.31V10l-.16,12.28a2.62,2.62,0,0,0-.06.56v4c0,3.1,4.81,3.1,4.81,0V25L92,18.16a2.93,2.93,0,0,1,.67-2.06,2.23,2.23,0,0,1,1.75-.66,2.27,2.27,0,0,1,1.76.71,3,3,0,0,1,.6,2.07l-.06,4.29a2.67,2.67,0,0,0,0,.31v4c0,3.1,4.81,3.1,4.81,0v-4s0-.08,0-.12l.06-4.74Q101.68,11.21,95.92,11.14Z"
      />
      <path
        name="a"
        d="M119.67,16.38a8.19,8.19,0,0,0-1.91-2.63A8.37,8.37,0,0,0,115,12a9.51,9.51,0,0,0-3.31-.64,9.21,9.21,0,0,0-3.33.57,8.38,8.38,0,0,0-2.84,1.65,8.13,8.13,0,0,0-2,2.58,7.91,7.91,0,0,0,.41,7.47,8.51,8.51,0,0,0,3.19,3,9.31,9.31,0,0,0,7.64.55c.15,0,.38-.14.69-.27a2.43,2.43,0,0,0,0,.26v0h0c.4,2.67,4.74,2.54,4.74-.4v-.17l.11-7A7.66,7.66,0,0,0,119.67,16.38Zm-10.14,6.49a3.73,3.73,0,0,1-.39-.26,4.21,4.21,0,0,1-.43-.37,3.67,3.67,0,0,1-1.16-2.75,3.57,3.57,0,0,1,.58-1.92,4.09,4.09,0,0,1,1.5-1.39,4.06,4.06,0,0,1,3.93.05A4.09,4.09,0,0,1,115,17.65a3.58,3.58,0,0,1,.53,1.94,3.76,3.76,0,0,1-.08.75v2.11a2.85,2.85,0,0,0,0,.36v4s0,.05,0,.08Z"
      />
      <path
        name="s"
        d="M131.21,18.6q-1.44-.36-2.86-.76t-1.28-1.25q0-1,1.76-1a4.29,4.29,0,0,1,1.15.17l.09,0a9.12,9.12,0,0,0,1.27.44,2.07,2.07,0,1,0,1.1-4,9.18,9.18,0,0,1-1.27-.44,1.27,1.27,0,0,0-.39-.09h0a7.66,7.66,0,0,0-2-.3,8.42,8.42,0,0,0-3.2.55,5.31,5.31,0,0,0-2.35,1.77,5,5,0,0,0-.92,3,4.72,4.72,0,0,0,1,3.42A5.66,5.66,0,0,0,126,21.74q2,.54,2.85.76,1.26.35,1.25,1.06,0,1.35-2.31,1.32a5.12,5.12,0,0,1-2.54-.68l0,0c-.24-.13-.47-.27-.7-.41a2.14,2.14,0,1,0-2.16,3.69,9,9,0,0,0,1.18.65l.51.22.31.06a8,8,0,0,0,1.27.37,13,13,0,0,0,2.52.26,8.23,8.23,0,0,0,3.24-.61,5.56,5.56,0,0,0,2.51-1.88,5,5,0,0,0,1-3,4.75,4.75,0,0,0-1-3.45A5.54,5.54,0,0,0,131.21,18.6Z"
      />
      <path
        name="e"
        d="M151.24,22.78a7.23,7.23,0,0,0-2.48,1,8.05,8.05,0,0,1-3.72,1,4.73,4.73,0,0,1-2.93-.83,4,4,0,0,1-1.35-2.09h9.77a3.44,3.44,0,0,0,2.09-.44,2,2,0,0,0,.59-1.61,7.12,7.12,0,0,0-2.37-5.4,8.39,8.39,0,0,0-5.92-2.19,9.18,9.18,0,0,0-6.49,2.33,7.86,7.86,0,0,0-2.52,6,7.88,7.88,0,0,0,2.52,6,9.18,9.18,0,0,0,6.49,2.33,12.4,12.4,0,0,0,5.62-1.16c1.59-.81,2.4-1.81,2.4-3a2.12,2.12,0,0,0-.45-1.39A1.54,1.54,0,0,0,151.24,22.78Zm-9.26-6a4.18,4.18,0,0,1,2.57-.73,4.41,4.41,0,0,1,2.7.77,3.13,3.13,0,0,1,1.16,1.73h-7.63A3.63,3.63,0,0,1,142,16.8Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "logo",
  props: ["color"],
};
</script>

<style lang="scss" scoped></style>
