var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.path == "profile_all"
        ? _c("all", { attrs: { user: _vm.user } })
        : _vm._e(),
      _vm._v(" "),
      _vm.path == "profile_events"
        ? _c("events", { attrs: { user: _vm.user } })
        : _vm._e(),
      _vm._v(" "),
      _vm.path == "profile_merch"
        ? _c("merch", { attrs: { user: _vm.user } })
        : _vm._e(),
      _vm._v(" "),
      _vm.path == "profile_music"
        ? _c("music", { attrs: { user: _vm.user } })
        : _vm._e(),
      _vm._v(" "),
      _vm.path == "profile_posts"
        ? _c("posts", {
            attrs: { user: _vm.user },
            on: {
              createLink: function ($event) {
                return _vm.pathToAll()
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.path == "profile_releases"
        ? _c("releases", { attrs: { user: _vm.user } })
        : _vm._e(),
      _vm._v(" "),
      _vm.path == "profile_videos"
        ? _c("videos", { attrs: { user: _vm.user } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }