var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-content-padded" },
    [
      _c(
        "overlay-notify",
        {
          attrs: {
            "is-visible": _vm.isOverlayVisible,
            duration:
              _vm.$route.query["email_verified"] === "1" ||
              _vm.$route.query["upgradation"] === "1"
                ? 6500
                : null,
          },
          on: { close: _vm.hideOverlay },
        },
        [
          _c(
            "div",
            { staticClass: "overlay-content" },
            [
              _c("logo", {
                staticClass: "overlay-logo",
                staticStyle: { width: "183px" },
                attrs: { color: "black" },
              }),
              _vm._v(" "),
              _vm.$route.query["email_verified"] === "1"
                ? _c(
                    "div",
                    [
                      _c("email-verified-svg"),
                      _vm._v(" "),
                      _c("h2", [_vm._v("Email Verified.")]),
                    ],
                    1
                  )
                : _vm.$route.query["upgradation"] === "1"
                ? _c("div", [
                    _c("h2", [
                      _vm._v(
                        "Your account upgradation request has been processed successfully. Thanks!"
                      ),
                    ]),
                  ])
                : _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticClass: "overLayClose",
                          on: { click: _vm.hideOverlay },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-times",
                            attrs: { "aria-hidden": "true" },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("email-not-verified-svg"),
                      _vm._v(" "),
                      _c("h2", [_vm._v("Registration complete")]),
                      _vm._v(" "),
                      _c("span", [_vm._v("Go to your email to verify")]),
                    ],
                    1
                  ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      !_vm.app.user.loggedin
        ? _c("div", { staticClass: "page-main" }, [
            _c("h1", { staticClass: "no-top" }, [_vm._v("Login")]),
            _vm._v(" "),
            _c(
              "form",
              {
                staticClass: "form-login",
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.formSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|email",
                      expression: "'required|email'",
                    },
                  ],
                  class: { error: _vm.errors.has("email") },
                  attrs: {
                    type: "email",
                    name: "email",
                    placeholder: "Email",
                    "data-vv-validate-on": "focusout",
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.email = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.has("email")
                  ? _c("span", { staticClass: "error-msg" }, [
                      _vm._v(_vm._s(_vm.errors.first("email"))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  class: { error: _vm.errors.has("password") },
                  attrs: {
                    type: "password",
                    name: "password",
                    placeholder: "Password",
                    "data-vv-validate-on": "focusout",
                  },
                  domProps: { value: _vm.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.password = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _vm.errors.has("password")
                  ? _c("span", { staticClass: "error-msg" }, [
                      _vm._v(_vm._s(_vm.errors.first("password"))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showMessage
                  ? _c(
                      "span",
                      { staticStyle: { color: "red", "font-size": "12px" } },
                      [_vm._v(_vm._s(_vm.showMessage))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("label", { staticStyle: { display: "block" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.remember,
                        expression: "remember",
                      },
                    ],
                    attrs: {
                      type: "checkbox",
                      name: "remember",
                      id: "remember",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.remember)
                        ? _vm._i(_vm.remember, null) > -1
                        : _vm.remember,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.remember,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.remember = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.remember = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.remember = $$c
                        }
                      },
                    },
                  }),
                  _vm._v("\n        Remember me\n      "),
                ]),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "remember-me",
                    staticStyle: {
                      display: "block",
                      cursor: "pointer",
                      "margin-top": "50px",
                      "margin-bottom": "20px",
                      "text-align": "center",
                    },
                    on: { click: _vm.showReset },
                  },
                  [_vm._v("\n        Forgot Password?\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "centered-text" },
                  [
                    _c(
                      "ph-button",
                      {
                        attrs: {
                          type: "submit",
                          size: "medium",
                          loading: _vm.loading,
                        },
                      },
                      [_vm._v(" Login ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }