<template>
    <modal name="modal-delete" width="500px" height="auto" scrollable adaptive>
        <div class="modal modal-delete">
            <div class="modal-header">
                <close-icon class="float-right" @click.native="$modal.hide('modal-delete')"></close-icon>
            </div>
            <div class="modal-content content-section">
                <h2>Delete Account</h2>
                <h3>
                    Are you sure you want to delete your account?
                </h3>

                <div class="save-button">
                    <ph-button size="large" @click.native="deleteAccount" :loading="submitting"
                        color="danger-outline">Delete</ph-button>
                </div>

                <p>Any music will be removed from sale and your account will be permanently deleted, this action is not
                    reversible.</p>
            </div>
        </div>
    </modal>
</template>

<script>
import { mapState } from 'vuex';
import CloseIcon from 'global/close-icon';
export default {
    data() {
        return {
            submitting: false,
            response: null,
        }
    },
    created: function () {

    },
    mounted: function () {

    },
    computed: {
        ...mapState([
            'app'
        ])
    },
    methods: {
        deleteAccount() {
            this.submitting = true;
            axios.get('/api/user/destroy/' + this.app.user.id).then(response => {
                window.location.href = '/';
            }).catch(() => {
                this.submitting = false;
            });
        }
    },
    components: {
        CloseIcon,
    }
}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

h2 {
    margin-bottom: 0;
}

p {
    margin: 1em 0;
}

textarea {
    box-sizing: border-box;
    border: 1px solid $color-grey2;
    padding: 5px;
    width: 100%;
    height: 50px;
}

.save-button {
    margin-top: 1em;
    text-align: center;
}

.error-msg {
    font-size: 70%;
    color: red;
}
</style>