var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "notification-dropdown" }, [
        _vm.notifications.length > 0
          ? _c(
              "div",
              {
                staticClass: "clear-all-text",
                on: {
                  click: function ($event) {
                    return _vm.$emit("clearall")
                  },
                },
              },
              [_vm._v("Clear all")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.notifications.length > 0
          ? _c(
              "ul",
              { staticClass: "notification-list" },
              _vm._l(_vm.notifications, function (notification, index) {
                return _c("li", [
                  _c("div", { staticClass: "notification" }, [
                    _c("div", { staticClass: "notification-detail" }, [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(notification?.data?.message),
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "notification-actions" }, [
                        _c(
                          "span",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$emit("dismiss", {
                                  notificationId: notification.id,
                                  index: index,
                                })
                              },
                            },
                          },
                          [_vm._v("Dismiss")]
                        ),
                        _vm._v(" "),
                        _c("a", { attrs: { href: notification?.data?.link } }, [
                          _c("i", {
                            staticClass:
                              "fa-solid fa-arrow-up-right-from-square",
                          }),
                        ]),
                      ]),
                    ]),
                  ]),
                ])
              }),
              0
            )
          : _c("div", [_vm._v("No notification!")]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }