var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "release", style: `background: ${_vm.backgroundColor}` },
      [
        _c("div", { staticClass: "release-info flex" }, [
          _c("div", { staticClass: "release-image" }, [
            _c("img", {
              attrs: {
                src: _vm.release.image.files.medium.url,
                alt: _vm.release.image.alt,
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "release-details" }, [
            _c("div", { staticClass: "flex justify-between" }, [
              _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "release",
                          params: { releaseid: _vm.release.slug },
                        },
                      },
                    },
                    [_c("h2", [_vm._v(_vm._s(_vm.release.name))])]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-sm" }, [
                    _vm._v(_vm._s(_vm.release.uploader.name)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "release-description flex text-xs" }, [
              _c(
                "div",
                { staticStyle: { width: "85%" } },
                [
                  _c("p", [_vm._v(_vm._s(_vm.release.description))]),
                  _vm._v(" "),
                  _c("social-sharing", {
                    attrs: {
                      url: "https://phase.test/",
                      title: `I have just purchased new music ${_vm.release.name} by ${_vm.release.uploader.name}`,
                      description: "",
                      quote: `I have just purchased new music ${_vm.release.name} by ${_vm.release.uploader.name}`,
                      hashtags: "phase,music",
                      "twitter-user": "phase",
                    },
                    inlineTemplate: {
                      render: function () {
                        var _vm = this,
                          _c = _vm._self._c
                        return _c(
                          "div",
                          { staticClass: "mt-4 social-share" },
                          [
                            _c("network", { attrs: { network: "facebook" } }, [
                              _c("i", { staticClass: "fab fa-facebook" }),
                            ]),
                            _vm._v(" "),
                            _c("network", { attrs: { network: "twitter" } }, [
                              _c("i", { staticClass: "fab fa-twitter" }),
                            ]),
                          ],
                          1
                        )
                      },
                      staticRenderFns: [],
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "tracks" },
      _vm._l(_vm.tracks, function (track, index) {
        return _c("div", { staticClass: "track flex" }, [
          _c("div", { staticClass: "flex-1" }, [
            _c("div", { staticClass: "track-image" }, [
              track.image
                ? _c("img", {
                    attrs: {
                      src: track.image.files.medium.url,
                      alt: track.image.alt,
                    },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(index + 1) +
                    " - " +
                    _vm._s(track.name) +
                    "\n                    "
                ),
                _c("play-pause-button", {
                  attrs: { track: track, type: "streamable" },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _vm.item[index].count < 5
              ? _c(
                  "a",
                  {
                    attrs: {
                      href: `/api/mymusic/download/mp3/${track.id}`,
                      title: "Download Track",
                    },
                  },
                  [
                    _c("ph-button", [
                      _c("i", { staticClass: "fas fa-download" }),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }