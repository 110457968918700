var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { position: "relative", display: "inline" } },
    [
      _c(
        _vm.tag,
        {
          ref: "component",
          tag: "component",
          staticClass: "button",
          class: [
            _vm.color,
            _vm.size,
            _vm.other,
            _vm.type,
            { active: _vm.active },
          ],
          attrs: {
            href: _vm.href ? _vm.href : null,
            to: _vm.to ? _vm.to : null,
            disabled: _vm.loading || _vm.disabled,
          },
        },
        [
          _vm.loading
            ? _c("spinner", {
                staticStyle: { margin: "0 auto" },
                attrs: {
                  "animation-duration": 1000,
                  size: 15,
                  color: _vm.variables.colors.colorBlue,
                },
              })
            : _vm._t("default"),
        ],
        2
      ),
      _vm._v(" "),
      _vm.$slots["tooltip"]
        ? _c("div", { staticClass: "tooltip" }, [_vm._t("tooltip")], 2)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }