import { render, staticRenderFns } from "./manage.vue?vue&type=template&id=126419e2&scoped=true&"
import script from "./manage.vue?vue&type=script&lang=js&"
export * from "./manage.vue?vue&type=script&lang=js&"
import style0 from "./manage.vue?vue&type=style&index=0&id=126419e2&lang=scss&scoped=true&"
import style1 from "./manage.vue?vue&type=style&index=1&id=126419e2&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "126419e2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.phase.uk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('126419e2')) {
      api.createRecord('126419e2', component.options)
    } else {
      api.reload('126419e2', component.options)
    }
    module.hot.accept("./manage.vue?vue&type=template&id=126419e2&scoped=true&", function () {
      api.rerender('126419e2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/vue/components/pages/account/account/manage.vue"
export default component.exports