var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded page-news-index" }, [
    _c("div", { staticClass: "page-main" }, [
      _c("h1", { staticClass: "no-top centered-text" }, [_vm._v("News")]),
      _vm._v(" "),
      _c("nav", { staticClass: "sub-nav" }, [
        _c(
          "ul",
          { staticClass: "centered-text" },
          [
            _c(
              "li",
              { class: { active: !_vm.category } },
              [_c("router-link", { attrs: { to: "/news" } }, [_vm._v("All")])],
              1
            ),
            _vm._v(" "),
            _vm._l(_vm.news.categories, function (categoryli) {
              return _c(
                "li",
                { class: { active: "/" + _vm.category === categoryli.path } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/news" + categoryli.path } },
                    [_vm._v(_vm._s(categoryli.title))]
                  ),
                ],
                1
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      !_vm.news.articles.length
        ? _c(
            "div",
            [
              _c("spinner", {
                staticStyle: { margin: "5em auto" },
                attrs: {
                  "animation-duration": 1000,
                  size: 100,
                  color: "black",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "articles" },
        _vm._l(_vm.news.articles, function (article) {
          return _c(
            "div",
            { staticClass: "article" },
            [_c("news-article", { attrs: { data: article, items: [] } })],
            1
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm.hasAnotherPage && _vm.news.articles.length
        ? _c(
            "div",
            { staticClass: "centered-text", staticStyle: { margin: "4em 0" } },
            [
              _c(
                "ph-button",
                {
                  attrs: { size: "large", loading: _vm.loadingNextPage },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.loadNextPage.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("\n        Show Me More\n      ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }