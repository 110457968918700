var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isPro && _vm.isShow
        ? _c(
            "ph-button",
            {
              staticStyle: { display: "block", "text-align": "center" },
              attrs: { size: "medium" },
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.show("modal-upload-video", {
                    user: _vm.user,
                  })
                },
              },
            },
            [_vm._v("Add Video")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loadingVideos,
            expression: "loadingVideos",
          },
        ],
        staticStyle: { margin: "3em auto" },
        attrs: { "animation-duration": 1000, size: 60, color: "black" },
      }),
      _vm._v(" "),
      _vm.videos.length
        ? _c(
            "div",
            _vm._l(_vm.videos, function (video) {
              return _c("item", { key: video.id, attrs: { item: video } })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.videos.length && !_vm.loadingVideos
        ? _c("div", { staticClass: "not-found" }, [
            _vm._v("\n    This user has not uploaded any videos yet.\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }