var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-upload-video",
        maxWidth: 600,
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
      on: { "before-close": _vm.maybeReset, "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal modal-upload-video" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.close.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content" }, [
          _c("h2", [_vm._v("Upload Video")]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    !_vm.resumable ||
                    (_vm.resumable &&
                      !_vm.resumable.isUploading() &&
                      !_vm.uploadComplete),
                  expression:
                    "!resumable || (resumable && !resumable.isUploading() && !uploadComplete)",
                },
              ],
            },
            [
              _c("h3", [
                _vm._v(
                  "First, browse for your video or drag it into the zone below:"
                ),
              ]),
              _vm._v(" "),
              _c("uploader", {
                on: {
                  "upload-start": _vm.uploadStart,
                  "upload-success": _vm.uploadSuccess,
                  "upload-cancel": _vm.uploadCancel,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.resumable
            ? _c(
                "div",
                { staticClass: "upload-details" },
                [
                  _c(
                    "h3",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.resumable.isUploading(),
                          expression: "resumable.isUploading()",
                        },
                      ],
                    },
                    [
                      _c("i", { staticClass: "fa fa-spinner fa-spin" }),
                      _vm._v(" Uploading "),
                      _c("em", [
                        _vm._v(_vm._s(_vm.resumable.files[0].fileName)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ph-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.resumable.isUploading(),
                          expression: "resumable.isUploading()",
                        },
                      ],
                      nativeOn: {
                        click: function ($event) {
                          return _vm.resumable.cancel()
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _vm._v(" "),
                  _c(
                    "ph-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.uploadComplete && !_vm.saved,
                          expression: "uploadComplete && !saved",
                        },
                      ],
                      nativeOn: {
                        click: function ($event) {
                          return _vm.resumable.cancel()
                        },
                      },
                    },
                    [_vm._v("Change Video")]
                  ),
                  _vm._v(" "),
                  _c(
                    "h3",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.uploadComplete,
                          expression: "uploadComplete",
                        },
                      ],
                    },
                    [
                      _c("i", { staticClass: "fa fa-check-circle" }),
                      _vm._v(" Upload Complete\n                "),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "upload-progress" }, [
                    _c("div", { staticClass: "progress-outer" }, [
                      _c(
                        "div",
                        {
                          staticClass: "progress-inner",
                          style:
                            "width: " + _vm.resumable.progress() * 100 + "%",
                        },
                        [
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.uploadComplete,
                                  expression: "uploadComplete",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(_vm.resumable.files[0].fileName))]
                          ),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.uploadComplete,
                            expression: "!uploadComplete",
                          },
                        ],
                        staticClass: "progress-digits",
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.uploadedSize) +
                            "MB / " +
                            _vm._s(_vm.fileSize) +
                            "MB (" +
                            _vm._s(Math.floor(_vm.resumable.progress() * 100)) +
                            "%)\n                    "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.resumable && !_vm.saved
            ? _c("form", [
                _c("h3", [
                  _vm._v("Now, provide some information about your video:"),
                ]),
                _vm._v(" "),
                _c("table", [
                  _c("tr", [
                    _c("td", [_vm._v("Title")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.details.title,
                            expression: "details.title",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|max:255",
                            expression: "'required|max:255'",
                          },
                        ],
                        attrs: {
                          type: "text",
                          name: "name",
                          placeholder: "Name",
                        },
                        domProps: { value: _vm.details.title },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.details, "title", $event.target.value)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "error-msg" }, [
                        _vm._v(_vm._s(_vm.errors.first("name"))),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td", [_vm._v("Description")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.details.description,
                            expression: "details.description",
                          },
                        ],
                        attrs: {
                          name: "description",
                          rows: "4",
                          placeholder: "Description",
                        },
                        domProps: { value: _vm.details.description },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.details,
                              "description",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td"),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _c(
                          "ph-button",
                          {
                            attrs: {
                              loading: _vm.saving,
                              disabled: _vm.videoModel === null,
                            },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.save.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Save")]
                        ),
                        _vm._v(" "),
                        _c(
                          "ph-button",
                          {
                            attrs: { disabled: _vm.saving },
                            nativeOn: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.close.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v("Cancel")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.resumable && _vm.saved && !_vm.resumable.isUploading()
            ? _c(
                "div",
                [
                  _c("ph-panel", { attrs: { type: "success" } }, [
                    _c("div", { staticClass: "header" }, [
                      _vm._v(
                        "\n                        Video Saved Successfully.\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "\n                        After your video is finished uploading and processing it will be available to view!\n                    "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("br"),
                    _vm._v(" "),
                    _c("p", [
                      _c(
                        "a",
                        {
                          staticClass: "button small",
                          on: {
                            click: function ($event) {
                              return _vm.resumable.cancel()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                             Click  Here\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" to upload new video\n                    "),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ph-button",
                    {
                      staticClass: "text-center",
                      attrs: { size: "large" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.close.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n                    Done\n                ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }