var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        attrs: { id: "account-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.handleForm.apply(null, arguments)
          },
        },
      },
      [
        _c("h1", [_vm._v("Verification")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "The following details are required to provide verification and a method of payment for sales"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "full-width" }, [
            _c("div", { staticClass: "full-width" }, [
              _c("div", { staticClass: "artist-input" }, [
                _c("div", [
                  _vm._v("\n\t\t\t\t\t\t\tArtist Type:\n\t\t\t\t\t\t"),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("artist-type-select", {
                      attrs: {
                        min: 2,
                        max: 4,
                        tabindex: "10",
                        disabled: _vm.submitting,
                        "data-vv-validate-on": "blur",
                      },
                      on: { change: _vm.artistTypesChanged },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'",
                        },
                      ],
                      ref: "artist_user_type_input",
                      attrs: {
                        type: "hidden",
                        name: "artist_user_type",
                        placeholder: "Artist Type",
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "error-message" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t\t\t" +
                          _vm._s(_vm.errors.first("artist_user_type")) +
                          "\n\t\t\t\t\t\t\t"
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.emptyArtistType,
                        expression: "emptyArtistType",
                      },
                    ],
                    staticClass: "error-message",
                    staticStyle: { "margin-top": "20px" },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t\tThe artist type is required\n\t\t\t\t\t\t"
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("First Name:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.account.individual.first_name,
                    expression: "account.individual.first_name",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:255",
                    expression: "'required|max:255'",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "first_name",
                  tabindex: "1",
                  "data-vv-as": "first name",
                },
                domProps: { value: _vm.account.individual.first_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.account.individual,
                      "first_name",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("first_name"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Last Name:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.account.individual.last_name,
                    expression: "account.individual.last_name",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:255",
                    expression: "'required|max:255'",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "last_name",
                  tabindex: "1",
                  "data-vv-as": "last name",
                },
                domProps: { value: _vm.account.individual.last_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.account.individual,
                      "last_name",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("last_name"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("DOB:")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex dob-fields",
                staticStyle: { "flex-direction": "column" },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account.individual.dob.day,
                      expression: "account.individual.dob.day",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|between:1,31",
                      expression: "'required|between:1,31'",
                    },
                  ],
                  attrs: {
                    name: "day",
                    id: "day",
                    type: "text",
                    placeholder: "Day",
                    "data-vv-validate-on": "blur",
                  },
                  domProps: { value: _vm.account.individual.dob.day },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.account.individual.dob,
                        "day",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account.individual.dob.month,
                      expression: "account.individual.dob.month",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|between:1,12",
                      expression: "'required|between:1,12'",
                    },
                  ],
                  attrs: {
                    name: "month",
                    id: "month",
                    type: "text",
                    placeholder: "Month",
                    "data-vv-validate-on": "blur",
                  },
                  domProps: { value: _vm.account.individual.dob.month },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.account.individual.dob,
                        "month",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account.individual.dob.year,
                      expression: "account.individual.dob.year",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min_value:1900|max_value:2020",
                      expression: "'required|min_value:1900|max_value:2020'",
                    },
                  ],
                  attrs: {
                    name: "year",
                    id: "year",
                    type: "text",
                    maxlength: "4",
                    placeholder: "Year",
                    "data-vv-validate-on": "blur",
                  },
                  domProps: { value: _vm.account.individual.dob.year },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.account.individual.dob,
                        "year",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error-message flex" }, [
                  _vm._v(
                    _vm._s(
                      _vm.errors.first("day") ||
                        _vm.errors.first("month") ||
                        _vm.errors.first("year")
                    )
                  ),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Email:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.account.individual.email,
                    expression: "account.individual.email",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email",
                    expression: "'required|email'",
                  },
                ],
                attrs: { type: "text", name: "email" },
                domProps: { value: _vm.account.individual.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.account.individual,
                      "email",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("email"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Phone:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.account.individual.phone,
                    expression: "account.individual.phone",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true, regex: /((\+?))\d{10,14}/ },
                    expression:
                      "{ required: true, regex: /((\\+?))\\d{10,14}/ }",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "phone",
                  placeholder: "+441234567890",
                  "data-vv-validate-on": "blur",
                },
                domProps: { value: _vm.account.individual.phone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.account.individual,
                      "phone",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("phone"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Address:")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account.individual.address.line1,
                      expression: "account.individual.address.line1",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticStyle: { "margin-top": "8px", "margin-bottom": "8px" },
                  attrs: {
                    type: "text",
                    name: "line1",
                    placeholder: "Line 1",
                    "data-vv-validate-on": "blur",
                  },
                  domProps: { value: _vm.account.individual.address.line1 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.account.individual.address,
                        "line1",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error-message" }, [
                  _vm._v(_vm._s(_vm.errors.first("line1"))),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account.individual.address.line2,
                      expression: "account.individual.address.line2",
                    },
                  ],
                  staticStyle: { "margin-top": "8px", "margin-bottom": "8px" },
                  attrs: {
                    type: "text",
                    name: "line2",
                    placeholder: "Line 2",
                    "data-vv-validate-on": "blur",
                  },
                  domProps: { value: _vm.account.individual.address.line2 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.account.individual.address,
                        "line2",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account.individual.address.city,
                      expression: "account.individual.address.city",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticStyle: { "margin-top": "8px", "margin-bottom": "8px" },
                  attrs: {
                    type: "text",
                    name: "city",
                    placeholder: "City",
                    "data-vv-validate-on": "blur",
                  },
                  domProps: { value: _vm.account.individual.address.city },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.account.individual.address,
                        "city",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error-message" }, [
                  _vm._v(_vm._s(_vm.errors.first("city"))),
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account.individual.address.state,
                      expression: "account.individual.address.state",
                    },
                  ],
                  staticStyle: { "margin-top": "8px", "margin-bottom": "8px" },
                  attrs: {
                    type: "text",
                    name: "state",
                    placeholder: "State",
                    "data-vv-validate-on": "blur",
                  },
                  domProps: { value: _vm.account.individual.address.state },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.account.individual.address,
                        "state",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.account.individual.address.postal_code,
                      expression: "account.individual.address.postal_code",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticStyle: { "margin-top": "8px", "margin-bottom": "8px" },
                  attrs: {
                    type: "text",
                    name: "postal_code",
                    placeholder: "Post Code",
                    "data-vv-validate-on": "blur",
                  },
                  domProps: {
                    value: _vm.account.individual.address.postal_code,
                  },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.account.individual.address,
                        "postal_code",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error-message" }, [
                  _vm._v(_vm._s(_vm.errors.first("postal_code"))),
                ]),
                _vm._v(" "),
                _c("country-select", {
                  staticStyle: { width: "100%", "margin-top": "8px" },
                  on: { change: _vm.artistCountryChanged },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error-message" }, [
                  _vm._v(_vm._s(_vm.errors.first("country"))),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { "flex-direction": "column" } },
          [
            _c("div", { staticClass: "input" }, [
              _c("div", [_vm._v("Accept Terms of Service:")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.account.tos_shown_and_accepted,
                    expression: "account.tos_shown_and_accepted",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: "terms",
                  type: "checkbox",
                  "data-vv-as": "Terms and Conditions",
                },
                domProps: {
                  checked: Array.isArray(_vm.account.tos_shown_and_accepted)
                    ? _vm._i(_vm.account.tos_shown_and_accepted, null) > -1
                    : _vm.account.tos_shown_and_accepted,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.account.tos_shown_and_accepted,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.account,
                            "tos_shown_and_accepted",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.account,
                            "tos_shown_and_accepted",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.account, "tos_shown_and_accepted", $$c)
                    }
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "error-message" }, [
              _vm._v(_vm._s(_vm.errors.first("terms"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.connectErrors
          ? _c("div", { staticClass: "error-message flex" }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.connectErrors) + "\n\t\t"),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "submit-buttons" }, [
          _c(
            "div",
            { staticClass: "button-wrap" },
            [
              _c(
                "ph-button",
                { attrs: { size: "large", loading: _vm.submitting } },
                [_vm._v("\n\t\t\t\t\tSubmit\n\t\t\t\t")]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }