var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "featured-dates-payment",
        adaptive: true,
        clickToClose: false,
        minHeight: 400,
        height: "auto",
        width: "100%",
        maxWidth: 660,
        scrollable: true,
      },
      on: { opened: _vm.setupPayment, "before-open": _vm.setupData },
    },
    [
      _c(
        "div",
        { staticClass: "modal modal-payment content-section" },
        [
          _c("h2", [_vm._v("Payment")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "You have selected the following dates to feature your release " +
                _vm._s(_vm.release.name)
            ),
          ]),
          _vm._v(" "),
          _vm._l(_vm.featuredDates, function (featuredDate, index) {
            return _c("span", { key: index }, [
              index > 0 ? _c("span", [_vm._v(" - ")]) : _vm._e(),
              _vm._v(" " + _vm._s(featuredDate) + "\n    "),
            ])
          }),
          _vm._v(" "),
          _c("p", { staticClass: "total" }, [
            _vm._v("Total: " + _vm._s(_vm.calculateTotal)),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v("Payment will only be taken if the release is approved."),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("h2", { staticClass: "payment-info-header" }, [
                _vm._v("Payment Information"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loadedInitialCardData,
                      expression: "loadedInitialCardData",
                    },
                  ],
                },
                [
                  _vm.userHasCard && !_vm.stripePayment
                    ? _c(
                        "div",
                        [
                          _c("existing-card-account", {
                            attrs: { card: _vm.card },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "card-details-info",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.showCardInput.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [_vm._v("Add new card")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.userHasCard || _vm.stripePayment,
                          expression: "!userHasCard || stripePayment",
                        },
                      ],
                    },
                    [
                      _c("div", {
                        staticStyle: { "margin-bottom": "20px" },
                        attrs: { id: "card-element" },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        attrs: { id: "card-errors", role: "alert" },
                      }),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loadedInitialCardData,
                      expression: "!loadedInitialCardData",
                    },
                  ],
                },
                [
                  _c("spinner", {
                    staticStyle: { margin: "3em auto" },
                    attrs: {
                      "animation-duration": 1000,
                      size: 60,
                      color: "black",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.loadedInitialCardData,
                      expression: "!loadedInitialCardData",
                    },
                  ],
                },
                [_c("i", { staticClass: "fa fa-spinner fa-spin" })]
              ),
              _vm._v(" "),
              _c(
                "ph-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.loadedInitialCardData,
                      expression: "loadedInitialCardData",
                    },
                  ],
                  attrs: {
                    id: "paymentButton",
                    loading: _vm.loading,
                    color: "primary-outline",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.pay.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Pay")]
              ),
              _vm._v(" "),
              _c(
                "ph-button",
                {
                  attrs: { color: "secondary-outline" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.cancel.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }