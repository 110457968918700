var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "track-list" }, [
    _vm.tracks.length
      ? _c(
          "table",
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.tracks, function (track) {
              return _c("tr", { key: track.id, staticClass: "data-row" }, [
                _c(
                  "td",
                  [
                    _c("play-pause-button", {
                      attrs: { track: track, size: 15, type: _vm.streamable },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.trackClicked($event, track)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(track.name) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.trackClicked($event, track)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm
                            .moment()
                            .startOf("day")
                            .seconds(track.length)
                            .format("mm:ss")
                        ) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.trackClicked($event, track)
                      },
                    },
                  },
                  [
                    track.format
                      ? _c("span", [_vm._v(_vm._s(track.format) + " ")])
                      : _vm._e(),
                    _vm._v(" "),
                    !track.format ? _c("span", [_vm._v("WAV, MP3")]) : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c("td", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$store.getters["app/getKeyByKey"](track.key).name
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.trackClicked($event, track)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.trackClicked($event, track)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(track.bpm) +
                        "\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.getUserLoggedIn
                  ? _c(
                      "td",
                      [_c("add-to-cart-button", { attrs: { product: track } })],
                      1
                    )
                  : _vm._e(),
              ])
            }),
          ],
          2
        )
      : _c("div", { staticClass: "no-tracks" }, [
          _vm._v(
            "\n        There are no available tracks for this release yet.\n    "
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", { staticClass: "header-row" }, [
      _c("th"),
      _vm._v(" "),
      _c("th", [_vm._v("Name")]),
      _vm._v(" "),
      _c("th", [_vm._v("Length")]),
      _vm._v(" "),
      _c("th", [_vm._v("Format")]),
      _vm._v(" "),
      _c("th", [_vm._v("Key")]),
      _vm._v(" "),
      _c("th", [_vm._v("BPM")]),
      _vm._v(" "),
      _c("th"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }