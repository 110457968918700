var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded" }, [
    _c("div", { staticClass: "page-main" }, [
      _c("div", { staticClass: "alert alert-success" }, [
        _vm._v(_vm._s(_vm.message) + "\n      "),
        !_vm.app.user.loggedin
          ? _c("span", [
              _vm._v("Please "),
              _c(
                "a",
                {
                  staticClass: "text-white",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.$modal.show("modal-auth-login")
                    },
                  },
                },
                [_vm._v("Login")]
              ),
              _vm._v(" to Access."),
            ])
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }