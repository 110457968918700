var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "feature-date" }, [
    _c(
      "div",
      { staticClass: "date" },
      [
        _c("v-date-picker", {
          ref: "featuredDates",
          attrs: {
            mode: "multiple",
            "available-dates": _vm.enabledDates,
            "input-props": _vm.inputProps,
            masks: { input: "DD/MM/YYYY" },
          },
          on: { input: _vm.updatePrice },
          model: {
            value: _vm.date,
            callback: function ($$v) {
              _vm.date = $$v
            },
            expression: "date",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("span", { staticClass: "price" }, [_vm._v("£" + _vm._s(_vm.price))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }