var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded" }, [
    _c("div", { staticClass: "page-main" }, [
      _c("h1", { staticClass: "no-top" }, [_vm._v("My Followed")]),
      _vm._v(" "),
      _vm.user
        ? _c(
            "div",
            _vm._l(_vm.user.followed, function (person) {
              return _c(
                "div",
                { key: person.id },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "sidebar-group-item",
                      attrs: { to: "/user/" + person.path },
                    },
                    [_c("user", { attrs: { user: person } })],
                    1
                  ),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }