var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "password-reset" }, [
    !_vm.loading && !_vm.loaded
      ? _c("div", [
          _c("h1", { staticStyle: { "text-align": "center" } }, [
            _vm._v("Reset password"),
          ]),
          _vm._v(" "),
          _c(
            "form",
            {
              attrs: { autocomplete: "false" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "form-element",
                attrs: {
                  type: "password",
                  name: "password",
                  id: "",
                  placeholder: "New password",
                  autocomplete: "false",
                },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password_confirmation,
                    expression: "password_confirmation",
                  },
                ],
                staticClass: "form-element",
                attrs: {
                  type: "password",
                  name: "password_confirmation",
                  id: "",
                  placeholder: "Confirm new password",
                  autocomplete: "false",
                },
                domProps: { value: _vm.password_confirmation },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password_confirmation = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "form-element", attrs: { type: "submit" } },
                [_vm._v("Submit")]
              ),
            ]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loading
      ? _c(
          "div",
          [
            _c("spinner", {
              staticStyle: { margin: "0 auto" },
              attrs: { "animation-duration": 1000, size: 75, color: "black" },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.loaded
      ? _c("div", [
          _c("p", { staticStyle: { "text-align": "center" } }, [
            _vm._v(
              "Your Password has been reset, you will be redirected shortly"
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "center",
                "margin-top": "50px",
              },
            },
            [
              _c("orbit-spinner", {
                attrs: {
                  "animation-duration": 1200,
                  size: 55,
                  color: "#3300ff",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }