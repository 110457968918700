var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "edit-cover" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "upload-detail" }, [
      _c(
        "table",
        { staticStyle: { width: "300px", "table-layout": "fixed" } },
        [
          _c("tbody", [
            _c("tr", [
              _c(
                "td",
                { staticClass: "test-image-dimension" },
                [
                  _c("image-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|min-dimensions:300,300",
                        expression: "'required|min-dimensions:300,300'",
                      },
                    ],
                    attrs: {
                      name: "image",
                      currentSelected: _vm.selectedImage,
                    },
                    model: {
                      value: _vm.cover.image,
                      callback: function ($$v) {
                        _vm.$set(_vm.cover, "image", $$v)
                      },
                      expression: "cover.image",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("td", [
                _c("span", { staticClass: "error-message" }, [
                  _vm._v(_vm._s(_vm.errors.first("image"))),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "upload-meta" }, [
        _c(
          "form",
          [
            _c(
              "table",
              { staticStyle: { "table-layout": "fixed" } },
              [
                _c("tr", [
                  _c("td", [_vm._v("Title*")]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cover.title,
                          expression: "cover.title",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:50",
                          expression: "'required|max:50'",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "title",
                        placeholder: "Release Name",
                      },
                      domProps: { value: _vm.cover.title },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.cover, "title", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "error-message" }, [
                      _vm._v(_vm._s(_vm.errors.first("title"))),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Genres*")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("genre-select", {
                        attrs: { populated: _vm.cover.genres },
                        on: { change: _vm.coverGenresChanged },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          type: "hidden",
                          name: "genre",
                          placeholder: "Genre",
                        },
                        domProps: { value: _vm.genreString },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "error-message" }, [
                        _vm._v(_vm._s(_vm.errors.first("genre"))),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticStyle: { "vertical-align": "top" } }, [
                    _vm._v("Description*"),
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.cover.description,
                          expression: "cover.description",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:255",
                          expression: "'required|max:255'",
                        },
                      ],
                      attrs: {
                        name: "description",
                        placeholder: "Maximum 255 characters",
                      },
                      domProps: { value: _vm.cover.description },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.cover,
                            "description",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "error-message" }, [
                      _vm._v(_vm._s(_vm.errors.first("description"))),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Class*")]),
                  _vm._v(" "),
                  _c("td", [
                    _c("div", { staticClass: "select-wrapper" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.cover.class,
                              expression: "cover.class",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: { name: "class" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.cover,
                                "class",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { disabled: "", value: "" } }, [
                            _vm._v("Select"),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.classes, function (v, k) {
                            return _c(
                              "option",
                              {
                                key: k,
                                attrs: { disabled: _vm.isDisabled(k) },
                                domProps: {
                                  value: k,
                                  selected: k === _vm.cover.class,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(v) +
                                    "\n                  "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm._m(1),
                    ]),
                    _vm._v(" "),
                    _c("span", { staticClass: "error-message" }, [
                      _vm._v(_vm._s(_vm.errors.first("class"))),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", { staticClass: "responsive-class" }, [
                  _c("td", [_vm._v("Release Date*")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "date-selects" },
                    [
                      _c("v-date-picker", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          "input-props": _vm.inputProps,
                          "min-date": new Date(),
                          name: "date",
                          masks: { input: "DD/MM/YYYY" },
                          popover: { placement: "top-start" },
                        },
                        model: {
                          value: _vm.cover.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.cover, "date", $$v)
                          },
                          expression: "cover.date",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "error-message" }, [
                        _vm._v(_vm._s(_vm.errors.first("date"))),
                      ]),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.isSingle
                  ? [
                      _c("tr", [
                        _c("td", [_vm._v("Format*")]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "form-td" },
                          [
                            _c("ph-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              key: 1,
                              attrs: {
                                name: "format",
                                title: "Format",
                                options: { mp3: "MP3", wav: "WAV" },
                              },
                              model: {
                                value: _vm.currentTrack.format,
                                callback: function ($$v) {
                                  _vm.$set(_vm.currentTrack, "format", $$v)
                                },
                                expression: "currentTrack.format",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "error-message" }, [
                              _vm._v(_vm._s(_vm.errors.first("format"))),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tr",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.currentTrack.format,
                              expression: "currentTrack.format",
                            },
                          ],
                          key: 1,
                        },
                        [
                          _c("td", [
                            _vm._v(
                              "Audio File* " +
                                _vm._s(`.${_vm.currentTrack.format}`)
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "td",
                            { staticClass: "form-td" },
                            [
                              !_vm.currentTrack.file
                                ? _c(
                                    "ph-button",
                                    {
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.selectFile.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Choose File")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              !_vm.currentTrack.file
                                ? _c("input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    key: 1,
                                    ref: "fileInput",
                                    staticStyle: { display: "none" },
                                    attrs: {
                                      type: "file",
                                      name: "file",
                                      accept: `.${_vm.currentTrack.format}`,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.setTrackFile(
                                          $event,
                                          _vm.currentTrack
                                        )
                                      },
                                    },
                                  })
                                : _c("div", { staticClass: "selected-file" }, [
                                    _c(
                                      "div",
                                      { staticClass: "selected-file__left" },
                                      [
                                        _vm._m(2),
                                        _vm._v(" "),
                                        _c(
                                          "p",
                                          {
                                            staticClass: "selected-file__name",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.currentTrack.file.name)
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { on: { click: _vm.removeFile } },
                                      [_c("i", { staticClass: "fas fa-times" })]
                                    ),
                                  ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "error-message" }, [
                                _vm._v(_vm._s(_vm.errors.first("file"))),
                              ]),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("Key*")]),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "form-td" },
                          [
                            _c("ph-select", {
                              directives: [
                                {
                                  name: "validate",
                                  rawName: "v-validate",
                                  value: "required",
                                  expression: "'required'",
                                },
                              ],
                              key: 1,
                              attrs: {
                                name: "key",
                                title: "Key",
                                options: {
                                  a: "A",
                                  "a+": "A#",
                                  b: "B",
                                  c: "C",
                                  "c+": "C#",
                                  d: "D",
                                  "d+": "D#",
                                  e: "E",
                                  f: "F",
                                  "f+": "F#",
                                  g: "G",
                                  "g+": "G#",
                                },
                              },
                              model: {
                                value: _vm.currentTrack.key,
                                callback: function ($$v) {
                                  _vm.$set(_vm.currentTrack, "key", $$v)
                                },
                                expression: "currentTrack.key",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "error-message" }, [
                              _vm._v(_vm._s(_vm.errors.first("key"))),
                            ]),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("td", [_vm._v("BPM*")]),
                        _vm._v(" "),
                        _c("td", { staticClass: "form-td" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.currentTrack.bpm,
                                expression: "currentTrack.bpm",
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: "required|between:40,250",
                                expression: "'required|between:40,250'",
                              },
                            ],
                            key: 1,
                            attrs: {
                              type: "number",
                              name: "bpm",
                              min: "40",
                              max: "250",
                            },
                            domProps: {
                              value: _vm.currentTrack.bpm,
                              value: _vm.currentTrack.bpm,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.currentTrack, "bpm", $event)
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.currentTrack,
                                  "bpm",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "error-message" }, [
                            _vm._v(_vm._s(_vm.errors.first("bpm"))),
                          ]),
                        ]),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c("td", { staticStyle: { "vertical-align": "middle" } }, [
                    _vm._v("Price*"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("price-range", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: {
                          min: _vm.isSingle
                            ? _vm.getSingleTrackPriceFrom()
                            : _vm.getAlbumPriceFrom(),
                          max: _vm.isSingle
                            ? _vm.getSingleTrackPriceTo()
                            : _vm.getAlbumPriceTo(),
                          step: 1,
                          value: _vm.cover.price,
                          name: "price",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.cover, "price", $event)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "error-message" }, [
                        _vm._v(_vm._s(_vm.errors.first("price"))),
                      ]),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
            _vm._v(" "),
            _c("div", { staticClass: "terms" }, [
              _c("label", [
                _c("table", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.cover.terms,
                            expression: "cover.terms",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        attrs: { type: "checkbox", name: "agree_terms" },
                        domProps: {
                          checked: Array.isArray(_vm.cover.terms)
                            ? _vm._i(_vm.cover.terms, null) > -1
                            : _vm.cover.terms,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.cover.terms,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.cover,
                                    "terms",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.cover,
                                    "terms",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.cover, "terms", $$c)
                            }
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      [
                        _vm._v(
                          "\n                  By sharing, you confirm that your release complies with our\n                  "
                        ),
                        _c(
                          "router-link",
                          { staticClass: "blue", attrs: { to: "/terms" } },
                          [_vm._v("Terms of use")]
                        ),
                        _vm._v(
                          "\n                  and you don’t infringe anyone else’s rights. If in doubt,\n                  check our\n                  "
                        ),
                        _c(
                          "router-link",
                          { staticClass: "blue", attrs: { to: "/copyright" } },
                          [_vm._v("Copyright")]
                        ),
                        _vm._v(
                          "\n                  information pages and\n                  "
                        ),
                        _c(
                          "router-link",
                          { staticClass: "blue", attrs: { to: "/faqs" } },
                          [_vm._v("FAQs")]
                        ),
                        _vm._v(
                          "\n                  before uploading.\n                  "
                        ),
                        _c("span", { staticClass: "error-message" }, [
                          _vm._v(_vm._s(_vm.errors.first("agree_terms"))),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "ph-button",
              {
                staticClass: "centered-inline submit-button",
                attrs: { size: "medium", color: "primary-outline" },
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.attemptSubmit.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.isSingle ? "Upload Release" : "Add Tracks") +
                    "\n        "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "upload-heading" }, [_c("div")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "select-controls" }, [
      _c("span", [_vm._v("|")]),
      _vm._v(" "),
      _c("i", { staticClass: "fa fa-caret-down" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [_c("i", { staticClass: "fas fa-file" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }