var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h1", [_vm._v("My Sales & Feedback for Track")]),
      _vm._v(" "),
      _c("account-menu", { attrs: { active: "stats" } }),
      _vm._v(" "),
      _c("chart", {
        attrs: {
          name: "Total Sales for Track Name",
          value: "total-sales-track-name",
        },
      }),
      _vm._v(" "),
      _c("chart", {
        attrs: {
          name: "Activity for Track Name",
          value: "activity-track-name",
        },
      }),
      _vm._v(" "),
      _c("h2", [_vm._v("Feedback for Track Name")]),
      _vm._v(" "),
      _c("comment", {
        attrs: {
          data: {
            user: _vm.user.user,
            created_at: "2018-04-30",
            body: "Cool song!",
          },
        },
      }),
      _vm._v(" "),
      _c("comment", {
        attrs: {
          data: {
            user: _vm.user.user,
            created_at: "2018-04-29",
            body: "Awesome!",
          },
        },
      }),
      _vm._v(" "),
      _c("comment", {
        attrs: {
          data: {
            user: _vm.user.user,
            created_at: "2018-04-25",
            body: "Nice track bro",
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }