var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticStyle: { "text-align": "center" } }, [
      _vm._v("Verification"),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { id: "account-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c("p", { staticStyle: { "text-align": "center" } }, [
          _vm._v(
            "The following details are required to provide verification and a method of payment for sales"
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Phone:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.phone,
                    expression: "phone",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true, regex: /((\+?))\d{10,14}/ },
                    expression:
                      "{ required: true, regex: /((\\+?))\\d{10,14}/ }",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "phone",
                  placeholder: "+441234567890",
                  "data-vv-validate-on": "blur",
                },
                domProps: { value: _vm.phone },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.phone = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("phone"))),
              ]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("small", [
                _vm._v("We'll text this number to verify your account."),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "submit-buttons" }, [
          _c(
            "div",
            { staticClass: "button-wrap" },
            [
              _c(
                "ph-button",
                { attrs: { size: "medium", loading: _vm.submitting } },
                [_vm._v("\n\t\t\t\t\tContinue\n\t\t\t\t")]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }