var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ph-panel", { attrs: { id: "notifications" } }, [
    _c("h2", [_vm._v("Notifications")]),
    _vm._v(" "),
    _vm.loaded
      ? _c(
          "div",
          [
            _c("div", { staticClass: "options-container" }, [
              _c("div", { staticClass: "options-column" }, [
                _c("div", { staticClass: "col-header" }, [
                  _c("div", { staticClass: "notify-heading" }, [
                    _c("span", [_vm._v("Activities")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-icons" }, [
                    _c("div", { staticClass: "notification-icon" }, [
                      _c("i", { staticClass: "fa fa-bell" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "notification-count" }, [
                        _vm._v("1"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: "/img/Email_notification_icon.png",
                        alt: "",
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("ul", [
                  _vm.app.user.roles[0].name == "pro" ||
                  _vm.app.user.roles[0].name == "artist" ||
                  _vm.app.user.roles[0].name == "admin"
                    ? _c("li", [
                        _c("label", { staticClass: "notify-title" }, [
                          _vm._v(
                            "\n                            Your release has been shared\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "notify-options" }, [
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.release_shared_notify,
                                  expression: "options.release_shared_notify",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.release_shared_notify
                                )
                                  ? _vm._i(
                                      _vm.options.release_shared_notify,
                                      null
                                    ) > -1
                                  : _vm.options.release_shared_notify,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.release_shared_notify,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_shared_notify",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_shared_notify",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "release_shared_notify",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.release_shared_email,
                                  expression: "options.release_shared_email",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.release_shared_email
                                )
                                  ? _vm._i(
                                      _vm.options.release_shared_email,
                                      null
                                    ) > -1
                                  : _vm.options.release_shared_email,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.release_shared_email,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_shared_email",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_shared_email",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "release_shared_email",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.app.user.roles[0].name == "pro" ||
                  _vm.app.user.roles[0].name == "artist" ||
                  _vm.app.user.roles[0].name == "admin"
                    ? _c("li", [
                        _c("label", { staticClass: "notify-title" }, [
                          _vm._v(
                            "\n                            Your release has been purchased\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "notify-options" }, [
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.release_purchased_notify,
                                  expression:
                                    "options.release_purchased_notify",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.release_purchased_notify
                                )
                                  ? _vm._i(
                                      _vm.options.release_purchased_notify,
                                      null
                                    ) > -1
                                  : _vm.options.release_purchased_notify,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.options.release_purchased_notify,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_purchased_notify",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_purchased_notify",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "release_purchased_notify",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.release_purchased_email,
                                  expression: "options.release_purchased_email",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.release_purchased_email
                                )
                                  ? _vm._i(
                                      _vm.options.release_purchased_email,
                                      null
                                    ) > -1
                                  : _vm.options.release_purchased_email,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.release_purchased_email,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_purchased_email",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_purchased_email",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "release_purchased_email",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.app.user.roles[0].name == "pro" ||
                  _vm.app.user.roles[0].name == "artist" ||
                  _vm.app.user.roles[0].name == "admin"
                    ? _c("li", [
                        _c("label", { staticClass: "notify-title" }, [
                          _vm._v(
                            "\n                            Your release has been favourited\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "notify-options" }, [
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.release_fav_notify,
                                  expression: "options.release_fav_notify",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.release_fav_notify
                                )
                                  ? _vm._i(
                                      _vm.options.release_fav_notify,
                                      null
                                    ) > -1
                                  : _vm.options.release_fav_notify,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.release_fav_notify,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_fav_notify",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_fav_notify",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "release_fav_notify",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.release_fav_email,
                                  expression: "options.release_fav_email",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.release_fav_email
                                )
                                  ? _vm._i(
                                      _vm.options.release_fav_email,
                                      null
                                    ) > -1
                                  : _vm.options.release_fav_email,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.release_fav_email,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_fav_email",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_fav_email",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "release_fav_email",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.app.user.roles[0].name == "pro" ||
                  _vm.app.user.roles[0].name == "artist" ||
                  _vm.app.user.roles[0].name == "admin"
                    ? _c("li", [
                        _c("label", { staticClass: "notify-title" }, [
                          _vm._v(
                            "\n                            Your release has been commented on\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "notify-options" }, [
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.release_commented_notify,
                                  expression:
                                    "options.release_commented_notify",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.release_commented_notify
                                )
                                  ? _vm._i(
                                      _vm.options.release_commented_notify,
                                      null
                                    ) > -1
                                  : _vm.options.release_commented_notify,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.options.release_commented_notify,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_commented_notify",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_commented_notify",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "release_commented_notify",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.release_commented_email,
                                  expression: "options.release_commented_email",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.release_commented_email
                                )
                                  ? _vm._i(
                                      _vm.options.release_commented_email,
                                      null
                                    ) > -1
                                  : _vm.options.release_commented_email,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.release_commented_email,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_commented_email",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "release_commented_email",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "release_commented_email",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.app.user.roles[0].name == "pro" ||
                  _vm.app.user.roles[0].name == "artist" ||
                  _vm.app.user.roles[0].name == "admin"
                    ? _c("li", [
                        _c("label", { staticClass: "notify-title" }, [
                          _vm._v(
                            "\n                            Your track has been shared\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "notify-options" }, [
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.track_shared_notify,
                                  expression: "options.track_shared_notify",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.track_shared_notify
                                )
                                  ? _vm._i(
                                      _vm.options.track_shared_notify,
                                      null
                                    ) > -1
                                  : _vm.options.track_shared_notify,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.track_shared_notify,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_shared_notify",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_shared_notify",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "track_shared_notify",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.track_shared_email,
                                  expression: "options.track_shared_email",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.track_shared_email
                                )
                                  ? _vm._i(
                                      _vm.options.track_shared_email,
                                      null
                                    ) > -1
                                  : _vm.options.track_shared_email,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.track_shared_email,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_shared_email",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_shared_email",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "track_shared_email",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.app.user.roles[0].name == "pro" ||
                  _vm.app.user.roles[0].name == "artist" ||
                  _vm.app.user.roles[0].name == "admin"
                    ? _c("li", [
                        _c("label", { staticClass: "notify-title" }, [
                          _vm._v(
                            "\n                            Your track has been purchased\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "notify-options" }, [
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.track_purchased_notify,
                                  expression: "options.track_purchased_notify",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.track_purchased_notify
                                )
                                  ? _vm._i(
                                      _vm.options.track_purchased_notify,
                                      null
                                    ) > -1
                                  : _vm.options.track_purchased_notify,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.track_purchased_notify,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_purchased_notify",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_purchased_notify",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "track_purchased_notify",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.track_purchased_email,
                                  expression: "options.track_purchased_email",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.track_purchased_email
                                )
                                  ? _vm._i(
                                      _vm.options.track_purchased_email,
                                      null
                                    ) > -1
                                  : _vm.options.track_purchased_email,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.track_purchased_email,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_purchased_email",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_purchased_email",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "track_purchased_email",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.app.user.roles[0].name == "pro" ||
                  _vm.app.user.roles[0].name == "artist" ||
                  _vm.app.user.roles[0].name == "admin"
                    ? _c("li", [
                        _c("label", { staticClass: "notify-title" }, [
                          _vm._v(
                            "\n                            Your track has been favourited\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "notify-options" }, [
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.track_fav_notify,
                                  expression: "options.track_fav_notify",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.track_fav_notify
                                )
                                  ? _vm._i(_vm.options.track_fav_notify, null) >
                                    -1
                                  : _vm.options.track_fav_notify,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.track_fav_notify,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_fav_notify",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_fav_notify",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "track_fav_notify",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.track_fav_email,
                                  expression: "options.track_fav_email",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.track_fav_email
                                )
                                  ? _vm._i(_vm.options.track_fav_email, null) >
                                    -1
                                  : _vm.options.track_fav_email,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.track_fav_email,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_fav_email",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_fav_email",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "track_fav_email",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.app.user.roles[0].name == "pro" ||
                  _vm.app.user.roles[0].name == "artist" ||
                  _vm.app.user.roles[0].name == "admin"
                    ? _c("li", [
                        _c("label", { staticClass: "notify-title" }, [
                          _vm._v(
                            "\n                            Your track has been commented on\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "notify-options" }, [
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.track_commented_notify,
                                  expression: "options.track_commented_notify",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.track_commented_notify
                                )
                                  ? _vm._i(
                                      _vm.options.track_commented_notify,
                                      null
                                    ) > -1
                                  : _vm.options.track_commented_notify,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.track_commented_notify,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_commented_notify",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_commented_notify",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "track_commented_notify",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                          _vm._v(" "),
                          _c("label", { staticClass: "switch" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.options.track_commented_email,
                                  expression: "options.track_commented_email",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.options.track_commented_email
                                )
                                  ? _vm._i(
                                      _vm.options.track_commented_email,
                                      null
                                    ) > -1
                                  : _vm.options.track_commented_email,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.options.track_commented_email,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_commented_email",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.options,
                                          "track_commented_email",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.options,
                                      "track_commented_email",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("span", { staticClass: "slider round" }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            Your post has been shared\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.activity_share_on_mine_notify,
                              expression:
                                "options.activity_share_on_mine_notify",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_share_on_mine_notify
                            )
                              ? _vm._i(
                                  _vm.options.activity_share_on_mine_notify,
                                  null
                                ) > -1
                              : _vm.options.activity_share_on_mine_notify,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options.activity_share_on_mine_notify,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_share_on_mine_notify",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_share_on_mine_notify",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_share_on_mine_notify",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.activity_share_on_mine_email,
                              expression:
                                "options.activity_share_on_mine_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_share_on_mine_email
                            )
                              ? _vm._i(
                                  _vm.options.activity_share_on_mine_email,
                                  null
                                ) > -1
                              : _vm.options.activity_share_on_mine_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options.activity_share_on_mine_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_share_on_mine_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_share_on_mine_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_share_on_mine_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            Your post has been favourited\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.activity_post_fav_notify,
                              expression: "options.activity_post_fav_notify",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_post_fav_notify
                            )
                              ? _vm._i(
                                  _vm.options.activity_post_fav_notify,
                                  null
                                ) > -1
                              : _vm.options.activity_post_fav_notify,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.options.activity_post_fav_notify,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_post_fav_notify",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_post_fav_notify",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_post_fav_notify",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.activity_post_fav_email,
                              expression: "options.activity_post_fav_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_post_fav_email
                            )
                              ? _vm._i(
                                  _vm.options.activity_post_fav_email,
                                  null
                                ) > -1
                              : _vm.options.activity_post_fav_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.options.activity_post_fav_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_post_fav_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_post_fav_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_post_fav_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            Your post has been commented\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.options.activity_comment_on_mine_notify,
                              expression:
                                "options.activity_comment_on_mine_notify",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_comment_on_mine_notify
                            )
                              ? _vm._i(
                                  _vm.options.activity_comment_on_mine_notify,
                                  null
                                ) > -1
                              : _vm.options.activity_comment_on_mine_notify,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options.activity_comment_on_mine_notify,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_comment_on_mine_notify",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_comment_on_mine_notify",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_comment_on_mine_notify",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.activity_comment_on_mine_email,
                              expression:
                                "options.activity_comment_on_mine_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_comment_on_mine_email
                            )
                              ? _vm._i(
                                  _vm.options.activity_comment_on_mine_email,
                                  null
                                ) > -1
                              : _vm.options.activity_comment_on_mine_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options.activity_comment_on_mine_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_comment_on_mine_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_comment_on_mine_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_comment_on_mine_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            Your profile has been shared\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.profile_shared_notify,
                              expression: "options.profile_shared_notify",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.profile_shared_notify
                            )
                              ? _vm._i(
                                  _vm.options.profile_shared_notify,
                                  null
                                ) > -1
                              : _vm.options.profile_shared_notify,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.options.profile_shared_notify,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "profile_shared_notify",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "profile_shared_notify",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "profile_shared_notify",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.profile_shared_email,
                              expression: "options.profile_shared_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.profile_shared_email
                            )
                              ? _vm._i(_vm.options.profile_shared_email, null) >
                                -1
                              : _vm.options.profile_shared_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.options.profile_shared_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "profile_shared_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "profile_shared_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "profile_shared_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "border-line" }),
              _vm._v(" "),
              _c("div", { staticClass: "options-column" }, [
                _c("div", { staticClass: "col-header" }, [
                  _c("div", { staticClass: "notify-heading" }, [
                    _c("span", [_vm._v("Social")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-icons" }, [
                    _c("div", { staticClass: "notification-icon" }, [
                      _c("i", { staticClass: "fa fa-bell" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "notification-count" }, [
                        _vm._v("1"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: "/img/Email_notification_icon.png",
                        alt: "",
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            New Messages\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _vm._v(
                          "\n                                 \n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.activity_message_email,
                              expression: "options.activity_message_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_message_email
                            )
                              ? _vm._i(
                                  _vm.options.activity_message_email,
                                  null
                                ) > -1
                              : _vm.options.activity_message_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.options.activity_message_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_message_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_message_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_message_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            New Followers\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.activity_follower_on_me_notify,
                              expression:
                                "options.activity_follower_on_me_notify",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_follower_on_me_notify
                            )
                              ? _vm._i(
                                  _vm.options.activity_follower_on_me_notify,
                                  null
                                ) > -1
                              : _vm.options.activity_follower_on_me_notify,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options.activity_follower_on_me_notify,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_follower_on_me_notify",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_follower_on_me_notify",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_follower_on_me_notify",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.activity_follower_on_me_email,
                              expression:
                                "options.activity_follower_on_me_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_follower_on_me_email
                            )
                              ? _vm._i(
                                  _vm.options.activity_follower_on_me_email,
                                  null
                                ) > -1
                              : _vm.options.activity_follower_on_me_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options.activity_follower_on_me_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_follower_on_me_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_follower_on_me_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_follower_on_me_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            User you follow has posted\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.options.activity_post_from_followee_notify,
                              expression:
                                "options.activity_post_from_followee_notify",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_post_from_followee_notify
                            )
                              ? _vm._i(
                                  _vm.options
                                    .activity_post_from_followee_notify,
                                  null
                                ) > -1
                              : _vm.options.activity_post_from_followee_notify,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options
                                    .activity_post_from_followee_notify,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_post_from_followee_notify",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_post_from_followee_notify",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_post_from_followee_notify",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.options.activity_post_from_followee_email,
                              expression:
                                "options.activity_post_from_followee_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_post_from_followee_email
                            )
                              ? _vm._i(
                                  _vm.options.activity_post_from_followee_email,
                                  null
                                ) > -1
                              : _vm.options.activity_post_from_followee_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options.activity_post_from_followee_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_post_from_followee_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_post_from_followee_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_post_from_followee_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            User you follow has posted a new event\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.options.activity_event_from_followee_notify,
                              expression:
                                "options.activity_event_from_followee_notify",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_event_from_followee_notify
                            )
                              ? _vm._i(
                                  _vm.options
                                    .activity_event_from_followee_notify,
                                  null
                                ) > -1
                              : _vm.options.activity_event_from_followee_notify,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options
                                    .activity_event_from_followee_notify,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_event_from_followee_notify",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_event_from_followee_notify",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_event_from_followee_notify",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.options.activity_event_from_followee_email,
                              expression:
                                "options.activity_event_from_followee_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_event_from_followee_email
                            )
                              ? _vm._i(
                                  _vm.options
                                    .activity_event_from_followee_email,
                                  null
                                ) > -1
                              : _vm.options.activity_event_from_followee_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options
                                    .activity_event_from_followee_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_event_from_followee_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_event_from_followee_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_event_from_followee_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            User who you follow has released new music\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.options.activity_released_new_music_notify,
                              expression:
                                "options.activity_released_new_music_notify",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_released_new_music_notify
                            )
                              ? _vm._i(
                                  _vm.options
                                    .activity_released_new_music_notify,
                                  null
                                ) > -1
                              : _vm.options.activity_released_new_music_notify,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options
                                    .activity_released_new_music_notify,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_released_new_music_notify",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_released_new_music_notify",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_released_new_music_notify",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.options.activity_released_new_music_email,
                              expression:
                                "options.activity_released_new_music_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_released_new_music_email
                            )
                              ? _vm._i(
                                  _vm.options.activity_released_new_music_email,
                                  null
                                ) > -1
                              : _vm.options.activity_released_new_music_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options.activity_released_new_music_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_released_new_music_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_released_new_music_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_released_new_music_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            User who you follow has released new merch\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.options.activity_released_new_merch_notify,
                              expression:
                                "options.activity_released_new_merch_notify",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_released_new_merch_notify
                            )
                              ? _vm._i(
                                  _vm.options
                                    .activity_released_new_merch_notify,
                                  null
                                ) > -1
                              : _vm.options.activity_released_new_merch_notify,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options
                                    .activity_released_new_merch_notify,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_released_new_merch_notify",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_released_new_merch_notify",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_released_new_merch_notify",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.options.activity_released_new_merch_email,
                              expression:
                                "options.activity_released_new_merch_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.activity_released_new_merch_email
                            )
                              ? _vm._i(
                                  _vm.options.activity_released_new_merch_email,
                                  null
                                ) > -1
                              : _vm.options.activity_released_new_merch_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a =
                                  _vm.options.activity_released_new_merch_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_released_new_merch_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "activity_released_new_merch_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "activity_released_new_merch_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "border-line" }),
              _vm._v(" "),
              _c("div", { staticClass: "options-column" }, [
                _c("div", { staticClass: "col-header" }, [
                  _c("div", { staticClass: "notify-heading" }, [
                    _c("span", [_vm._v("Phase")]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-icons" }, [
                    _c("div", { staticClass: "notification-icon" }, [
                      _c("i", { staticClass: "fa fa-bell" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "notification-count" }, [
                        _vm._v("1"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      attrs: {
                        src: "/img/Email_notification_icon.png",
                        alt: "",
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("ul", [
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            Phase News\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _vm._v(
                          "\n                                 \n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.phase_new_features_email,
                              expression: "options.phase_new_features_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.phase_new_features_email
                            )
                              ? _vm._i(
                                  _vm.options.phase_new_features_email,
                                  null
                                ) > -1
                              : _vm.options.phase_new_features_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.options.phase_new_features_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "phase_new_features_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "phase_new_features_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "phase_new_features_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            Music News\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _vm._v(
                          "\n                                 \n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.phase_music_news_email,
                              expression: "options.phase_music_news_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.phase_music_news_email
                            )
                              ? _vm._i(
                                  _vm.options.phase_music_news_email,
                                  null
                                ) > -1
                              : _vm.options.phase_music_news_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.options.phase_music_news_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "phase_music_news_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "phase_music_news_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "phase_music_news_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", { staticClass: "notify-title" }, [
                      _vm._v(
                        "\n                            Tip & Offers\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "notify-options" }, [
                      _c("label", { staticClass: "switch" }, [
                        _vm._v(
                          "\n                                 \n                                "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("label", { staticClass: "switch" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.options.phase_tips_offers_email,
                              expression: "options.phase_tips_offers_email",
                            },
                          ],
                          attrs: { type: "checkbox" },
                          domProps: {
                            checked: Array.isArray(
                              _vm.options.phase_tips_offers_email
                            )
                              ? _vm._i(
                                  _vm.options.phase_tips_offers_email,
                                  null
                                ) > -1
                              : _vm.options.phase_tips_offers_email,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.options.phase_tips_offers_email,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.options,
                                      "phase_tips_offers_email",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.options,
                                      "phase_tips_offers_email",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(
                                  _vm.options,
                                  "phase_tips_offers_email",
                                  $$c
                                )
                              }
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "slider round" }),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "ph-button",
              {
                staticClass: "mt-2",
                attrs: { size: "medium", loading: _vm.submitting },
                nativeOn: {
                  click: function ($event) {
                    return _vm.save.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Save")]
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "phase-loading" },
          [
            _c("dotlottie-player", {
              staticStyle: { width: "150px", height: "150px" },
              attrs: {
                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                background: "transparent",
                speed: "1",
                loop: "",
                autoplay: "",
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }