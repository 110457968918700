var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.release.status == "live"
    ? _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
        _c("div", { staticClass: "release" }, [
          _c("div", { staticClass: "release-info flex" }, [
            _c("div", { staticClass: "release-image" }, [
              _c("img", {
                attrs: {
                  src: _vm.release.image.files.medium.url,
                  alt: _vm.release.image.alt,
                  width: "130",
                },
              }),
              _vm._v(" "),
              _vm.isNew
                ? _c("span", { staticClass: "tag tag-right new" }, [
                    _vm._v("New"),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "release-details" }, [
              _c("div", { staticClass: "flex justify-between" }, [
                _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "release",
                            params: { releaseid: _vm.release.slug },
                          },
                        },
                      },
                      [_c("h2", [_vm._v(_vm._s(_vm.release.name))])]
                    ),
                    _vm._v(" "),
                    _c("p", { staticClass: "text-sm" }, [
                      _vm._v(_vm._s(_vm.release.uploader.name)),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.release.tracks.length
                  ? _c(
                      "div",
                      { staticClass: "play-button" },
                      [
                        _c("play-pause-button", {
                          attrs: {
                            track: _vm.release.tracks[0],
                            size: 15,
                            type: _vm.streamable,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _c("span", { staticClass: "text-sm" }, [
                    _c("span", { staticClass: "text-blue" }, [
                      _vm._v("Release Date: "),
                    ]),
                    _vm._v(" " + _vm._s(_vm.formattedDate)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "release-description flex text-xs" },
                [
                  _c("div", { staticStyle: { width: "85%" } }, [
                    _c("p", [
                      _c("span", { staticClass: "text-blue" }, [
                        _vm._v(
                          _vm._s(_vm._f("capitalize")(_vm.release.class)) +
                            " description: "
                        ),
                      ]),
                      _vm._v(" " + _vm._s(_vm.release.description)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("ph-button", { staticClass: "button-responsive" }, [
                    _vm._v(_vm._s(_vm.formattedPrice)),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "text-xs text-blue tab-buttons" }, [
                _c(
                  "span",
                  {
                    staticClass: "tabButton",
                    on: {
                      click: function ($event) {
                        return _vm.changeTab("details")
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm._f("capitalize")(_vm.release.class)) +
                        " details\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                ["album", "compilation"].includes(_vm.release.class) &&
                _vm.release.tracks.length
                  ? _c(
                      "span",
                      {
                        staticClass: "tabButton",
                        on: {
                          click: function ($event) {
                            return _vm.changeTab("tracks")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Tracks\n                    "
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _vm.tabOpen
          ? _c(
              "div",
              { staticClass: "tabs text-sm" },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tab === "details",
                        expression: "tab === 'details'",
                      },
                    ],
                    staticClass: "details",
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "details_item" },
                      [
                        _c("strong", [_vm._v("Genres:")]),
                        _vm._v(" "),
                        _vm._l(_vm.release.genres, function (genre, index) {
                          return _c("span", { key: index }, [
                            _vm._v(
                              "\n                    " + _vm._s(genre.name)
                            ),
                            _vm.release.genres[index + 1]
                              ? _c("span", [_vm._v(", ")])
                              : _vm._e(),
                          ])
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.release.class === "single"
                      ? _c("span", { staticClass: "details_item" }, [
                          _c("strong", [_vm._v("Key:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.release.tracks[0] &&
                                  _vm.release.tracks[0].key
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.release.class === "single"
                      ? _c("span", { staticClass: "details_item" }, [
                          _c("strong", [_vm._v("BPM:")]),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.release.tracks[0] &&
                                  _vm.release.tracks[0].bpm
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "details_item" }, [
                      _c("strong", [_vm._v("Homepage Feature:")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.release.featured ? "Featured" : "Not featured"
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.tab === "tracks"
                  ? _c("track-list", { attrs: { tracks: _vm.release.tracks } })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }