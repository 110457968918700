var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("h1", { staticStyle: { "text-align": "center" } }, [
      _vm._v("Phone Verification"),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { id: "account-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c("p", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Enter the verification code we sent to your phone"),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex flex-row justify-between", attrs: { id: "in" } },
          _vm._l(_vm.inputCount, function (inp, idx) {
            return _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputs[idx],
                  expression: "inputs[idx]",
                },
              ],
              key: idx,
              class: _vm.inputClass,
              style: _vm.style,
              attrs: { tabindex: idx, maxlength: "1", autocomplete: "off" },
              domProps: { value: _vm.inputs[idx] },
              on: {
                keyup: _vm.inputFocus,
                focus: function ($event) {
                  return _vm.check($event)
                },
                change: function ($event) {
                  return _vm.handleChange(idx, $event)
                },
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.inputs, idx, $event.target.value)
                },
              },
            })
          }),
          0
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm.errMsg
          ? _c(
              "span",
              {
                staticStyle: {
                  color: "red",
                  "text-align": "center",
                  display: "block",
                  "font-size": "14px",
                },
              },
              [_vm._v(_vm._s(_vm.errMsg))]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.resendMsg
          ? _c(
              "span",
              {
                staticStyle: {
                  color: "#3300ff",
                  "text-align": "center",
                  display: "block",
                  "font-size": "14px",
                },
              },
              [_vm._v(_vm._s(_vm.resendMsg))]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "small",
          {
            staticStyle: {
              display: "flex",
              "justify-content": "center",
              "flex-direction": "row",
              margin: "15px 0px",
              cursor: "pointer",
            },
            on: { click: _vm.handleResend },
          },
          [_c("i", { staticClass: "fa fa-redo" }), _vm._v(" Resend code")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "submit-buttons" }, [
          _c(
            "div",
            { staticClass: "button-wrap" },
            [
              _c(
                "ph-button",
                { attrs: { size: "medium", loading: _vm.submitting } },
                [_vm._v(" Continue ")]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }