var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid" },
    _vm._l(_vm.data, function (item) {
      return _c(
        "div",
        { key: item.id, staticClass: "grid_item" },
        [
          _c("router-link", { attrs: { to: _vm.getRouterObject(item) } }, [
            _c("img", {
              attrs: { src: item.image.files.original.url, alt: "" },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "content" }, [
              _c("p", { staticClass: "type" }, [
                _vm._v("Latest " + _vm._s(item.type)),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "name" }, [
                _vm._v(_vm._s(item.name || item.title)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "overlay" }),
          ]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }