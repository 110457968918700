var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-content-padded", staticStyle: { padding: "0" } },
    [
      _vm.app.user.loggedin
        ? _c(
            "div",
            {
              staticClass: "page-main",
              staticStyle: {
                display: "flex",
                "max-width": "none",
                "min-height": "100vh",
              },
            },
            [
              _c("account-menu", {
                attrs: { active: _vm.activeTab },
                on: { handleActiveTab: _vm.handleActiveTab },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    margin: "0px 30px",
                    height: "100%",
                  },
                },
                [
                  _c(
                    "transition",
                    { attrs: { mode: "out-in" } },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }