var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "artist-tile",
      attrs: { to: _vm.getRouterObject(_vm.user) },
    },
    [
      _c(
        "div",
        { staticClass: "artist-tile-image" },
        [
          _c("avatar", {
            attrs: {
              tile: true,
              size: _vm.size,
              src: _vm.user.avatar.files.medium.url,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "artist-tile-text" }, [
        _c("p", { staticClass: "artist-name" }, [
          _vm._v(_vm._s(_vm.user.name)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }