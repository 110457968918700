var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "genre-select" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.searchTerm,
          expression: "searchTerm",
        },
      ],
      attrs: {
        type: "text",
        placeholder:
          _vm.selectedGenres.length < _vm.max
            ? "Minimum of " + _vm.min + ", maximum of " + _vm.max + " genres"
            : "Maximum number of genres reached",
        disabled: _vm.selectedGenres.length >= _vm.max || _vm.disabled,
      },
      domProps: { value: _vm.searchTerm },
      on: {
        input: [
          function ($event) {
            if ($event.target.composing) return
            _vm.searchTerm = $event.target.value
          },
          _vm.input,
        ],
        keyup: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "down", 40, $event.key, [
                "Down",
                "ArrowDown",
              ])
            )
              return null
            return _vm.onArrowDown.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
            )
              return null
            return _vm.onArrowUp.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.onEnter.apply(null, arguments)
          },
        ],
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          )
            return null
          $event.preventDefault()
        },
      },
    }),
    _vm._v(" "),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showList,
            expression: "showList",
          },
        ],
        staticClass: "search-results",
      },
      _vm._l(_vm.searchResults, function (genre, i) {
        return _c(
          "li",
          {
            key: i,
            class: { highlighted: i === _vm.arrowCounter },
            on: {
              click: function ($event) {
                return _vm.selectGenre(genre)
              },
              mouseover: function ($event) {
                _vm.arrowCounter = i
              },
            },
          },
          [_vm._v("\n            " + _vm._s(genre.name) + "\n        ")]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.selectedGenres.length > 0
      ? _c("p", [_vm._v("\n        Click on a genre to remove it\n    ")])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.selectedGenres.length > 0,
            expression: "selectedGenres.length > 0",
          },
        ],
        staticClass: "selected-genres",
      },
      _vm._l(_vm.selectedGenres, function (genre, i) {
        return _c(
          "li",
          {
            key: i,
            on: {
              click: function ($event) {
                return _vm.removeGenre(genre)
              },
            },
          },
          [_vm._v("\n            " + _vm._s(genre.name) + "\n        ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }