var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.track && _vm.track.status === "approved"
        ? _c("track-release", { attrs: { item: _vm.track } })
        : _vm._e(),
      _vm._v(" "),
      _vm.track && _vm.track.status !== "approved"
        ? _c("div", { staticClass: "center-content" }, [
            _vm._v("\n        This track has not been approved yet.\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.track
        ? _c("spinner", {
            staticStyle: { margin: "3em auto" },
            attrs: { "animation-duration": 1000, size: 80, color: "black" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }