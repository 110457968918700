var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "actions" }, [
    _c("div", [
      (_vm.actType = "merch")
        ? _c(
            "div",
            { staticClass: "action" },
            _vm._l(_vm.actionable.links, function (link, index) {
              return _c("span", { key: index, staticClass: "merch-link" }, [
                _c("a", { attrs: { href: link.link, target: "_blank" } }, [
                  _c("i", { staticClass: "fa fa-info-circle" }),
                ]),
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.app.user.loggedin
        ? _c(
            "div",
            { staticClass: "action" },
            [
              _c("comment-button", {
                attrs: { commentable: _vm.actionable },
                on: { commented: _vm.commented },
              }),
              _vm._v(" "),
              _vm.actionable.comments_count >= 0
                ? _c("div", { staticClass: "action-count" }, [
                    _vm._v(_vm._s(_vm.actionable.comments_count)),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.app.user.loggedin
        ? _c(
            "div",
            { staticClass: "action" },
            [
              _c("share-button", {
                attrs: { shareable: _vm.actionable },
                on: { share: _vm.shared },
              }),
              _vm._v(" "),
              _vm.actionable.shares_count >= 0
                ? _c("div", { staticClass: "action-count" }, [
                    _vm._v(_vm._s(_vm.actionable.shares_count)),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.app.user.loggedin
        ? _c(
            "div",
            { staticClass: "action" },
            [
              _c("like-button", {
                attrs: { likeable: _vm.actionable },
                on: { like: _vm.liked, unlike: _vm.unliked },
              }),
              _vm._v(" "),
              _vm.actionable.likes_count >= 0
                ? _c("div", { staticClass: "action-count" }, [
                    _vm._v(_vm._s(_vm.actionable.likes_count)),
                  ])
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.app.user.loggedin
        ? _c(
            "div",
            { staticClass: "action" },
            [
              _c("report-button", {
                attrs: { reportable: _vm.actionable },
                on: { report: _vm.reported },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.actType != "post"
        ? _c(
            "div",
            { staticClass: "action" },
            [_c("info-button", { attrs: { infoable: _vm.actionable } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.actType === "track" && _vm.download
        ? _c(
            "div",
            { staticClass: "action" },
            [
              _c("action-menu", {
                attrs: { actionable: _vm.actionable, download: _vm.download },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.actType === "post" && _vm.app.user.id === _vm.actionable.user_id
        ? _c(
            "div",
            {
              staticClass: "action",
              on: {
                click: function ($event) {
                  return _vm.editPost(_vm.actionable)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-pencil" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.actType === "post" && _vm.app.user.id === _vm.actionable.user_id
        ? _c(
            "div",
            { staticClass: "action", on: { click: _vm.deleteAction } },
            [_c("i", { staticClass: "fa fa-trash" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.actType === "merch" && _vm.app.user.id === _vm.actionable.user_id
        ? _c(
            "div",
            {
              staticClass: "action",
              on: {
                click: function ($event) {
                  return _vm.editMerch(_vm.actionable)
                },
              },
            },
            [_c("i", { staticClass: "fa fa-pencil" })]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.actType === "merch" && _vm.app.user.id === _vm.actionable.user_id
        ? _c("div", { staticClass: "action", on: { click: _vm.deleteMerch } }, [
            _c("i", { staticClass: "fa fa-trash" }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }