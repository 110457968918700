var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-create-merch",
        width: "800px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal modal-create-event" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-create-merch")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content content-section" }, [
          _c("h2", [_vm._v("Create a new merch item")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n\t\t\t\tUse this form to create links to your merch.\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "event-options" }, [
            _c(
              "div",
              { staticClass: "event-image" },
              [
                _c("image-select", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min-dimensions:300,300",
                      expression: "'required|min-dimensions:300,300'",
                    },
                  ],
                  attrs: { name: "image" },
                  model: {
                    value: _vm.data.image,
                    callback: function ($$v) {
                      _vm.$set(_vm.data, "image", $$v)
                    },
                    expression: "data.image",
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.errors.first("image"))),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "event-info" },
              [
                _c("form", [
                  _c("table", [
                    _c("tr", [
                      _c("td", [_vm._v("Title")]),
                      _vm._v(" "),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.title,
                              expression: "data.title",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:255",
                              expression: "'required|max:255'",
                            },
                          ],
                          attrs: { type: "text", name: "title" },
                          domProps: { value: _vm.data.title },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(_vm.data, "title", $event.target.value)
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(_vm._s(_vm.errors.first("title"))),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", { staticStyle: { "vertical-align": "top" } }, [
                        _vm._v("Description"),
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.description,
                              expression: "data.description",
                            },
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:255",
                              expression: "'required|max:255'",
                            },
                          ],
                          attrs: { type: "text", name: "description" },
                          domProps: { value: _vm.data.description },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.data,
                                "description",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(_vm._s(_vm.errors.first("description"))),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Links")]),
                      _vm._v(" "),
                      _c(
                        "td",
                        _vm._l(_vm.data.links, function (link, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticStyle: { "padding-bottom": "5px" },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: link.link,
                                    expression: "link.link",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required|url",
                                    expression: "'required|url'",
                                  },
                                ],
                                staticStyle: {
                                  width: "85%",
                                  "margin-top": "5px",
                                },
                                attrs: {
                                  required: "",
                                  type: "text",
                                  name: `links[link][${index}]`,
                                  placeholder: "Link",
                                  "data-vv-as": "link",
                                },
                                domProps: { value: link.link },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(link, "link", $event.target.value)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.data.links.length > 1
                                ? _c(
                                    "span",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeLink(index)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "link fas fa-minus",
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticStyle: { "margin-left": "20px" } },
                                [
                                  _c("span", { staticClass: "error-msg" }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.errors.first(
                                            `links[link][${index}]`
                                          )
                                        ) +
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.data.validationErrors[
                                            "links.0.link"
                                          ]
                                            ? "Please enter proper link as http(s)://www.example.com"
                                            : ""
                                        )
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "ph-button",
                  {
                    attrs: {
                      size: "medium",
                      loading: _vm.submitting,
                      color: "primary-outline",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Submit")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }