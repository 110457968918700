var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "only-music-bar" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.$store.state.player.status.set,
            expression: "$store.state.player.status.set",
          },
        ],
        staticClass: "user-bar-player",
      },
      [_c("player-for-search")],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }