var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded" }, [
    _c(
      "div",
      { staticClass: "page-main" },
      [
        _c("h1", { staticClass: "no-top" }, [_vm._v("Recently Played")]),
        _vm._v(" "),
        _vm._l(_vm.tracks, function (track) {
          return _c(
            "div",
            [
              _c(
                "router-link",
                {
                  staticClass: "release-tile",
                  attrs: {
                    to: { name: "track", params: { trackid: track.slug } },
                  },
                },
                [
                  _c("div", { staticClass: "recent-wrap" }, [
                    _c(
                      "div",
                      [
                        _c("avatar-track", {
                          attrs: {
                            size: 100,
                            tile: true,
                            src: track.release.image.files.medium.url,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "track-meta" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(track.name) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }