var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("overlay-notify", { attrs: { "is-visible": _vm.isOverlayVisible } }, [
        _c(
          "div",
          { staticClass: "overlay-content" },
          [
            _c("dotlottie-player", {
              staticStyle: { width: "200px", height: "200px" },
              attrs: {
                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                background: "transparent",
                speed: "1",
                loop: "",
                autoplay: "",
              },
            }),
            _vm._v(" "),
            _c("h3", [_vm._v("Redirecting to stripe...")]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "register-form-group" }, [
        _c("h2", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Personal Details"),
        ]),
        _vm._v(" "),
        _vm.selectedPlan.role.name === "standard"
          ? _c("div", { staticClass: "register-form-inputs" }, [
              _c("div", { staticClass: "full-width" }, [
                _c("div", { staticClass: "full-width" }, [
                  _c("div", { staticClass: "input" }, [
                    _c("div", [_vm._v("Username:")]),
                    _vm._v(" "),
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.data.personal.username,
                            expression: "data.personal.username",
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required|username|max:20",
                            expression: "'required|username|max:20'",
                          },
                        ],
                        attrs: {
                          type: "text",
                          name: "personal-username",
                          tabindex: "1",
                          disabled: _vm.submitting,
                          "data-vv-as": "user name",
                        },
                        domProps: { value: _vm.data.personal.username },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.data.personal,
                              "username",
                              $event.target.value
                            )
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "error-message" }, [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.errors.first("personal-username")) +
                            "\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.validationErrors["personal.username"],
                              expression:
                                "validationErrors['personal.username']",
                            },
                          ],
                          staticClass: "error-message",
                        },
                        [
                          _vm._v(
                            "\n                The username has been taken\n              "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.selectedPlan.role.name === "artist" ||
        _vm.selectedPlan.role.name === "pro"
          ? _c("div", { staticClass: "register-form-inputs" }, [
              _c("div", { staticClass: "full-width" }, [
                _c("div", { staticClass: "full-width" }, [
                  _c("div", { staticClass: "input" }, [
                    _c("div", [_vm._v("Artist Type:")]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("artist-type-select", {
                          attrs: {
                            min: 2,
                            max: 4,
                            tabindex: "10",
                            disabled: _vm.submitting,
                            "data-vv-validate-on": "blur",
                          },
                          on: { change: _vm.artistTypesChanged },
                        }),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          ref: "artist_user_type_input",
                          attrs: {
                            type: "hidden",
                            name: "artist_user_type",
                            placeholder: "Artist Type",
                          },
                        }),
                        _vm._v(" "),
                        _c("p", { staticClass: "error-message" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.errors.first("personal.artist_user_type")
                              ) +
                              "\n              "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.emptyArtistType,
                                expression: "emptyArtistType",
                              },
                            ],
                            staticClass: "error-message",
                            staticStyle: { "margin-top": "20px" },
                          },
                          [
                            _vm._v(
                              "\n                The artist type is required\n              "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "register-form-inputs" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "input" }, [
              _c("div", [_vm._v("First Name:")]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.personal.firstname,
                      expression: "data.personal.firstname",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        max: 255,
                        regex: /^[a-zA-Z][a-zA-Z ]*$/,
                      },
                      expression:
                        "{ required: true, max: 255, regex: /^[a-zA-Z][a-zA-Z ]*$/ }",
                    },
                  ],
                  attrs: {
                    type: "text",
                    name: "personal-name",
                    tabindex: "2",
                    disabled: _vm.submitting,
                    "data-vv-as": "first name",
                    "data-vv-validate-on": "focusout",
                  },
                  domProps: { value: _vm.data.personal.firstname },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.data.personal,
                        "firstname",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "error-message" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.errors.first("personal-name")) +
                      "\n            "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input" }, [
              _c("div", [_vm._v("Password:")]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.personal.password,
                      expression: "data.personal.password",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        max: 255,
                      },
                      expression:
                        "{\n                required: true,\n                max: 255,\n              }",
                    },
                  ],
                  ref: "password",
                  attrs: {
                    type: "password",
                    name: "personal-password",
                    tabindex: "6",
                    disabled: _vm.submitting,
                    "data-vv-as": "password",
                    "data-vv-validate-on": "focusout",
                  },
                  domProps: { value: _vm.data.personal.password },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.data.personal,
                        "password",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "error-message" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.errors.first("personal-password")) +
                      "\n            "
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "input" }, [
              _c("div", [_vm._v("Last Name:")]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.personal.surname,
                      expression: "data.personal.surname",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        max: 255,
                        regex: /^[a-zA-Z][a-zA-Z ]*$/,
                      },
                      expression:
                        "{ required: true, max: 255, regex: /^[a-zA-Z][a-zA-Z ]*$/ }",
                    },
                  ],
                  attrs: {
                    type: "text",
                    name: "personal-surname",
                    tabindex: "3",
                    disabled: _vm.submitting,
                    "data-vv-as": "surname",
                    "data-vv-validate-on": "focusout",
                  },
                  domProps: { value: _vm.data.personal.surname },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.data.personal,
                        "surname",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "error-message" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.errors.first("personal-surname")) +
                      "\n            "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input" }, [
              _c("div", [_vm._v("Confirm Password:")]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.personal.password_confirmation,
                      expression: "data.personal.password_confirmation",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:255|confirmed:password",
                      expression: "'required|max:255|confirmed:password'",
                    },
                  ],
                  attrs: {
                    type: "password",
                    name: "personal-password-confirmation",
                    tabindex: "7",
                    disabled: _vm.submitting,
                    "data-vv-as": "password",
                    "data-vv-validate-on": "focusout",
                  },
                  domProps: { value: _vm.data.personal.password_confirmation },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.data.personal,
                        "password_confirmation",
                        $event.target.value
                      )
                    },
                  },
                }),
                _vm._v(" "),
                _c("p", { staticClass: "error-message" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(
                        _vm.errors.first("personal-password-confirmation")
                      ) +
                      "\n            "
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "register-form-inputs" }, [
          _c("div", { staticClass: "full-width" }, [
            _c("div", { staticClass: "full-width" }, [
              _c("div", { staticClass: "input" }, [
                _c("div", [_vm._v("Email:")]),
                _vm._v(" "),
                _c(
                  "div",
                  { class: { "email-input": true, valid: _vm.isValid } },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.personal.email,
                          expression: "data.personal.email",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|email|max:255",
                          expression: "'required|email|max:255'",
                        },
                      ],
                      attrs: {
                        type: "email",
                        "data-vv-as": "email address",
                        "data-vv-validate-on": "focusout",
                        name: "personal-email",
                        tabindex: "8",
                        disabled: _vm.submitting,
                        placeholder: "Enter Email",
                      },
                      domProps: { value: _vm.data.personal.email },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data.personal,
                            "email",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("svg", { attrs: { viewBox: "0 0 18 18" } }, [
                      _c("path", {
                        attrs: {
                          d: "M11.5,10.5 C6.4987941,17.5909626 1,3.73719105 11.5,6 C10.4594155,14.5485365 17,13.418278 17,9 C17,4.581722 13.418278,1 9,1 C4.581722,1 1,4.581722 1,9 C1,13.418278 4.581722,17 9,17 C13.418278,17 17,13.42 17,9",
                        },
                      }),
                      _vm._v(" "),
                      _c("polyline", { attrs: { points: "5 9.25 8 12 13 6" } }),
                    ]),
                    _vm._v(" "),
                    _c("p", { staticClass: "error-message" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.errors.first("personal-email")) +
                          "\n              "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.validationErrors["personal.email"],
                            expression: "validationErrors['personal.email']",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          "\n                The email has been taken\n              "
                        ),
                      ]
                    ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "register-form-inputs" }, [
          _c("div", { staticClass: "full-width" }, [
            _c("div", { staticClass: "full-width" }, [
              _c("div", { staticClass: "input" }, [
                _c("div", [_vm._v("Country:")]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("country-select", {
                      staticStyle: { width: "100%", "margin-top": "8px" },
                      on: { change: _vm.artistCountryChanged },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "error-message" }, [
                      _vm._v(_vm._s(_vm.errors.first("country"))),
                    ]),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.selectedPlan.role.name === "artist" ||
      _vm.selectedPlan.role.name === "pro"
        ? _c("div", { staticClass: "register-form-group" }, [
            _c("h2", [_vm._v("Artist / Producer / Label Details")]),
            _vm._v(" "),
            _c("div", { staticClass: "register-form-inputs" }, [
              _c("div", { staticClass: "full-width" }, [
                _c("div", { staticClass: "input" }, [
                  _c("div", [_vm._v("Name:")]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.artist.username,
                          expression: "data.artist.username",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|max:20",
                          expression: "'required|max:20'",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "artist-name",
                        tabindex: "9",
                        disabled: _vm.submitting,
                        "data-vv-as": "name",
                        "data-vv-validate-on": "focusout",
                      },
                      domProps: { value: _vm.data.artist.username },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data.artist,
                            "username",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.validationErrors["artist.username"],
                            expression: "validationErrors['artist.username']",
                          },
                        ],
                        staticClass: "error-message",
                      },
                      [
                        _vm._v(
                          "\n              The name has been taken\n            "
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input" }, [
                  _c("div", [_vm._v("Genres:")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("genre-select", {
                        attrs: {
                          min: 2,
                          max: 4,
                          tabindex: "10",
                          disabled: _vm.submitting,
                          "data-vv-validate-on": "blur",
                        },
                        on: { change: _vm.artistGenresChanged },
                      }),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'",
                          },
                        ],
                        ref: "artist_genre_input",
                        attrs: {
                          type: "hidden",
                          name: "artist genre",
                          placeholder: "Genre",
                        },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "error-message" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.errors.first("artist genre")) +
                            "\n            "
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.selectedPlan.role.name === "artist" ||
      _vm.selectedPlan.role.name === "pro"
        ? _c("div", { staticClass: "register-form-group" }, [
            _c("h2", { staticClass: "social-header" }, [
              _vm._v("Social & Website Links"),
            ]),
            _vm._v(" "),
            _vm.submitted && !_vm.hasValidSocial
              ? _c("p", { staticClass: "social-error" }, [
                  _vm._v(
                    "\n      You must enter at least one social url\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "register-form-inputs" }, [
              _c("div", { staticClass: "full-width" }, [
                _c("div", { staticClass: "input" }, [
                  _c("div", [_vm._v("Website")]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.social.website,
                          expression: "data.social.website",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            max: 255,
                            regex:
                              /^((https?):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
                          },
                          expression:
                            "{\n                max: 255,\n                regex: /^((https?):\\/\\/)?(www.)?[a-z0-9]+\\.[a-z]+(\\/[a-zA-Z0-9#]+\\/?)*$/,\n              }",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "social-website",
                        tabindex: "11",
                        disabled: _vm.submitting,
                        "data-vv-as": "website",
                        "data-vv-validate-on": "focusout",
                      },
                      domProps: { value: _vm.data.social.website },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data.social,
                            "website",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "error-message" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.errors.first("social-website")) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "register-form-inputs" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticClass: "input" }, [
                  _c("div", [_vm._v("Facebook")]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.social.facebook,
                          expression: "data.social.facebook",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            max: 255,
                            regex:
                              /^(http(s)?:\/\/)?(www\.)?(m\.)?facebook\.com\/[A-z 0-9 _ .]*\/?$/,
                          },
                          expression:
                            "{\n                max: 255,\n                regex: /^(http(s)?:\\/\\/)?(www\\.)?(m\\.)?facebook\\.com\\/[A-z 0-9 _ .]*\\/?$/,\n              }",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "social-facebook",
                        tabindex: "12",
                        disabled: _vm.submitting,
                        "data-vv-as": "Facebook",
                        "data-vv-validate-on": "focusout",
                      },
                      domProps: { value: _vm.data.social.facebook },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data.social,
                            "facebook",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "error-message" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.errors.first("social-facebook")) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input" }, [
                  _c("div", [_vm._v("Soundcloud")]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.social.soundcloud,
                          expression: "data.social.soundcloud",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            max: 255,
                            regex:
                              /^(http(s)?:\/\/)?(www\.)?(m\.)?(soundcloud\.com|snd\.sc)\/(.*)$/,
                          },
                          expression:
                            "{\n                max: 255,\n                regex: /^(http(s)?:\\/\\/)?(www\\.)?(m\\.)?(soundcloud\\.com|snd\\.sc)\\/(.*)$/,\n              }",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "social-soundcloud",
                        tabindex: "14",
                        disabled: _vm.submitting,
                        "data-vv-as": "Soundcloud",
                        "data-vv-validate-on": "focusout",
                      },
                      domProps: { value: _vm.data.social.soundcloud },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data.social,
                            "soundcloud",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "error-message" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.errors.first("social-soundcloud")) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right" }, [
                _c("div", { staticClass: "input" }, [
                  _c("div", [_vm._v("Twitter")]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.social.twitter,
                          expression: "data.social.twitter",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            max: 255,
                            regex:
                              /^(http(s)?:\/\/)?(www\.)?(mobile\.)?twitter\.com\/[A-z 0-9 _]{1,15}\/?$/,
                          },
                          expression:
                            "{\n                max: 255,\n                regex: /^(http(s)?:\\/\\/)?(www\\.)?(mobile\\.)?twitter\\.com\\/[A-z 0-9 _]{1,15}\\/?$/,\n              }",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "social-twitter",
                        tabindex: "13",
                        disabled: _vm.submitting,
                        "data-vv-as": "Twitter",
                        "data-vv-validate-on": "focusout",
                      },
                      domProps: { value: _vm.data.social.twitter },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data.social,
                            "twitter",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "error-message" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.errors.first("social-twitter")) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input" }, [
                  _c("div", [_vm._v("YouTube")]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.data.social.youtube,
                          expression: "data.social.youtube",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: {
                            max: 255,
                            regex:
                              /(https?:\/\/)?(www\.)?(m\.)?youtube\.com\/(channel|user|c|u)\/[\w-]+/,
                          },
                          expression:
                            "{\n                max: 255,\n                regex: /(https?:\\/\\/)?(www\\.)?(m\\.)?youtube\\.com\\/(channel|user|c|u)\\/[\\w-]+/,\n              }",
                        },
                      ],
                      attrs: {
                        type: "text",
                        name: "social-youtube",
                        tabindex: "15",
                        disabled: _vm.submitting,
                        "data-vv-as": "Youtube",
                        "data-vv-validate-on": "focusout",
                      },
                      domProps: { value: _vm.data.social.youtube },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.data.social,
                            "youtube",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("p", { staticClass: "error-message" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.errors.first("social-youtube")) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "register-form-group" }, [
        _c("h2", [_vm._v("Music Interests")]),
        _vm._v(" "),
        _c("div", { staticClass: "register-form-inputs" }, [
          _c("div", { staticClass: "full-width" }, [
            _c("div", { staticClass: "input" }, [
              _c("div", [_vm._v("Genres:")]),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("genre-select", {
                    attrs: {
                      min: 2,
                      max: 4,
                      tabindex: "16",
                      disabled: _vm.submitting,
                      "data-vv-validate-on": "blur",
                    },
                    on: { change: _vm.interestGenresChanged },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                    ],
                    ref: "interest_genre_input",
                    attrs: {
                      type: "hidden",
                      name: "interest genre",
                      placeholder: "Genre",
                    },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "error-message" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.first("interest genre")) +
                        "\n            "
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "register-form-inputs" }, [
          _c("div", { staticClass: "full-width" }, [
            _c("div", { staticClass: "input" }, [
              _c("div", [_vm._v("Sign up to newsletter:")]),
              _vm._v(" "),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.newsletter,
                      expression: "data.newsletter",
                    },
                  ],
                  attrs: {
                    type: "checkbox",
                    name: `${_vm.selectedPlan.role.name}_newsletter`,
                  },
                  domProps: {
                    checked: Array.isArray(_vm.data.newsletter)
                      ? _vm._i(_vm.data.newsletter, null) > -1
                      : _vm.data.newsletter,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.data.newsletter,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(_vm.data, "newsletter", $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.data,
                              "newsletter",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.data, "newsletter", $$c)
                      }
                    },
                  },
                }),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "register-form-inputs" }, [
          _c("div", { staticClass: "full-width" }, [
            _c("div", { staticClass: "input" }, [
              _c("div"),
              _vm._v(" "),
              _c("div", { staticClass: "center" }),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("vue-recaptcha", {
                    ref: "recaptcha",
                    attrs: {
                      sitekey: _vm.getSiteKey(),
                      loadRecaptchaScript: true,
                    },
                    on: {
                      verify: _vm.onCaptchaVerified,
                      expired: _vm.onCaptchaExpired,
                    },
                  }),
                  _vm._v(" "),
                  _vm.captchaValidationError
                    ? _c(
                        "p",
                        {
                          staticClass: "error-message",
                          staticStyle: { "padding-top": "40px" },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.captchaValidationError) +
                              "\n            "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex justify-center" },
        [
          _c(
            "ph-button",
            {
              attrs: {
                size: "large",
                loading: _vm.submitting,
                color: "primary-outline",
              },
              nativeOn: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.registerUser.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      Submit\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }