var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading && _vm.releases.data.length && _vm.app.user.account_verified
        ? _c(
            "ph-button",
            {
              attrs: { size: "large" },
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.show("modal-upload")
                },
              },
            },
            [_vm._v("Upload")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.releases.data.length
        ? _c(
            "div",
            { staticClass: "release-wrapper" },
            [
              _vm._l(_vm.releases.data, function (release) {
                return _c("release", {
                  key: release.id,
                  attrs: { release: release },
                })
              }),
              _vm._v(" "),
              _vm.hasAnotherPage && _vm.releases.data.length
                ? _c(
                    "div",
                    {
                      staticClass: "centered-text",
                      staticStyle: { margin: "4em 0" },
                    },
                    [
                      _c(
                        "ph-button",
                        {
                          attrs: {
                            size: "large",
                            loading: _vm.loadingNextPage,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.loadNextPage.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("\n        Show Me More\n      ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading && !_vm.releases.data.length
        ? _c(
            "div",
            { staticClass: "centered-text", staticStyle: { margin: "4em 0" } },
            [
              _c("h2", [_vm._v("No Releases Yet")]),
              _vm._v(" "),
              _vm.createButtonDisabled
                ? _c(
                    "ph-button",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: {
                            content: _vm.createButtonTooltip,
                            class: "custom-red",
                          },
                          expression:
                            "{content: createButtonTooltip, class: 'custom-red'}",
                          modifiers: { top: true },
                        },
                      ],
                      style: _vm.createButtonStyle,
                      attrs: {
                        size: "medium",
                        disabled: _vm.createButtonDisabled,
                      },
                    },
                    [_vm._v("\n      Create Release\n    ")]
                  )
                : _c(
                    "ph-button",
                    {
                      style: _vm.createButtonStyle,
                      attrs: { size: "medium" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.handleCreateReleaseClick.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [_vm._v("\n      Create Release\n    ")]
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.loading || _vm.loadingNextPage
        ? _c("div", [
            _c(
              "div",
              { staticClass: "phase-loading widget-center" },
              [
                _c("dotlottie-player", {
                  staticStyle: { width: "150px", height: "150px" },
                  attrs: {
                    src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                    background: "transparent",
                    speed: "1",
                    loop: "",
                    autoplay: "",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }