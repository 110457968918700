var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded page-help-support" }, [
    _c(
      "div",
      { staticClass: "page-main" },
      [
        _c("div", { staticClass: "help-hero" }, [
          _c("div", { staticClass: "help-search" }, [
            _c(
              "div",
              { staticClass: "search-button" },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.searching,
                        expression: "!searching",
                      },
                    ],
                  },
                  [_c("i", { staticClass: "fas fa-search" })]
                ),
                _vm._v(" "),
                _c("spinner", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.searching,
                      expression: "searching",
                    },
                  ],
                  staticStyle: { margin: "3em auto" },
                  attrs: {
                    "animation-duration": 1000,
                    size: 40,
                    color: _vm.variables.colors.colorBlue,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "search-input-container" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchTerm,
                    expression: "searchTerm",
                  },
                ],
                attrs: { type: "text", placeholder: "HOW CAN WE HELP?" },
                domProps: { value: _vm.searchTerm },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.searchTerm = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.searchResults.length,
                    expression: "searchResults.length",
                  },
                ],
                staticClass: "search-results",
              },
              _vm._l(_vm.searchResults, function (faqResult) {
                return _c(
                  "div",
                  {
                    staticClass: "search-result",
                    on: {
                      click: function ($event) {
                        return _vm.showFaqModal(faqResult)
                      },
                    },
                  },
                  [
                    _vm._m(0, true),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n              " +
                          _vm._s(faqResult.question) +
                          "\n            "
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.loaded
          ? _c(
              "div",
              { staticClass: "faq-categories" },
              _vm._l(_vm.categories, function (category) {
                return _c(
                  "div",
                  {
                    key: category.id,
                    staticClass: "faq-category",
                    class: { active: _vm.isCategoryActive(category) },
                    on: {
                      click: function ($event) {
                        return _vm.selectCategory(category)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "category-icon" }, [
                      _c("i", { class: category.icon }),
                    ]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(category.name))]),
                  ]
                )
              }),
              0
            )
          : _c("spinner", {
              staticStyle: { margin: "3em auto" },
              attrs: { "animation-duration": 1000, size: 60, color: "black" },
            }),
        _vm._v(" "),
        _vm.categories.length
          ? _c(
              "div",
              { staticClass: "faqs" },
              _vm._l(_vm.faqs, function (faq) {
                return _c("div", { staticClass: "faq" }, [
                  _c(
                    "div",
                    {
                      staticClass: "question",
                      on: {
                        click: function ($event) {
                          return _vm.toggleFaq(faq)
                        },
                      },
                    },
                    [
                      _vm._m(1, true),
                      _vm._v(" "),
                      _c("div", { staticClass: "question-text" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(faq.question) +
                            "\n          "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "answer",
                      class: { active: _vm.isFaqActive(faq) },
                    },
                    [
                      _vm._m(2, true),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "answer-text",
                        domProps: { innerHTML: _vm._s(faq.answer.nl2br()) },
                      }),
                    ]
                  ),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.selectedCategory && _vm.selectedCategory.id === 5
          ? _c("contact-form")
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "question-icon" }, [
      _c("i", { staticClass: "fas fa-question-circle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "question-icon" }, [
      _c("i", { staticClass: "fas fa-question-circle" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "answer-icon" }, [
      _c("i", { staticClass: "fas fa-check-circle" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }