var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    this.$store.state.app.account.business_type == "company" ||
    this.$store.state.app.account.business_type == "non_profit"
      ? _c("h1", { staticStyle: { "text-align": "center" } }, [
          _vm._v("Verify you represent this business"),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { id: "account-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c("p", { staticStyle: { "text-align": "center" } }, [
          _vm._v(
            "This form must be filled out by someone with significant control and management\n\t\t\tof your business. if that's\n\t\t\tnot you, make sure to ask the right person to continue."
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Legal Name:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.first_name,
                    expression: "first_name",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:255",
                    expression: "'required|max:255'",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "first_name",
                  tabindex: "1",
                  placeholder: "First name",
                  "data-vv-as": "first name",
                },
                domProps: { value: _vm.first_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.first_name = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("first_name"))),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "input" }, [
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.last_name,
                    expression: "last_name",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:255",
                    expression: "'required|max:255'",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "last_name",
                  tabindex: "1",
                  placeholder: "Last name",
                  "data-vv-as": "last name",
                },
                domProps: { value: _vm.last_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.last_name = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("last_name"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Job Title:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.title,
                    expression: "title",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: { type: "text", name: "title" },
                domProps: { value: _vm.title },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.title = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("title"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Relationship to Company:")]),
            _vm._v(" "),
            _c("div", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.relationship,
                      expression: "relationship",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { name: "relation", "data-vv-as": "relation" },
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.relationship = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c(
                    "option",
                    { attrs: { value: "", disabled: "", selected: "" } },
                    [_vm._v("Select your Relation")]
                  ),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "owner" } }, [
                    _vm._v("Owner"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "director" } }, [
                    _vm._v("Director"),
                  ]),
                  _vm._v(" "),
                  _c("option", { attrs: { value: "representative" } }, [
                    _vm._v("Representative"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("relation"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("DOB:")]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex dob-fields",
                staticStyle: { "flex-direction": "column" },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dob.day,
                      expression: "dob.day",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|between:1,31",
                      expression: "'required|between:1,31'",
                    },
                  ],
                  attrs: {
                    name: "day",
                    id: "day",
                    type: "text",
                    placeholder: "Day",
                    "data-vv-validate-on": "blur",
                  },
                  domProps: { value: _vm.dob.day },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.dob, "day", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dob.month,
                      expression: "dob.month",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|between:1,12",
                      expression: "'required|between:1,12'",
                    },
                  ],
                  attrs: {
                    name: "month",
                    id: "month",
                    type: "text",
                    placeholder: "Month",
                    "data-vv-validate-on": "blur",
                  },
                  domProps: { value: _vm.dob.month },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.dob, "month", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.dob.year,
                      expression: "dob.year",
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|min_value:1900|max_value:2020",
                      expression: "'required|min_value:1900|max_value:2020'",
                    },
                  ],
                  attrs: {
                    name: "year",
                    id: "year",
                    type: "text",
                    maxlength: "4",
                    placeholder: "Year",
                    "data-vv-validate-on": "blur",
                  },
                  domProps: { value: _vm.dob.year },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.dob, "year", $event.target.value)
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error-message flex" }, [
                  _vm._v(
                    "\n\t\t\t\t\t\t" +
                      _vm._s(
                        _vm.errors.first("day") ||
                          _vm.errors.first("month") ||
                          _vm.errors.first("year")
                      ) +
                      "\n\t\t\t\t\t"
                  ),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Home Address:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.address.line1,
                    expression: "address.line1",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticStyle: { "margin-top": "8px", "margin-bottom": "8px" },
                attrs: {
                  type: "text",
                  name: "line1",
                  placeholder: "Line 1",
                  "data-vv-validate-on": "blur",
                },
                domProps: { value: _vm.address.line1 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.address, "line1", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(
                  "\n\t\t\t\t\t\t" +
                    _vm._s(_vm.errors.first("line1")) +
                    "\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.address.line2,
                    expression: "address.line2",
                  },
                ],
                staticStyle: { "margin-top": "8px", "margin-bottom": "8px" },
                attrs: {
                  type: "text",
                  name: "line2",
                  placeholder: "Line 2",
                  "data-vv-validate-on": "blur",
                },
                domProps: { value: _vm.address.line2 },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.address, "line2", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.address.city,
                    expression: "address.city",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticStyle: { "margin-top": "8px", "margin-bottom": "8px" },
                attrs: {
                  type: "text",
                  name: "city",
                  placeholder: "City",
                  "data-vv-validate-on": "blur",
                },
                domProps: { value: _vm.address.city },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.address, "city", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("city"))),
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.address.postal_code,
                    expression: "address.postal_code",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                staticStyle: { "margin-top": "8px", "margin-bottom": "8px" },
                attrs: {
                  type: "text",
                  name: "postal_code",
                  placeholder: "Post Code",
                  "data-vv-validate-on": "blur",
                },
                domProps: { value: _vm.address.postal_code },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.address, "postal_code", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("postal_code"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Percentage of Ownership:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.percentage,
                    expression: "percentage",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true, max_value: 100, min_value: 0.1 },
                    expression:
                      "{ required: true, max_value: 100, min_value: 0.1 }",
                  },
                ],
                attrs: {
                  type: "number",
                  name: "percentage",
                  placeholder: "0",
                  "data-vv-validate-on": "blur",
                },
                domProps: { value: _vm.percentage },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.percentage = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("percentage"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "submit-buttons" }, [
          _c(
            "div",
            { staticClass: "button-wrap" },
            [
              _c(
                "ph-button",
                { attrs: { size: "medium", loading: _vm.submitting } },
                [_vm._v("\n\t\t\t\t\tSave & Continue\n\t\t\t\t")]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }