var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidebar-group" },
    [
      _vm.title
        ? _c("div", { staticClass: "sidebar-group-title" }, [
            _vm._v("\n        " + _vm._s(_vm.title) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.items.length
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "notifications-box",
                class: { active: _vm.active },
              },
              _vm._l(_vm.items, function (item, index) {
                return _c("notification-group-item", {
                  key: item.id,
                  attrs: { item: item, index: index },
                })
              }),
              1
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.items.length > 2,
                    expression: "items.length > 2",
                  },
                ],
                staticClass: "view-all",
                on: {
                  click: function ($event) {
                    _vm.active = !_vm.active
                  },
                },
              },
              [
                _vm._v("\n            View All "),
                _c("i", { staticClass: "fa fa-angle-double-right" }),
              ]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }