var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "sub-nav" }, [
    _vm.user.account_type === "standard"
      ? _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                class: [
                  _vm.routename == "profile_all"
                    ? "router-link-exact-active router-link-active"
                    : "",
                ],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.changeRoute("profile_all")
                  },
                },
              },
              [_vm._v("Activity")]
            ),
            _vm._v(" |"),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                class: [
                  _vm.routename == "profile_posts"
                    ? "router-link-exact-active router-link-active"
                    : "",
                ],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.changeRoute("profile_posts")
                  },
                },
              },
              [_vm._v("Posts")]
            ),
          ]),
        ])
      : _c("ul", [
          _c("li", [
            _c(
              "a",
              {
                class: [
                  _vm.routename == "profile_all"
                    ? "router-link-exact-active router-link-active"
                    : "",
                ],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.changeRoute("profile_all")
                  },
                },
              },
              [_vm._v("Activity")]
            ),
            _vm._v(" |"),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                class: [
                  _vm.routename == "profile_music"
                    ? "router-link-exact-active router-link-active"
                    : "",
                ],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.changeRoute("profile_music")
                  },
                },
              },
              [_vm._v("Music")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                class: [
                  _vm.routename == "profile_events"
                    ? "router-link-exact-active router-link-active"
                    : "",
                ],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.changeRoute("profile_events")
                  },
                },
              },
              [_vm._v("Events")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                class: [
                  _vm.routename == "profile_videos"
                    ? "router-link-exact-active router-link-active"
                    : "",
                ],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.changeRoute("profile_videos")
                  },
                },
              },
              [_vm._v("Videos")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                class: [
                  _vm.routename == "profile_merch"
                    ? "router-link-exact-active router-link-active"
                    : "",
                ],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.changeRoute("profile_merch")
                  },
                },
              },
              [_vm._v("Merch")]
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                class: [
                  _vm.routename == "profile_posts"
                    ? "router-link-exact-active router-link-active"
                    : "",
                ],
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    return _vm.changeRoute("profile_posts")
                  },
                },
              },
              [_vm._v("Posts")]
            ),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }