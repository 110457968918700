var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "action-menu-container" }, [
    _c(
      "span",
      { staticClass: "action-menu-toggle", on: { click: _vm.toggleMenu } },
      [_c("i", { staticClass: "fa fa-ellipsis-h" })]
    ),
    _vm._v(" "),
    _vm.display
      ? _c("div", { staticClass: "action-menu" }, [
          _c("ul", [
            _c(
              "li",
              [
                _c("i", { staticClass: "fa fa-plus fa-fw" }),
                _vm._v(" Add to playlist "),
                _c("i", { staticClass: "fa fa-caret-right" }),
                _vm._v(" "),
                _c("action-sub-menu", { attrs: { actionable: {}, test: 1 } }),
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    _vm.remaining_dl -= 1
                  },
                },
              },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "/api/mymusic/download/" +
                        _vm.download.format +
                        "/" +
                        _vm.download.track_id,
                      target: "_blank",
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-download fa-fw" }),
                    _vm._v(" Download "),
                    _c(
                      "span",
                      { staticStyle: { "text-transform": "uppercase" } },
                      [_vm._v(_vm._s(_vm.download.format))]
                    ),
                    _vm._v(" (" + _vm._s(_vm.remaining_dl) + " left)"),
                  ]
                ),
              ]
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c("i", { staticClass: "fa fa-plus-square fa-fw" }),
      _vm._v(" Play next"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }