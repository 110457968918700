var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.method
    ? _c("div", [
        _c("div", { staticClass: "d-flex" }, [
          _c("div", { staticClass: "card-brand" }, [
            _c("img", { attrs: { src: `/img/cards/${_vm.method.type}.png` } }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-details" }, [
            _c("p", { staticClass: "card-name" }, [
              _vm._v(_vm._s(_vm.method.billing_details.name)),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-num" }, [
              _vm._v("**** **** " + _vm._s(_vm.method[_vm.method.type].last4)),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "card-num" }, [
              _vm._v("Sort: " + _vm._s(_vm.method[_vm.method.type].sort_code)),
            ]),
          ]),
        ]),
      ])
    : _c("div", { staticStyle: { "text-align": "center" } }, [
        _vm._v("\n  No Card details found.\n"),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }