var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-delete-confirm",
        width: "500px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal modal-delete" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-delete-confirm")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content content-section" }, [
          _c("h2", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("h3", [_vm._v(_vm._s(_vm.message))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "save-button" },
            [
              _c(
                "ph-button",
                {
                  attrs: {
                    size: "medium",
                    loading: _vm.submitting,
                    color: "danger-outline",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.onDelete.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Delete")]
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }