var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { staticClass: "data-row" }, [
    _c("td", [_vm._v("\n        " + _vm._s(_vm.item.name) + "\n    ")]),
    _vm._v(" "),
    _c("td", [_vm._v("\n        " + _vm._s(_vm.artist) + "\n    ")]),
    _vm._v(" "),
    _c("td", [_vm._v("\n        " + _vm._s(_vm.length) + "\n        ")]),
    _vm._v(" "),
    _c("td", [
      _vm._v("\n        " + _vm._s(this.format) + "\n        "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: this.format,
            expression: "this.format",
          },
        ],
        attrs: { type: "hidden", name: "format", readonly: true },
        domProps: { value: this.format },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(this, "format", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("td", [
      _vm._v(
        "\n        £" +
          _vm._s(
            (_vm.$store.getters["cart/getItemPrice"](_vm.item) / 100).toFixed(2)
          ) +
          "\n    "
      ),
    ]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c("close-icon", {
          attrs: { zoom: 2 },
          nativeOn: {
            click: function ($event) {
              return _vm.$store.dispatch("cart/removeItem", _vm.item)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }