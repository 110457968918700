import { render, staticRenderFns } from "./split-grid-home.vue?vue&type=template&id=0e684835&scoped=true&"
import script from "./split-grid-home.vue?vue&type=script&lang=js&"
export * from "./split-grid-home.vue?vue&type=script&lang=js&"
import style0 from "./split-grid-home.vue?vue&type=style&index=0&id=0e684835&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e684835",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.phase.uk/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0e684835')) {
      api.createRecord('0e684835', component.options)
    } else {
      api.reload('0e684835', component.options)
    }
    module.hot.accept("./split-grid-home.vue?vue&type=template&id=0e684835&scoped=true&", function () {
      api.rerender('0e684835', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/vue/components/global/split-grid-home.vue"
export default component.exports