var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded page-discover-index" }, [
    _c(
      "div",
      { staticClass: "page-main" },
      [
        _vm.app.feed.length
          ? _c("masonry-grid", { attrs: { feed_items: _vm.app.feed } })
          : _c(
              "div",
              { staticClass: "phase-loading" },
              [
                _c("dotlottie-player", {
                  staticStyle: { width: "200px", height: "200px" },
                  attrs: {
                    src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                    background: "transparent",
                    speed: "1",
                    loop: "",
                    autoplay: "",
                  },
                }),
              ],
              1
            ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "aside",
      { staticClass: "sidebar-right" },
      [
        _c("sidebar-group", {
          attrs: { title: "News", items: _vm.news.articles.slice(0, 5) },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }