var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { searching: _vm.search.visible } }, [
    _c(
      "div",
      {
        staticClass: "top",
        attrs: { id: "mobile-toggle" },
        on: {
          click: function ($event) {
            return _vm.$emit("slideoutToggle")
          },
        },
      },
      [_vm._m(0)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "top mobile-toggle" }, [
      _c("i", { staticClass: "fa fa-bars" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }