var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "message-dropdown" }, [
        _c(
          "ul",
          _vm._l(_vm.threads, function (thread, index) {
            return _c("li", { key: thread.id }, [
              _c(
                "div",
                { staticClass: "message" },
                [
                  _c("avatar", {
                    attrs: {
                      src: _vm.getReceiver(thread, "avatar"),
                      size: 50,
                      center: false,
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "message-detail" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "underline_none",
                          attrs: { to: { path: `/user/thread/${thread.id}` } },
                          nativeOn: {
                            click: function ($event) {
                              _vm.$parent.messages.show = false
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "message-name" }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.getReceiver(thread, "name"))),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "message-excerpt" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(thread.last_message.body.trunc(90)) +
                                "\n              "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "message-actions" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: { path: `/user/thread/${thread.id}` },
                              },
                              nativeOn: {
                                click: function ($event) {
                                  _vm.$parent.messages.show = false
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "action_link" }, [
                                _vm._v("Reply"),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "action_link",
                              on: {
                                click: function ($event) {
                                  return _vm.markRead(thread.id, index)
                                },
                              },
                            },
                            [_vm._v("Mark as read")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          }),
          0
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.toggleMessages.apply(null, arguments)
              },
            },
          },
          [
            _c("router-link", { attrs: { to: "/user/messages" } }, [
              _vm._v("\n      View all messages\n    "),
            ]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }