var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-deactivate",
        width: "500px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
    },
    [
      _c("div", { staticClass: "modal modal-deactivate" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-deactivate")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "modal-content content-section" }, [
          _c("h2", [_vm._v("Deactivate Account")]),
          _vm._v(" "),
          _c("h3", [
            _vm._v(
              "\n                Are you sure you want to deactivate your account?\n            "
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "save-button" },
            [
              _c(
                "ph-button",
                {
                  attrs: {
                    size: "large",
                    loading: _vm.submitting,
                    color: "danger-outline",
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.deactivateAccount.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Deactivate")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Deactivating your account will disable your profile and remove your presence from our platform. You\n                can reactivate your account at any time by Phase support."
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }