var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "add-payment-method",
        width: "600px",
        height: "auto",
        scrollable: "",
        adaptive: "",
      },
      on: {
        closed: _vm.closed,
        "before-close": _vm.closed,
        opened: _vm.opened,
      },
    },
    [
      _c("div", { staticClass: "modal add-payment-method" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("logo", {
              staticClass: "modal-logo centered-block",
              staticStyle: { width: "185px" },
            }),
            _vm._v(" "),
            _c("close-icon", {
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("add-payment-method")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-content" },
          [
            _c("div", {
              staticStyle: { margin: "20px 0px" },
              attrs: { id: "card-element" },
            }),
            _vm._v(" "),
            _c(
              "ph-button",
              {
                attrs: {
                  id: "card-button",
                  size: "small",
                  loading: _vm.submitting,
                  color: "primary-outline",
                },
              },
              [_vm._v("Save")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }