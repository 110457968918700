var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ph-panel",
    [
      _c("h2", [_vm._v("Bank Accounts")]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      !_vm.loading
        ? _c("div", [
            _c(
              "table",
              [
                _c("tr", { staticClass: "table-head" }, [
                  _c("td", [_c("span", [_vm._v("BANK NAME")])]),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v("CURRENCY")])]),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v("NAME")])]),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v("NUMBER")])]),
                  _vm._v(" "),
                  _c("td", [_c("span", [_vm._v("SORT CODE")])]),
                ]),
                _vm._v(" "),
                _vm.bankAccounts && _vm.bankAccounts.data.length
                  ? _vm._l(_vm.bankAccounts.data, function (bankAccount) {
                      return _c("tr", { staticClass: "table-data-row" }, [
                        _c("td", [_vm._v(_vm._s(bankAccount.bank_name))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(bankAccount.currency))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              bankAccount.account_holder_name
                                ? bankAccount.account_holder_name
                                : _vm.app.user.name
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v("****" + _vm._s(bankAccount.last4))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(bankAccount.routing_number))]),
                      ])
                    })
                  : _c("tr", [
                      _vm._v(
                        "\n                No Bank accounts found.\n            "
                      ),
                    ]),
              ],
              2
            ),
          ])
        : _c("spinner", {
            staticStyle: { margin: "3em auto" },
            attrs: { "animation-duration": 1000, size: 60, color: "black" },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }