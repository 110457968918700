var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "avatar",
      class: { center: _vm.center },
      style: _vm.fullWidth
        ? "width:100%;height:auto;"
        : "width:" + _vm.size + "px;height:" + _vm.size + "px;",
    },
    [
      _vm.labels.tl
        ? _c(
            "ph-label",
            {
              staticClass: "tl",
              attrs: { size: "small", color: _vm.labels.tl.color },
            },
            [_vm._v("\n    " + _vm._s(_vm.labels.tl.text) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showImage
        ? _c("div", {
            staticStyle: { width: "100%", height: "150px" },
            style: `background-image:url('${_vm.src}');background-size:cover;`,
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.labels.tr
        ? _c(
            "ph-label",
            {
              staticClass: "tr",
              attrs: { size: "small", color: _vm.labels.tr.color },
            },
            [_vm._v("\n    " + _vm._s(_vm.labels.tr.text) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.recent
        ? _c("div", { staticClass: "is-new-badge" }, [_vm._v("\n    new\n  ")])
        : _vm._e(),
      _vm._v(" "),
      _vm.track && _vm.track.status === "approved"
        ? _c(
            "div",
            { staticClass: "play-pause" },
            [
              _c("play-pause-button", {
                attrs: { track: _vm.track, size: 25, type: _vm.streamable },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }