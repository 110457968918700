<template>
  <div v-if="card">
    <div class="d-flex">
      <div class="card-brand">
        <img :src="`/img/cards/${card.card.brand}.png`" style="width: 50px;">
        <div class="card-details">
          <p>{{ card.billing_details.name }}</p>

          <p>**** **** ****{{ card.card.last4 }}</p>
          <p>Exp. {{ card.card.exp_month }}/{{ card.card.exp_year }}</p>
        </div>
      </div>

      <div class="payment-status">
        <button class="active-status" v-if="getExpirationStatus(card.card.exp_month, card.card.exp_year)">Active</button>
        <button class="expired-status" v-else>Expired</button>
      </div>
    </div>
  </div>
  <div v-else>
    Please add your card, or make some card as default!
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  props: ['card'],
  data() {
    return {
    }
  },
  methods: {
    getExpirationStatus(exp_month, exp_year) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

      const cardYear = parseInt(exp_year, 10); // Convert to number
      const cardMonth = parseInt(exp_month, 10); // Convert to number
      console.log(cardYear, currentYear, cardMonth, currentMonth);
      if (cardYear <= currentYear && currentMonth < cardMonth) {
        return true;
      } else {
        return false;
      }
    },
  },

  computed: {
    lastUpdated() {
      return moment.unix(this.card.created).format('Do MMM YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  // padding: 10px;
  // margin-bottom: 20px;

  .card-brand {
    display: flex;
    align-items: center;

    img {
      width: 80px;
      margin-right: 15px;
    }
  }

  .card-details {
    display: flex;
    margin-top: 10px;
    font-size: 14px;
    align-items: center;
  }

  .card-details p {
    padding-bottom: 10px;
    padding-right: 65px;
  }

  .card-actions {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

#current-card {
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fdfdfd;
  border-radius: 5px;

  .card-brand {
    padding-right: 1rem;

    img {
      width: 40px
    }
  }

  .card-details {
    font-size: 14px;

    p {
      &:first-child {
        margin-bottom: .5rem;
        font-weight: bold;
      }
    }
  }

  .card-actions {
    align-self: center;
    flex: 1;
    text-align: right;

    .remove {
      color: #ff6e6e;
      text-decoration: none;
    }
  }
}

.active-status {
  background-color: #fff;
  color: #0AB11B;
  border: 2px solid #0AB11B;
  padding: 6px;
  border-radius: 5px;
  font-size: 14px;
}

.expired-status {
  background-color: #fff;
  color: #AE1803;
  border: 2px solid #AE1803;
  padding: 6px;
  border-radius: 5px;
  font-size: 14px;
}

.remove-icon {
  font-size: 20px;
}


.flag {
  position: relative;
  width: 119px;
  height: 32px;
  background-color: black;
  color: white;
  text-align: center;
  line-height: 33px;
  font-size: 16px;
}

.flag::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 20px 16px 0;
  border-color: transparent #fff transparent transparent;
}
.spinner-icon{
  font-size: 20px;
}
</style>