var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ph-panel",
    [
      _c("overlay-notify", { attrs: { "is-visible": _vm.loading } }, [
        _c(
          "div",
          { staticClass: "overlay-content" },
          [
            _c("dotlottie-player", {
              staticStyle: { width: "200px", height: "200px" },
              attrs: {
                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                background: "transparent",
                speed: "1",
                loop: "",
                autoplay: "",
              },
            }),
            _vm._v(" "),
            _c("h3", [_vm._v("Redirecting to stripe...")]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("h2", [_vm._v("Stripe Verification")]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "verification-section" }, [
        _c(
          "div",
          { staticClass: "verification-item" },
          [
            _c("h2", [_vm._v("Stripe Account")]),
            _vm._v(" "),
            _vm.account ? _c("VerifyTickIcon") : _c("VerifyExclamationIcon"),
            _vm._v(" "),
            _vm.account
              ? _c("h5", [_vm._v("Verified")])
              : _c("h5", [_vm._v("Un-Verified")]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "verification-item" },
          [
            _c("h2", [_vm._v("Account Details")]),
            _vm._v(" "),
            _vm.account && _vm.isAccountDetailsVerified
              ? _c("VerifyTickIcon")
              : _c("VerifyExclamationIcon"),
            _vm._v(" "),
            _vm.account && _vm.isAccountDetailsVerified
              ? _c("h5", [_vm._v("Verified")])
              : _c("h5", [_vm._v("Un-Verified")]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "verification-item" },
          [
            _c("h2", [_vm._v("Payouts")]),
            _vm._v(" "),
            _vm.account && _vm.isPayoutEnabled
              ? _c("VerifyTickIcon")
              : _c("VerifyExclamationIcon"),
            _vm._v(" "),
            _c("h5", [_vm._v("Daily")]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "verification-item" },
          [
            _c(
              "ph-button",
              {
                attrs: { loading: _vm.loading, color: "default-dark" },
                nativeOn: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleVerificationAccount.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    _vm.verification ? "Verify Stripe" : "Update Stripe details"
                  )
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }