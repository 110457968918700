var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-change-banner",
        width: "600px",
        height: "auto",
        scrollable: "",
      },
    },
    [
      _c("div", { staticClass: "modal modal-change-banner" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-change-banner")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-content content-section" },
          [
            _c("h2", [_vm._v("Change Banner")]),
            _vm._v(" "),
            _c("image-select", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|min-dimensions:800,500",
                  expression: "'required|min-dimensions:800,500'",
                },
              ],
              attrs: { name: "banner", "min-width": 800, "min-height": 500 },
              model: {
                value: _vm.banner,
                callback: function ($$v) {
                  _vm.banner = $$v
                },
                expression: "banner",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "error-msg" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.errors.first("banner")) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "save-button" },
              [
                _c(
                  "ph-button",
                  {
                    attrs: {
                      size: "large",
                      loading: _vm.uploading,
                      color: "primary-outline",
                    },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Save")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }