var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    { staticClass: "p-item", attrs: { href: _vm.event.url, target: "_blank" } },
    [
      _c(
        "div",
        { staticClass: "p-item-image" },
        [
          _c("avatar", {
            attrs: {
              size: 130,
              src: _vm.event.image.files.medium.url,
              tile: true,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "p-item-main" }, [
        _c("div", { staticClass: "p-item-detail" }, [
          _c("div", { staticClass: "p-item-title" }, [
            _c("span", [_vm._v(_vm._s(_vm.event.name))]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "event-date-location" }, [
          _c("div", [
            _c("i", { staticClass: "far fa-fw fa-calendar" }),
            _vm._v(
              "\n        " +
                _vm._s(
                  _vm.moment(_vm.event.date).format("MMMM Do YYYY, h:mma")
                ) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("i", { staticClass: "fa fa-fw fa-map-marker-alt" }),
            _vm._v(" " + _vm._s(_vm.event.location) + "\n      "),
          ]),
        ]),
        _vm._v(" "),
        _vm.app.user.id === _vm.event.created_by
          ? _c("div", [
              _c(
                "a",
                {
                  attrs: { href: "" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$modal.show("modal-update-event", {
                        event: _vm.event,
                      })
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-pen" }),
                  _vm._v(" Edit\n        \n      "),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.$modal.show("modal-delete-confirm", {
                        deleteable: _vm.event,
                      })
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fa fa-trash" }),
                  _vm._v(" Delete\n      "),
                ]
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }