var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    { staticClass: "sidebar-group-item", attrs: { to: _vm.url } },
    [
      _c(
        "div",
        { staticClass: "sgi-upper" },
        [
          _c("avatar", {
            staticClass: "sgi-image",
            attrs: {
              tile: true,
              size: 70,
              src: _vm.imageSrc,
              alt: "",
              center: false,
              recent:
                _vm.item.is_recent &&
                (_vm.item.type == "track" || _vm.item.type == "release"),
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "sgi-info" }, [
            _c("div", { staticClass: "sgi-title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _vm._v(" "),
            _vm.item.type !== "event"
              ? _c("div", { staticClass: "sgi-date" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.moment(_vm.item.created_at).format("MMMM D, YYYY")
                      ) +
                      "\n            "
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }