var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    { staticClass: "p-item", attrs: { to: _vm.getRouterObject(_vm.user) } },
    [
      _c(
        "div",
        { staticClass: "p-item-image" },
        [
          _c("avatar", {
            attrs: { size: 130, src: _vm.user.avatar.files.thumb.url },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "p-item-main" }, [
        _c("div", { staticClass: "p-item-detail" }, [
          _c("div", { staticClass: "p-item-title" }, [
            _c("span", [_vm._v(_vm._s(_vm.user.name))]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "p-post-text" }, [
          _vm._v("\n            " + _vm._s(_vm.user.bio) + "\n        "),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }