var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-chart" }, [
    _c("h2", [
      _vm._v("\n    " + _vm._s(_vm.name) + " "),
      _c("small", [_vm._v("from " + _vm._s(this.data[0][0]) + " to Today")]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "chart-container" }, [
      _c("canvas", { ref: "chart", attrs: { id: "chart", height: "195" } }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }