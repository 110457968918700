var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "recipient" }, [
    !_vm.selectedUser
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchTerm,
              expression: "searchTerm",
            },
          ],
          class: { results: _vm.showList },
          attrs: { type: "text", placeholder: "Recipient" },
          domProps: { value: _vm.searchTerm },
          on: {
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.searchTerm = $event.target.value
              },
              _vm.input,
            ],
            keyup: [
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown",
                  ])
                )
                  return null
                return _vm.onArrowDown.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp",
                  ])
                )
                  return null
                return _vm.onArrowUp.apply(null, arguments)
              },
              function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.onEnter.apply(null, arguments)
              },
            ],
          },
        })
      : _vm._e(),
    _vm._v(" "),
    _c(
      "ul",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showList,
            expression: "showList",
          },
        ],
        staticClass: "predictions",
      },
      _vm._l(_vm.searchResults, function (result, index) {
        return _c(
          "li",
          {
            key: index,
            class: { highlighted: index === _vm.arrowCounter },
            on: {
              click: function ($event) {
                return _vm.selectRecipient(result)
              },
              mouseover: function ($event) {
                _vm.arrowCounter = index
              },
            },
          },
          [
            _c("avatar", {
              attrs: {
                size: 25,
                src: result.avatar.files.thumb.url,
                center: false,
              },
            }),
            _vm._v(" " + _vm._s(result.name) + "\n        "),
          ],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.selectedUser
      ? _c(
          "div",
          { staticClass: "selected-user", on: { click: _vm.clearReceiver } },
          [
            _c("avatar", {
              attrs: {
                size: 25,
                src: _vm.selectedUser.avatar.files.thumb.url,
                center: false,
              },
            }),
            _vm._v(" " + _vm._s(_vm.selectedUser.name) + "\n        "),
            _vm._m(0),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "remove-button" }, [
      _c("i", { staticClass: "fa fa-times" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }