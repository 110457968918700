var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-auth-register-form",
        width: !_vm.submitted ? "70%" : "40%",
        height: "auto",
        scrollable: "",
      },
      on: { "before-open": _vm.beforeOpen, closed: _vm.closed },
    },
    [
      _c("div", { staticClass: "modal modal-auth-register-form" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("logo", {
              staticClass: "modal-logo centered-block",
              staticStyle: { width: "185px" },
            }),
            _vm._v(" "),
            _c("close-icon", {
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-auth-register-form")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        !_vm.submitted
          ? _c("div", { staticClass: "modal-content full-width" }, [
              _vm.selectedPlan
                ? _c(
                    "form",
                    { staticClass: "register-form" },
                    [
                      _vm.step === 1
                        ? _c("personal-details", {
                            attrs: {
                              "selected-plan": _vm.selectedPlan,
                              verrors: _vm.verrors,
                            },
                            on: { "next-step": _vm.nextStep },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.step === 2
                        ? _c("connect-details", {
                            on: {
                              skip: _vm.onHandleSkip,
                              "next-step": _vm.nextStep,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.step === 3
                        ? _c("phone-details", {
                            on: {
                              "next-step": _vm.nextStep,
                              handleOtpId: _vm.handleOtpId,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.step === 4
                        ? _c("phone-verification", {
                            attrs: { otpId: _vm.otpId },
                            on: {
                              "next-step": _vm.nextStep,
                              handleOtpId: _vm.handleOtpId,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.step === 5
                        ? _c("identity-details", {
                            on: {
                              "next-step-verify": _vm.nextStepVerify,
                              "next-step": _vm.nextStep,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.step === 6
                        ? _c("verify-business-details", {
                            attrs: { editPerson: _vm.person },
                            on: { "next-step": _vm.nextStep },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.step === 7
                        ? _c("person-options", {
                            on: {
                              "next-step": _vm.nextStep,
                              editPerson: _vm.handleEditPerson,
                              addPerson: _vm.handleAddPerson,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.step === 8
                        ? _c("payouts-details", {
                            attrs: { "selected-plan": _vm.selectedPlan },
                            on: {
                              "next-step": _vm.nextStep,
                              "prev-step": _vm.prevStep,
                              finished: _vm.onHandleFinished,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.selectedPlan.id !== 1
                        ? _c(
                            "div",
                            {
                              staticClass: "time-confirmation-text",
                              staticStyle: { padding: "20px 50px" },
                            },
                            [
                              _c("br"),
                              _vm._v(" "),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "margin-top": "30px",
                                    "text-align": "center",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            *\n            " +
                                      _vm._s(
                                        _vm.selectedPlan.id === 2
                                          ? "Artist"
                                          : "Artist Pro"
                                      ) +
                                      " Applications\n            are subject to a verification process. This may take up to 48\n            hours.\n          "
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _c("div", { staticClass: "modal-content" }, [
              _c("h2", { staticClass: "flex justify-center" }, [
                _vm._v("\n        Your registration was successful!\n      "),
              ]),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "flex justify-center",
                  staticStyle: { "text-align": "center" },
                },
                [
                  _vm._v(
                    "\n        Account verification email sent. \n        "
                  ),
                  _c("br"),
                  _c("br"),
                  _vm._v(
                    "\n        Please check your email for account activation instructions.\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex justify-center",
                  staticStyle: { padding: "20px 0" },
                },
                [
                  _c(
                    "ph-button",
                    {
                      attrs: { size: "large" },
                      nativeOn: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.showLoginModal.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n          Login\n        ")]
                  ),
                ],
                1
              ),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }