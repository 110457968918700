var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { staticClass: "sub-nav", class: _vm.classes }, [
    _c("ul", [
      _c("li", [_vm._v(" ")]),
      _vm._v(" "),
      _c(
        "li",
        {
          class: { active: _vm.active === "account_default" },
          on: {
            click: function ($event) {
              return _vm.$emit("handleActiveTab", "account_default")
            },
          },
        },
        [
          _c("router-link", { attrs: { to: { name: "account_default" } } }, [
            _c("span", { staticClass: "top_curve" }),
            _vm._v(" "),
            _c("span", { staticClass: "left_curve" }),
            _vm._v(" "),
            _c("img", { attrs: { src: "/img/billing_account.png", alt: "" } }),
            _vm._v(" Billing & Account\n        "),
            _c("span", { staticClass: "bottom_curve" }),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$can("create releases")
        ? _c(
            "li",
            {
              class: { active: _vm.active === "account_marketplace" },
              on: {
                click: function ($event) {
                  return _vm.$emit("handleActiveTab", "account_marketplace")
                },
              },
            },
            [
              _c("span", { staticClass: "top_curve" }),
              _vm._v(" "),
              _c("span", { staticClass: "left_curve" }),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { name: "account_marketplace" } } },
                [
                  _c("img", {
                    attrs: { src: "/img/verification.png", alt: "" },
                  }),
                  _vm._v("\n        Verification\n      "),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "bottom_curve" }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "li",
        {
          class: { active: _vm.active === "mymusic" },
          on: {
            click: function ($event) {
              return _vm.$emit("handleActiveTab", "mymusic")
            },
          },
        },
        [
          _c("span", { staticClass: "top_curve" }),
          _vm._v(" "),
          _c("span", { staticClass: "left_curve" }),
          _vm._v(" "),
          _c("router-link", { attrs: { to: { name: "mymusic" } } }, [
            _c("img", { attrs: { src: "/img/my_music.png", alt: "" } }),
            _vm._v("\n        My Music\n      "),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "bottom_curve" }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$can("create releases")
        ? _c(
            "li",
            {
              class: { active: _vm.active === "account_releases" },
              on: {
                click: function ($event) {
                  return _vm.$emit("handleActiveTab", "account_releases")
                },
              },
            },
            [
              _c("span", { staticClass: "top_curve" }),
              _vm._v(" "),
              _c("span", { staticClass: "left_curve" }),
              _vm._v(" "),
              _c(
                "router-link",
                { attrs: { to: { name: "account_releases" } } },
                [
                  _c("img", { attrs: { src: "/img/my_release.png", alt: "" } }),
                  _vm._v("\n        My Releases\n      "),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "bottom_curve" }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$can("create releases")
        ? _c(
            "li",
            {
              class: { active: _vm.active === "account_stats" },
              on: {
                click: function ($event) {
                  return _vm.$emit("handleActiveTab", "account_stats")
                },
              },
            },
            [
              _c("span", { staticClass: "top_curve" }),
              _vm._v(" "),
              _c("span", { staticClass: "left_curve" }),
              _vm._v(" "),
              _c("router-link", { attrs: { to: { name: "account_stats" } } }, [
                _c("img", { attrs: { src: "/img/sales.png", alt: "" } }),
                _vm._v("\n        Sales & Feedback\n      "),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "bottom_curve" }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "li",
        {
          class: { active: _vm.active === "account_edit" },
          on: {
            click: function ($event) {
              return _vm.$emit("handleActiveTab", "account_edit")
            },
          },
        },
        [
          _c("span", { staticClass: "top_curve" }),
          _vm._v(" "),
          _c("span", { staticClass: "left_curve" }),
          _vm._v(" "),
          _c("router-link", { attrs: { to: { name: "account_edit" } } }, [
            _c("img", { attrs: { src: "/img/profile.png", alt: "" } }),
            _vm._v("\n        Profile\n      "),
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "bottom_curve" }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "extra-spacer-outline" }, [_vm._v(" ")]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }