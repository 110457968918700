var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      class: { invert: _vm.invert },
      style: `width:${_vm.width};height:${_vm.height};border-radius:${_vm.radius}`,
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }