var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-share",
        width: "700px",
        height: "auto",
        scrollable: "",
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal modal-share" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.$modal.hide("modal-share")
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-content content-section" },
          [
            _c("h2", [_vm._v("Share " + _vm._s(_vm.shareable.type))]),
            _vm._v(" "),
            _c("h3", [_vm._v("Write a comment to show your followers.")]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.message,
                  expression: "message",
                },
              ],
              attrs: { placeholder: "Comment", rows: "4" },
              domProps: { value: _vm.message },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.message = $event.target.value
                },
              },
            }),
            _vm._v(" "),
            _c("item", { attrs: { item: _vm.shareable } }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c(
              "ph-button",
              {
                attrs: {
                  size: "large",
                  loading: _vm.submitting,
                  color: "primary-outline",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.submit.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n                Share\n            ")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }