var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.app.user.loggedin
    ? _c("div", { staticClass: "add-text" }, [
        _c(
          "div",
          { staticClass: "user-avatar" },
          [
            _vm.app.user.avatar
              ? _c("avatar", {
                  attrs: {
                    size: 90,
                    src: _vm.app.user.avatar.files.medium.url,
                    alt: _vm.app.user.avatar.alt,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.submitting,
                expression: "!submitting",
              },
            ],
            staticClass: "main-actions",
          },
          [
            _vm.show[_vm.type].upper
              ? _c("div", { staticClass: "upper" }, [
                  _c(
                    "div",
                    { staticClass: "action-name" },
                    [
                      _vm.type === "newMessage"
                        ? _c("recipient-select", {
                            ref: "recipientSelector",
                            attrs: { userid: _vm.userid },
                            on: {
                              selected: function ($event) {
                                return _vm.setReceiver($event)
                              },
                              unselected: function ($event) {
                                return _vm.clearReceiver($event)
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.text[_vm.type].name) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.type === "post"
                    ? _c(
                        "div",
                        {
                          staticClass: "action-name action-name--secondary",
                          on: {
                            click: function ($event) {
                              return _vm.$modal.show("modal-upload-video")
                            },
                          },
                        },
                        [_vm._v("\n        Upload Video\n      ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.type === "post"
                    ? _c(
                        "div",
                        {
                          staticClass: "attachment-options",
                          on: {
                            click: function ($event) {
                              _vm.imageUpload = !_vm.imageUpload
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "attachment-option action-name--secondary",
                            },
                            [
                              _c("input", {
                                staticClass: "file-input--hidden",
                                attrs: {
                                  type: "file",
                                  accept: "image/png,image/jpeg",
                                },
                                on: {
                                  change: _vm.handleAttachmentChange,
                                  click: _vm.handleAttachmentClick,
                                },
                              }),
                              _vm._v(" "),
                              _c("i", { staticClass: "fa fa-image" }),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "lower" }, [
              _c(
                "div",
                { staticClass: "lower-text-input" },
                [
                  _c("form", [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.message,
                          expression: "message",
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: { max: 255 },
                          expression: "{ max: 255 }",
                        },
                      ],
                      attrs: {
                        oninput:
                          'this.style.height = "";this.style.height = this.scrollHeight + "px"',
                        rows: "1",
                        placeholder: _vm.text[_vm.type].placeholder,
                        name: "message",
                        "data-vv-as": "message",
                      },
                      domProps: { value: _vm.message },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          if (!$event.ctrlKey) return null
                          return _vm.submit.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.message = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ph-button",
                    {
                      attrs: {
                        color: "blue",
                        size: "medium",
                        loading: _vm.submitting,
                        disabled: _vm.isDisabled,
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.submit.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.text[_vm.type].button) +
                          "\n          "
                      ),
                      _vm.type === "post" && _vm.isDisabled
                        ? _c("template", { slot: "tooltip" }, [
                            _c("p", [
                              _vm._v(
                                "Upload a photo or write a post message to submit"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.previewUrl
                ? _c("div", { staticClass: "img-preview-remove" }, [
                    _c(
                      "a",
                      {
                        attrs: { title: "Remove Image" },
                        on: { click: _vm.removeAttachment },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-times text-danger",
                          staticStyle: { color: "#FF0000" },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.previewUrl
                ? _c("img", {
                    staticClass: "image-preview",
                    attrs: { src: _vm.previewUrl },
                  })
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "error-message" }, [
              _vm._v(
                "\n      " + _vm._s(_vm.errors.first("message")) + "\n    "
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.submitting,
                expression: "submitting",
              },
            ],
            staticClass: "main-actions",
          },
          [
            _c("spinner", {
              staticStyle: { margin: "0 auto" },
              attrs: { "animation-duration": 1000, size: 75, color: "black" },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }