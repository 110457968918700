<template>
    <div class="page-content-padded">
        <!-- <aside class="sidebar-left">
            <sidebar-group title="Notifications" :view-all="true" :items="[]"></sidebar-group>
        </aside> -->

        <div class="page-main">
            <h1 class="no-top">My Messages</h1>
            <add-text :userid="userid" v-if="composing" type="newMessage" @success="fetchThreads"></add-text>
            <ph-button size="large" @click.native="composing = true" v-else>New Message</ph-button>
            <div class="conversations-options">
                <button v-for="(option, index) in options" :key="index" class="message-option-btn"
                    :class="{ 'active-message-option-btn': activeOptionIndex === index }"
                    @click="setActiveOption(index)">
                    {{ option }}
                </button>
            </div>
            <div class="user-messages-container" v-if="filteredThreads?.length > 0">
                <message-thread v-if="thread.last_message" v-for="thread in filteredThreads" :key="thread.id"
                    :thread="thread" @handleRemove="removeUserConversation"></message-thread>
            </div>
            <div v-else class="no-msg-text">
                Currently, you have no messages.
            </div>
        </div>
        <!--         
        <aside class="sidebar-right">
            <sidebar-group title="Followed" :view-all="true" :items="[]"></sidebar-group>
            <sidebar-group title="Favourites" :view-all="true" :items="[]"></sidebar-group>
        </aside> -->
    </div>
</template>

<script>
import AddText from 'global/add-text/add-text';
import SidebarGroup from 'global/sidebar-group';
import MessageThread from './messages/message-thread';
import PhButton from 'global/ph-button';
import { mapState } from 'vuex';
import store from 'store';

export default {
    data() {
        return {
            composing: (this.$route.params.userid) ? true : false,
            userid: this.$route.params.userid,
            options: ['Unread messages', 'Messages'],
            activeOptionIndex: 0
        }
    },
    computed: {
        ...mapState('messenger', {
            threads: state => state.threads
        }),
        filteredThreads() {
            if (this.activeOptionIndex === 0) {
                // Filter unread messages
                return this.threads.filter(thread => {
                    if (thread.last_message) {
                        const unread = thread.last_message.views.find(view => view.view === 0);
                        return unread !== undefined;
                    }
                });
            } else {
                // Filter read messages
                return this.threads.filter(thread => {
                    if (thread.last_message) {
                        const read = thread.last_message.views.find(view => view.view === 1);
                        return read !== undefined;
                    }
                });
            }
        }
    },
    created: function () {
        if (!this.$store.state.app.user.loggedin) {
            this.$router.push({ path: '/login' });
        }
    },
    mounted() {
        this.setActiveOption(0);
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('messenger/fetchThreads').then(() => {
            next();
        });
    },

    methods: {
        fetchThreads() {
            store.dispatch('messenger/fetchThreads').then(() => {
                next();
            });
        },
        removeUserConversation(id) {
            axios.post('/api/threads/remove/' + id)
                .then(response => {
                    this.fetchThreads();
                })
                .catch(error => console.log(error));
        },
        setActiveOption(index) {
            this.activeOptionIndex = index;
        }
    },
    components: {
        AddText,
        SidebarGroup,
        MessageThread,
        PhButton,
    }
}
</script>

<style lang="scss" scoped>
.user-messages-container {
    margin: 3em 0;
}

.conversations-options {
    margin: 10px 0px;
    display: flex;
    justify-content: flex-end;
}

.message-option-btn {
    background: transparent;
    border: 2px solid #3300ff;
    font-size: 14px;
    border-radius: 18px;
    padding: 8px;
    color: #3300ff;
    margin: 0px 5px;
}

.active-message-option-btn {
    background: #3300ff;
    border: 2px solid #3300ff;
    font-size: 14px;
    border-radius: 18px;
    padding: 8px;
    color: #ffff;
    margin: 0px 5px;
}

.no-msg-text {
    height: 50vh;
    padding: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>