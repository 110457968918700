var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.post
    ? _c("div", { staticClass: "page-content-padded" }, [
        _c("div", { staticClass: "page-main page-post" }, [
          _c(
            "div",
            { staticClass: "main-info" },
            [
              _vm.post
                ? _c(
                    "router-link",
                    { attrs: { to: "/user/" + _vm.app.user.path } },
                    [
                      _c(
                        "div",
                        { staticClass: "post-image" },
                        [
                          _c("div", { staticClass: "post-label" }, [
                            _vm._v(
                              "\n                        Go back to profile\n                    "
                            ),
                          ]),
                          _vm._v(" "),
                          _c("avatar", {
                            attrs: {
                              src: _vm.post.user.avatar.files.medium.url,
                              size: 300,
                              tile: true,
                              center: false,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "item-info" }, [
                _c("div", { staticClass: "item-info-upper" }, [
                  _c("div", { staticClass: "item-info-upper-left" }, [
                    _c("div", { staticClass: "post-body" }, [
                      _vm._v(
                        "\n\n                            " +
                          _vm._s(_vm.post.body) +
                          "\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.post.attachment
                      ? _c("div", { staticClass: "post-body" }, [
                          _vm.post.attachment
                            ? _c("img", {
                                staticClass: "p-post-image",
                                attrs: {
                                  src: _vm.post.attachment.files.medium.url,
                                  alt: _vm.post.attachment.alt,
                                },
                              })
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "comments" },
            [_c("comments-container", { attrs: { commentable: _vm.post } })],
            1
          ),
        ]),
        _vm._v(" "),
        _c("aside", { staticClass: "sidebar-right" }),
      ])
    : _c("spinner", {
        staticStyle: { margin: "3em auto" },
        attrs: { "animation-duration": 1000, size: 80, color: "black" },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }