<template>
    <div class="page-content-padded" style="margin: 18px;padding: 1.5rem;border-radius: 5px;background: #f1f1f1;">
        <div class="thread-sidebar" v-if="currentThread?.messages?.length > 0">
            <div class="thread-list">
                <div :class="['thread-item', { active: isActiveThread(thread.id) }]" v-for="thread in threads" @click="$router.push(`/user/thread/${thread.id}`)">
                    <avatar :src="getReceiver(thread, 'avatar')" :size="40" :center="false" />
                    <h4>{{ getReceiver(thread, 'name') }}</h4>
                </div>
            </div>
        </div>
        <div class="page-main single-thread-page" v-if="currentThread?.messages?.length > 0">
            <div class="thread-header">
                <avatar :src="getReceiver(currentThread, 'avatar')" :size="70" :center="false" />
                <h4>{{ getReceiver(currentThread, 'name') }}</h4>
            </div>
            <div class="thread-messages" id="messages">
                <div class="thread-message-row" v-for="message in currentThread.messages" :key="message.id">
                    <div class="thread-message" :class="didSendMessage(message)">
                        <div class="message-avatar">
                            <avatar :src="getMessageSender(message, 'avatar')" :size="40" />
                        </div>
                        <div class="message-body" :class="unreadMessage(message.views[0].view)">
                            {{ message.body }}
                        </div>

                        <div class="actions" v-if="didSendMessage(message) == 'mine'">
                            <div class="action">
                                <delete-button :deleteable="message"></delete-button>
                            </div>
                        </div>
                        <div class="message-time">
                            <small>
                                {{ moment(message.date).calendar() }}
                                <span v-if="didSendMessage(message) == 'mine'">
                                    <i class="fa fa-check" v-if="!message.views[0].view"></i>
                                    <i class="fa fa-check-double" v-else></i>
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="thread-respond">
                <add-text type="message" @keydown.enter.prevent :thread="currentThread"
                    @success="appendNewMessage($event)" style="margin-bottom: 0rem;"></add-text>
            </div>
        </div>
        <dotlottie-player src="https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json" background="transparent" speed="1" style="width: 200px; height: 200px;margin: auto" loop autoplay v-if="!currentThread?.messages"></dotlottie-player>
    </div>
</template>

<script>
import DeleteButton from 'global/actions/delete-button';
import SidebarGroup from 'global/sidebar-group';
import { mapActions, mapState } from 'vuex';
import store from 'store';
import AddText from 'global/add-text/add-text';
import { MessageEvents } from '../../../event-bus';

export default {
    components: { SidebarGroup, AddText, DeleteButton },

    computed: {
        ...mapState('messenger', [
            'currentThread',
            'unreadThreads',
            'threads',
        ]),

        ...mapState([
            'app',
            'user'
        ]),
        activeThreadId() {
            return this.$route.params.threadid;
        }
    },
    data() {
        return {
            moment: window.moment,
        }
    },
    mounted() {
        MessageEvents.$on('newMessage', (data) => { this.appendNewMessage(data) });
    },

    created() {
        const id = this.$route.params.threadid;
        this.getCurrentThread(id);
        this.markMessageRead(id);


        MessageEvents.$on("message-removed", () => {
            this.getCurrentThread(this.currentThread?.id);
            return true;
        });

        if (!this.app.user.loggedin) {
            this.$router.push({ path: '/login' });
        }
    },

    watch: {
        currentThread: {
            handler(value) {
                const id = this.$route.params.threadid;
                this.markMessageRead(id);
                setTimeout(() => this.scrollToLatest(), 0)
            },

            deep: true
        },
        '$route.params.threadid': function (id) {
            console.log(id);
            store.dispatch('messenger/getCurrentThread', id).then(() => {
                // setTimeout(() => this.scrollToLatest(), 0)
            });

        }
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('messenger/fetchThreads').then(() => {
            next();
        });
    },
    methods: {
        ...mapActions('messenger', [
            'getCurrentThread',
            'markMessageRead'
        ]),
        // fetchThreads() {
        //     store.dispatch('messenger/fetchThreads').then(() => {
        //         next();
        //     });
        // },
        getReceiver(thread, property = null) {
            const result = thread.users.filter(user => {
                const userID = this.app.user.id;
                return user.id != userID;
            });

            user = result.shift();
            if (property) return user[property];
            return user;
        },
        isActiveThread(threadId) {
            return threadId == this.$route.params.threadid;
        },
        getThreadUserAvatar(users) {
            return users[0].id == this.app.user.id ? users[0].avatar : users[1].avatar;
        },

        getThreadUsername(users) {
            return users[0].id == this.app.user.id ? users[0].name : users[1].name;
        },
        getMessageSender(message, field = null) {
            const users = this.currentThread.users;

            const user = users.filter(user => {

                return user.id === message.sender;
            });

            const result = user.shift();

            if (field) return result[field];
            return result;
        },

        didSendMessage(message) {
            const sender = this.getMessageSender(message);
            return sender.id === this.app.user.id ? 'mine' : 'not-mine';
        },
        unreadMessage(value) {
            return value ? '' : 'boldText';
        },

        unreadMessageText(value) {
            return value ? 'read' : 'unread';
        },

        scrollToLatest() {
            const wrapper = document.getElementById('messages');
            wrapper.scrollTo({ top: wrapper.scrollHeight, behavior: 'smooth' });
            // wrapper.scrollTop = wrapper.scrollHeight;
        },

        getCurrentThread(){
            store.dispatch('messenger/getCurrentThread', this.$route.params.threadid);
        },

        appendNewMessage(event) {
            this.currentThread.messages.push(event);
            this.getCurrentThread();
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~styles/helpers/_variables.scss";

.single-thread-page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.otheruser-data {
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    h1 {
        margin: 0 0 0 0.5em;
    }
}

.user-messages-container {
    margin: 3em 0;
}

.thread-messages {
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    max-height: 70vh;
    padding-right: 10px;
    //box-shadow: 0 -5px 5px -5px #333;

    /*
        &::-webkit-scrollbar {
            appearance: none;
            width: 7px;
            max-width: 7px;
            height: 100%;
        }
        &::-webkit-scrollbar-track {
            background: $color-grey;
        }
        */
    &::-webkit-scrollbar-thumb {
        background: $color-2;
    }
}

.thread-message-row {
    width: 100%;
    overflow: auto;
    margin: 1em 10px;
}

.thread-message {
    display: flex;
    justify-content: space-between;
    max-width: 80%;
    padding: 1em;
    border-radius: 20px;
    text-align: right;

    &.mine {
        float: right;

        background: $color-grey;

        .message-avatar {
            order: 2;
            margin-left: 1em;
        }

        .message-body {
            order: 1;
        }
    }

    &.not-mine {
        float: left;
        text-align: left;
        border: 1px solid black;

        .message-avatar {
            margin-right: 1em;
        }
    }
}

.message-body {
    text-align: justify;
    line-height: 130%;
}

.message-time {
    line-height: 30px;
    font-size: 9px;
    color: #a5a5a5;
    min-width: 105px
}

.mine .message-time {
    float: left;
    position: relative;
    left: -10px;
    height: 18px;
    bottom: -26px;
}

.not-mine .message-time {
    // float: right;
    position: relative;
    right: -10px;
    bottom: -24px;
    height: 18px;
}

.actions {
    // width: 30%;
    font-size: 11px;
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .action {
        padding: 0 8px 0 0;
    }
}

.boldText {
    font-weight: bold;
}

.fa-check {
    font-size: 14px;
    margin-left: 10px;
    color: #ae9595;
}

.fa-check-double {
    font-size: 14px;
    margin-left: 10px;
    color: #3300ff;
}

.thread-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    background: $color-grey;
    padding: 20px;
    border-radius: 5px;
}

.thread-sidebar {
    height: 100%;
    margin-right: 7px;
    border-right: 2px solid #3300ff;
    width: 250px;
}

.thread-list {
    display: flex;
    flex-direction: column;
    margin: 5px;
    gap: 18px;
}

.thread-item {
    display: flex;
    align-items: center;
    gap: 14px;
    background: #fff;
    border-radius: 8px;
    padding: 14px 8px;
    cursor: pointer;
}
.thread-item.active {
  color: white;
  background: linear-gradient(to right, #3300ff, #00aaff);
}
</style>
