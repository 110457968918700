var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.account &&
    _vm.account.requirements &&
    (_vm.account.requirements.currently_due.length ||
      _vm.account.requirements.pending_verification.length) &&
    _vm.$refs.verification &&
    !_vm.$refs.verification.verified
      ? _c("div", [
          _vm._v(
            "\n    To receive payouts you need to supply the following information:\n    "
          ),
          _c(
            "ul",
            [
              _vm._l(
                _vm.account.requirements.currently_due,
                function (requirement, index) {
                  return _c("li", { key: `due_${index}` }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          requirement
                            .replace(".", " ")
                            .replace(".", " ")
                            .replace("_", " ")
                        ) +
                        "\n      "
                    ),
                  ])
                }
              ),
              _vm._v(" "),
              _vm._l(
                _vm.account.requirements.pending_verification,
                function (requirement, index) {
                  return _c("li", { key: `pending_${index}` }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          requirement
                            .replace(".", " ")
                            .replace(".", " ")
                            .replace("_", " ")
                        ) +
                        "\n      "
                    ),
                  ])
                }
              ),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.loading
      ? _c(
          "div",
          [
            _c("personal-details", {
              attrs: { account: _vm.account },
              on: { account_updated: _vm.fetchAccount },
            }),
            _vm._v(" "),
            _vm.account
              ? _c("bank-accounts", {
                  attrs: { account: _vm.account },
                  on: { bank_updated: _vm.fetchAccount },
                })
              : _vm._e(),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "phase-loading widget-center" },
          [
            _c("dotlottie-player", {
              staticStyle: { width: "150px", height: "150px" },
              attrs: {
                src: "https://lottie.host/453e4621-1422-4ce2-ab7f-2713df193a5d/4dPuBnAd9i.json",
                background: "transparent",
                speed: "1",
                loop: "",
                autoplay: "",
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }