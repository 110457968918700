var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-content-padded" }, [
    _c(
      "div",
      { staticClass: "page-main" },
      [
        _c("h1", { staticClass: "no-top" }, [_vm._v("My Messages")]),
        _vm._v(" "),
        _vm.composing
          ? _c("add-text", {
              attrs: { userid: _vm.userid, type: "newMessage" },
              on: { success: _vm.fetchThreads },
            })
          : _c(
              "ph-button",
              {
                attrs: { size: "large" },
                nativeOn: {
                  click: function ($event) {
                    _vm.composing = true
                  },
                },
              },
              [_vm._v("New Message")]
            ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "conversations-options" },
          _vm._l(_vm.options, function (option, index) {
            return _c(
              "button",
              {
                key: index,
                staticClass: "message-option-btn",
                class: {
                  "active-message-option-btn": _vm.activeOptionIndex === index,
                },
                on: {
                  click: function ($event) {
                    return _vm.setActiveOption(index)
                  },
                },
              },
              [_vm._v("\n                " + _vm._s(option) + "\n            ")]
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.filteredThreads?.length > 0
          ? _c(
              "div",
              { staticClass: "user-messages-container" },
              _vm._l(_vm.filteredThreads, function (thread) {
                return thread.last_message
                  ? _c("message-thread", {
                      key: thread.id,
                      attrs: { thread: thread },
                      on: { handleRemove: _vm.removeUserConversation },
                    })
                  : _vm._e()
              }),
              1
            )
          : _c("div", { staticClass: "no-msg-text" }, [
              _vm._v(
                "\n            Currently, you have no messages.\n        "
              ),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }