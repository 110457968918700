var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("h1", { staticStyle: { "text-align": "center" } }, [
      _vm._v("Select an account for payouts"),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { id: "account-form" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.handleSubmit.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Country of bank account:")]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("country-select", {
                  staticStyle: { width: "100%", "margin-top": "8px" },
                  on: { change: _vm.artistCountryChanged },
                }),
                _vm._v(" "),
                _c("span", { staticClass: "error-message" }, [
                  _vm._v(_vm._s(_vm.errors.first("country"))),
                ]),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Account name:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.account_name,
                    expression: "account_name",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true },
                    expression: "{ required: true }",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "account_name",
                  placeholder: "Test Bank",
                },
                domProps: { value: _vm.account_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.account_name = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("account_name"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Account number:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.account_number,
                    expression: "account_number",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                ref: "account_number",
                attrs: {
                  type: "text",
                  name: "account_number",
                  placeholder: "GB82WEST12345698765432",
                  "data-vv-as": "account number",
                },
                domProps: { value: _vm.account_number },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.account_number = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("account_number"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex" }, [
          _c("div", { staticClass: "input" }, [
            _c("div", [_vm._v("Confirm account number:")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.confirm_account_number,
                    expression: "confirm_account_number",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|confirmed:account_number",
                    expression: "'required|confirmed:account_number'",
                  },
                ],
                attrs: {
                  type: "text",
                  name: "confirm_account_number",
                  placeholder: "GB82WEST12345698765432",
                  "data-vv-as": "account number",
                },
                domProps: { value: _vm.confirm_account_number },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.confirm_account_number = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "error-message" }, [
                _vm._v(_vm._s(_vm.errors.first("confirm_account_number"))),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { "flex-direction": "column" } },
          [
            _c("div", { staticClass: "input" }, [
              _c("div", [_vm._v("Accept Terms of Service:")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.tos_shown_and_accepted,
                    expression: "tos_shown_and_accepted",
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'",
                  },
                ],
                attrs: {
                  name: "terms",
                  type: "checkbox",
                  "data-vv-as": "Terms and Conditions",
                },
                domProps: {
                  checked: Array.isArray(_vm.tos_shown_and_accepted)
                    ? _vm._i(_vm.tos_shown_and_accepted, null) > -1
                    : _vm.tos_shown_and_accepted,
                },
                on: {
                  change: function ($event) {
                    var $$a = _vm.tos_shown_and_accepted,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.tos_shown_and_accepted = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.tos_shown_and_accepted = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.tos_shown_and_accepted = $$c
                    }
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "error-message" }, [
              _vm._v(_vm._s(_vm.errors.first("terms"))),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.connectErrors
          ? _c("div", { staticClass: "error-message flex" }, [
              _vm._v("\n        " + _vm._s(_vm.connectErrors) + "\n      "),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "submit-buttons" }, [
          _c(
            "div",
            { staticClass: "button-wrap" },
            [
              _c(
                "ph-button",
                { attrs: { size: "medium", loading: _vm.submitting } },
                [_vm._v(" Agree & Submit ")]
              ),
            ],
            1
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "persons" }, [
      _c("span", [_vm._v("Sam Palfery: Owner, percentage ownership: 25%")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }