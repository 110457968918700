var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "comments-container" },
    [
      _c("add-text", {
        attrs: { type: "comment", addTextAble: _vm.commentable },
        on: { success: _vm.addComment },
      }),
      _vm._v(" "),
      _vm._l(_vm.comments, function (comment) {
        return _c("comment", { key: comment.id, attrs: { data: comment } })
      }),
      _vm._v(" "),
      !_vm.comments.length
        ? _c("div", { staticClass: "no-comments" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.commentsLoaded,
                    expression: "commentsLoaded",
                  },
                ],
              },
              [
                _vm._v(
                  "\n      There are no comments for this " +
                    _vm._s(_vm.commentable.type) +
                    " yet.\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.commentsLoaded,
                    expression: "!commentsLoaded",
                  },
                ],
              },
              [_vm._v(" Loading... ")]
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }