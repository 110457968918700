var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: "modal-comment",
        width: "700px",
        height: "auto",
        scrollable: "",
      },
      on: { "before-open": _vm.beforeOpen },
    },
    [
      _c("div", { staticClass: "modal modal-comment" }, [
        _c(
          "div",
          { staticClass: "modal-header" },
          [
            _c("close-icon", {
              staticClass: "float-right",
              nativeOn: {
                click: function ($event) {
                  return _vm.onHide.apply(null, arguments)
                },
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "modal-content" },
          [
            _c("div", { staticClass: "release-header" }, [
              _vm.commentable && _vm.commentable.type === "news"
                ? _c("div", [
                    _c("h2", [_vm._v("News")]),
                    _vm._v(" "),
                    _c("h3", [_vm._v(_vm._s(_vm.commentable.title))]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.commentable.content))]),
                  ])
                : _vm.commentable && _vm.commentable.type === "release"
                ? _c("div", { staticClass: "release-content" }, [
                    _c("h2", [_vm._v(_vm._s(_vm.commentable.name))]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.commentable.description))]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("add-text", {
              attrs: { type: "comment", addTextAble: _vm.commentable },
              on: { success: _vm.onCommentAdded },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "comments" }, [
              _c("div", { staticClass: "text-center" }, [
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.showcomment,
                        expression: "!showcomment",
                      },
                    ],
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        return _vm.showComments()
                      },
                    },
                  },
                  [_vm._v("Show Comments")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showcomment,
                        expression: "showcomment",
                      },
                    ],
                    staticClass: "button",
                    on: {
                      click: function ($event) {
                        return _vm.hideComments()
                      },
                    },
                  },
                  [_vm._v("Hide Comments")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showcomment,
                      expression: "showcomment",
                    },
                  ],
                },
                [
                  _c("h2", [_vm._v("Comments")]),
                  _vm._v(" "),
                  _c("comment-lists", {
                    attrs: { commentable: _vm.commentable },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }