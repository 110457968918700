<template>
  <div v-if="cards">
    <!-- <div class="d-flex" v-if="auccountsection == 'member'">
      <div class="card-brand">
        <img :src="`/img/cards/${card.card.brand}.png`">
        <div class="card-details">
      <p>{{ card.billing_details.name }}</p>  
      
      <p>**** **** ****{{ card.card.last4 }}</p>
      <p>Exp. {{ card.card.exp_month }}/{{ card.card.exp_year }}</p>
    </div>
      </div>
      
    <div class="card-actions" v-if="actions">
        <ph-button class="remove" @click.native.prevent="removeCard" size="small" :loading="submitting">Remove</ph-button>

      </div>
    </div> -->
    <!-- <div class="d-flex" v-else-if="auccountsection == 'bill'"> -->
    <div class="d-flex" v-for="(card, index) in cards" :key="index">
      <div class="card-brand">
        <img :src="`/img/cards/${card.card.brand}.png`" style="width: 50px;">
        <div class="card-details">
          <p>{{ card.billing_details.name }}</p>

          <p>**** **** ****{{ card.card.last4 }}</p>
          <p>Exp. {{ card.card.exp_month }}/{{ card.card.exp_year }}</p>
        </div>
      </div>

      <div class="payment-status">
        <button class="active-status"
          v-if="getExpirationStatus(card.card.exp_month, card.card.exp_year)">Active</button>
        <button class="expired-status" v-else>Expired</button>
      </div>


      <div class="card-actions" v-if="actions">
        <div v-if="getExpirationStatus(card.card.exp_month, card.card.exp_year)">
          <ph-button class="set-as-default" @click.native.prevent="setDefault(card.id)" size="small"
            :loading="submitting[card.id]" v-if="!card.isDefault">Set as
            Default</ph-button>

          <div class="flag" v-else>Default</div>
        </div>


        <span @click="removeCard(card)" v-if="!removeLoading[card.id]"><i class="fa fa-trash remove-icon"></i></span>
        <i class="fa fa-spinner fa-pulse fa-3x fa-fw spinner-icon" aria-hidden="true" v-else></i>
      </div>
    </div>
    <!-- <div id="current-card" v-else>
      <div class="card-brand">
        <img :src="`/img/cards/${card.card.brand}.png`">
      </div>
      <div class="card-details">
        <p>{{ auccountsection }}</p>
        <p>Ending with {{ card.card.last4 }}</p>
        <p>Expires {{ card.card.exp_month }}/{{ card.card.exp_year }} - Last updated {{ lastUpdated }}</p>
      </div>
      <div class="card-actions" v-if="actions">
        <ph-button class="remove" @click.native.prevent="removeCard" size="small" :loading="submitting">Remove</ph-button>

      </div>
    </div> -->
  </div>
  <div v-else style="text-align: center;">
    No Card details found.
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  props: ['cards', 'actions', 'account'],
  data() {
    return {
      submitting: {},
      removeLoading: {},
      auccountsection: this.account,
    }
  },
  methods: {
    async setDefault(id) {
      this.$set(this.submitting, id, true);
      await axios.post('/api/account/payment-method/update', {
        pm_id: id
      }).then(({ data }) => {
        this.$set(this.submitting, id, false);
        Vue.notify({
          group: 'main',
          type: 'success',
          title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
          duration: 1500,
        })
        window.location.reload();
      })
    },
    async removeCard(card) {
      this.$set(this.removeLoading, card.id, true);
      await axios.post('/api/account/billing/remove', {
        payment_method: card
      }).then(({ data }) => {
        this.$set(this.removeLoading, card.id, false);
        this.$emit("handleRemove");
        Vue.notify({
          group: 'main',
          type: 'success',
          title: "<img src='/img/confirm.gif' alt='success' style='background:transparent;width:60%;'>",
          duration: 1500,
        })
        window.location.reload();
      })
    },
    getExpirationStatus(exp_month, exp_year) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1; // Months are zero-based

      const cardYear = parseInt(exp_year, 10); // Convert to number
      const cardMonth = parseInt(exp_month, 10); // Convert to number
      console.log(cardYear, currentYear, cardMonth, currentMonth);
      if (cardYear <= currentYear && currentMonth < cardMonth) {
        return true;
      } else {
        return false;
      }
    },
  },

  computed: {
    lastUpdated() {
      return moment.unix(this.card.created).format('Do MMM YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 10px;
  margin-bottom: 20px;

  .card-brand {
    display: flex;
    align-items: center;

    img {
      width: 80px;
      margin-right: 15px;
    }
  }

  .card-details {
    display: flex;
    margin-top: 10px;
    font-size: 20px;
  }

  .card-details p {
    padding-bottom: 10px;
    padding-right: 65px;
  }

  .card-actions {
    display: flex;
    align-items: center;
    gap: 20px;
  }
}

#current-card {
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #fdfdfd;
  border-radius: 5px;

  .card-brand {
    padding-right: 1rem;

    img {
      width: 40px
    }
  }

  .card-details {
    font-size: 14px;

    p {
      &:first-child {
        margin-bottom: .5rem;
        font-weight: bold;
      }
    }
  }

  .card-actions {
    align-self: center;
    flex: 1;
    text-align: right;

    .remove {
      color: #ff6e6e;
      text-decoration: none;
    }
  }
}

.active-status {
  background-color: #fff;
  color: #0AB11B;
  border: 2px solid #0AB11B;
  padding: 6px;
  border-radius: 5px;
  font-size: 15px;
}

.expired-status {
  background-color: #fff;
  color: #AE1803;
  border: 2px solid #AE1803;
  padding: 6px;
  border-radius: 5px;
  font-size: 15px;
}

.remove-icon {
  font-size: 20px;
}


.flag {
  position: relative;
  width: 88px;
  height: 32px;
  background-color: black;
  color: white;
  text-align: start;
  padding-left: 8px;
  margin-left: 20px;
  line-height: 33px;
  font-size: 16px;
}

.flag::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 16px 20px 16px 0;
  border-color: transparent #fff transparent transparent;
}

.spinner-icon {
  font-size: 20px;
}
</style>